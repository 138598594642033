import { forwardRef } from 'react'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { usePracticeStore } from './PracticeStore.ts'
import { useSnapshot } from 'valtio'
import { cn } from '../../shared/lib/utils.ts'
import { FormattedMessage } from 'react-intl'

export interface VocabProgressElemType {
  textColor: string
  bgColor: string
  statKey: 'new' | 'weak' | 'strong'
}

export interface VocabProgressTagType {
  bgColor: string
  id: AppMessageKeys
}

export const VOCAB_PROGRESS_BLOCKS: VocabProgressElemType[] = [
  { textColor: 'text-blue2', bgColor: 'bg-blue', statKey: 'new' },
  { textColor: 'text-orange5', bgColor: 'bg-orange4', statKey: 'weak' },
  { textColor: 'text-green2', bgColor: 'bg-green3', statKey: 'strong' },
]

export const VOCAB_PROGRESS_TAGS: VocabProgressTagType[] = [
  { bgColor: 'bg-blue2', id: 'practice.new' },
  { bgColor: 'bg-orange5', id: 'practice.weak' },
  { bgColor: 'bg-green2', id: 'practice.strong' },
]

export const ProgressBar = forwardRef<
  HTMLDivElement,
  { className: string; wrapperWidth?: number | null }
>(({ className, wrapperWidth = null }, ref) => {
  const store = usePracticeStore()
  const state = useSnapshot(store.state)

  return (
    <div
      className={className}
      ref={ref}
      style={{ width: wrapperWidth ?? '100%' }}
    >
      {VOCAB_PROGRESS_BLOCKS.map((el, i, arr) => {
        const rounded =
          i === 0 ? 'rounded-l-12' : i === arr.length - 1 ? 'rounded-r-12' : ''
        const value = state.stat ? state.stat[el.statKey] : null
        const width = state.stat
          ? (state.stat[el.statKey] / state.stat.sum) * 100
          : 0

        return (
          <div
            key={`progress-${el.statKey}`}
            className={cn('relative h-full min-w-52', el.bgColor, rounded)}
            style={{ width: `${width}%` }}
          >
            <p
              className={cn(
                'absolute bottom-4 right-12 text-24 font-extrabold',
                el.textColor,
              )}
            >
              {value}
            </p>
          </div>
        )
      })}
    </div>
  )
})

ProgressBar.displayName = 'ProgressBar'

export const ProgressTags = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      {VOCAB_PROGRESS_TAGS.map((el, i) => (
        <ProgressElemTag
          key={`progress-el-tag-${i}`}
          bgColor={el.bgColor}
          id={el.id}
        />
      ))}
    </div>
  )
}

const ProgressElemTag = ({ bgColor, id }: VocabProgressTagType) => {
  return (
    <div className="flex items-center gap-4 font-nunito-7-condensed text-14 font-bold text-gray4">
      <div className={cn('size-8 rounded-full', bgColor)} />
      <FormattedMessage id={id} />
    </div>
  )
}

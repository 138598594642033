import { FormattedMessage } from 'react-intl'
import { Button } from '../shared/ui/button/button.tsx'
import { useNavigate } from 'react-router-dom'
import { urls } from '../shared/urls.ts'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <div className="flex min-h-dvh w-full flex-col p-16">
      <div className="flex w-full flex-1 flex-col items-center justify-center ">
        <img
          src="/images/magnifier.svg"
          alt="magnifier"
          className="size-[124px] "
        />
        <h3 className="mt-24 text-center text-22 font-bold">
          <FormattedMessage id="error404" />
        </h3>
        <p className="mt-12 px-32 text-center font-nunito-7-semicondensed text-16">
          <FormattedMessage id="error404text" />
        </p>
      </div>
      <div className="flex w-full flex-col items-center">
        <Button
          bg="blue-gradient"
          size="large"
          rounded="full"
          className="w-full sm:w-800"
          onClick={() => {
            navigate(urls.learningProfile())
          }}
        >
          <FormattedMessage id="goLearningProfile" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          id="contact-button"
          rounded="full"
          className="mt-8 w-full sm:w-800"
          onClick={() => {
            navigate(urls.contacts)
          }}
        >
          <FormattedMessage id="contacts.title" />
        </Button>
      </div>
    </div>
  )
}

import React from 'react'
import { useProfileStore } from './ProfileStore.ts'
import { useSnapshot } from 'valtio'
import { Button } from '../../shared/ui/button/button.tsx'
import {
  OnboardingContext,
  stepComponents,
} from '../onboarding/OnboardingView.tsx'
import { onboardingSteps } from '../../shared/data/onboardingSteps.ts'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { FormattedMessage } from 'react-intl'
import { AppMessageKeys } from '../../shared/translations/messages.ts'

export const ProfileOnboardingInfo = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  if (!state.student?.onboarding_info) {
    return
  }
  const onboarding = store.onboardingStore
    .getProfileData()
    .filter((x) => x.key !== 'name')

  return (
    <div>
      <ProfileTitle
        title={<FormattedMessage id="user.changeOnboardingInformation" />}
      />
      <ProfileMenuBlock>
        {onboarding.map((x, i) => (
          <ProfileButton
            key={i}
            withArrow
            onClick={() => {
              store.openOnboardingDrawer(x.key)
            }}
            title={x.icon + ' ' + x.text}
            subtitle={<FormattedMessage id={x.key as AppMessageKeys} />}
          />
        ))}
      </ProfileMenuBlock>
      <EditDrawer />
    </div>
  )
}

function EditDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const key =
    state.onboardingKey == 'languageLevel' ? 'level' : state.onboardingKey
  const title = onboardingSteps.find((x) => x.step === key)?.title ?? ''

  return (
    <OnboardingContext.Provider value={store.onboardingStore}>
      <ProfileDrawer gray open={!!state.onboardingKey} title={title}>
        <div className="flex flex-col gap-16">
          <div className="mb-16">{key && stepComponents[key]}</div>
          <Button
            rounded="full"
            size="large"
            bg="blue-gradient"
            onClick={() => {
              void store.saveOnboardingChanges()
            }}
          >
            Save
          </Button>

          <Button
            bg="transparent"
            size="large"
            onClick={() => {
              store.closeDrawer()
            }}
          >
            Cancel
          </Button>
        </div>
      </ProfileDrawer>
    </OnboardingContext.Provider>
  )
}

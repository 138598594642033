import { proxy } from 'valtio'
import { AppLocalStorage } from '../../shared/lib/appLocalStorage.ts'
import { AppLanguages, languages } from '../../shared/translations/messages.ts'

interface State {
  edmanUnmute: boolean
  hideMenu: boolean
  language: AppLanguages
}

export class AppStore {
  state: State

  constructor(private appLocalStorage: AppLocalStorage) {
    this.state = proxy<State>({
      edmanUnmute: false,
      hideMenu: false,
      language: this.getInitialLanguage(),
    })
  }

  setHideMenu(value: boolean) {
    this.state.hideMenu = value
  }

  getInitialLanguage() {
    const language =
      this.appLocalStorage.get('language') ??
      navigator.language.split(/[-_]/)[0]
    return (languages.has(language) ? language : 'en') as AppLanguages
  }

  setLanguage(language: AppLanguages) {
    this.state.language = language
    this.appLocalStorage.set('language', language)
  }

  toggleEdmanUnmute() {
    this.state.edmanUnmute = !this.state.edmanUnmute
  }
}

import { useIntl } from 'react-intl'
import { AppMessageKeys } from '../../shared/translations/messages.ts'

export type ChooseListData = readonly (readonly [string, string, string])[]

export interface OnboardingData {
  duration: ChooseListData
  profession: ChooseListData
  goal: ChooseListData
  improves: ChooseListData
  languageLevel: ChooseListData
  sex: ChooseListData
  interests: ChooseListData
  age: ChooseListData
  levels: ('A2' | 'B1' | 'B2')[]
}

function useIntlItems(items: readonly (readonly [string, AppMessageKeys])[]) {
  const intl = useIntl()
  return items.map((x) => {
    const text = intl.formatMessage({ id: x[1] })
    return [x[0], text, text] as const
  })
}

export function useOnboardingData(): OnboardingData {
  const ageData = [
    ['👶', 'onboarding.age-1'],
    ['🎓', 'onboarding.age-2'],
    ['💼', 'onboarding.age-3'],
    ['🏡', 'onboarding.age-4'],
    ['🌟', 'onboarding.age-5'],
    ['🎉', 'onboarding.age-6'],
    ['🙈', 'onboarding.noAnswer'],
  ] as const

  const age = useIntlItems(ageData)

  const intl = useIntl()
  const durations = [5, 10, 15, 30, 40, 60]
  const duration = durations.map((d) => {
    const text = intl.formatMessage({ id: 'minutes' }, { count: d })
    return ['⏱️', text, text] as const
  })

  const improveData = [
    ['🗣️', 'onboarding.improve-1'],
    ['📖', 'onboarding.improve-2'],
    ['✍️', 'onboarding.improve-3'],
    ['📚', 'onboarding.improve-4'],
    ['📝', 'onboarding.improve-5'],
    ['💬', 'onboarding.improve-6'],
    ['👂', 'onboarding.improve-7'],
  ] as const

  const improves = useIntlItems(improveData)

  const sexData = [
    ['👨', 'onboarding.male'],
    ['👩', 'onboarding.female'],
    ['🙈', 'onboarding.noAnswer'],
  ] as const

  const sex = useIntlItems(sexData)

  const goalData = [
    ['💼', 'onboarding.goal-1'],
    ['🏘️', 'onboarding.goal-2'],
    ['📚', 'onboarding.goal-3'],
    ['🌍', 'onboarding.goal-4'],
    ['💬', 'onboarding.goal-5'],
  ] as const

  const goal = useIntlItems(goalData)

  const interestData = [
    ['🍔', 'onboarding.Food'],
    ['🎨', 'onboarding.Fine Arts'],
    ['🔬', 'onboarding.Science'],
    ['⚽', 'onboarding.Sports'],
    ['🌿', 'onboarding.Nature'],
    ['✈️ ', 'onboarding.Travel'],
    ['🏡', 'onboarding.Home and Decor'],
    ['🎵', 'onboarding.Music'],
    ['🩺', 'onboarding.Health'],
    ['🛍️', 'onboarding.Shopping'],
    ['🧠', 'onboarding.Psychology'],
    ['🎥', 'onboarding.Cinema'],
    ['🐶', 'onboarding.Pets'],
    ['👗', 'onboarding.Fashion'],
    ['🖥️', 'onboarding.Technologies'],
    ['🎮', 'onboarding.Games'],
    ['🎓', 'onboarding.Education'],
    ['🎭', 'onboarding.Theater'],
    ['🥘', 'onboarding.Culinary Arts'],
    ['💰', 'onboarding.Finance'],
    ['📜', 'onboarding.History'],
    ['📸', 'onboarding.Photography'],
  ] as const

  const interests = useIntlItems(interestData)

  const levelData = [
    ['❌', 'onboarding.a2-1', 'A2'],
    ['👋', 'onboarding.a2-2', 'A2'],
    ['🗣️', 'onboarding.a2-3', 'A2'],
    ['😊', 'onboarding.b1-1', 'B1'],
    ['📚', 'onboarding.b1-2', 'B1'],
    ['🕵️', 'onboarding.b2-1', 'B2'],
    ['🎓', 'onboarding.b2-2', 'B2'],
  ] as const

  const languageLevel = useIntlItems(levelData.map((x) => [x[0], x[1]]))
  const levels = levelData.map((x) => x[2])

  const professionData = [
    ['📈', 'onboarding.Marketing Specialist'],
    ['🔬', 'onboarding.Research Scientist'],
    ['👩‍🏫', 'onboarding.Teacher'],
    ['🤝', 'onboarding.Sales Manager'],
    ['👥', 'onboarding.Human Resources Specialist'],
    ['💻', 'onboarding.IT Specialist'],
    ['📣', 'onboarding.Public Relations Officer'],
    ['✍️', 'onboarding.Content Writer/Editor'],
    ['⚖️', 'onboarding.Lawyer'],
    ['🧮', 'onboarding.Accountant'],
    ['💼', 'onboarding.Entrepreneur'],
    ['👨‍', 'onboarding.Software developer'],
    ['🧠', 'onboarding.Psychologist'],
    ['👩‍⚕️', 'onboarding.Nurse'],
    ['🏠', 'onboarding.Real estate agent'],
    ['📐', 'onboarding.Architect'],
    ['🤷', 'onboarding.Other'],
  ] as const

  const profession = useIntlItems(professionData)

  return {
    age,
    duration,
    improves,
    sex,
    goal,
    interests,
    levels,
    languageLevel,
    profession,
  }
}

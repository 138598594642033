import { HttpClient } from './httpClient.ts'

export class EdenaiApi {
  constructor(private httpClient: HttpClient) {}
  async speechToText(chatId: number, blob: Blob) {
    const form = new FormData()
    form.append('file', blob)
    return this.httpClient.request<{ text: string }>(
      `/api/speech_to_text/${chatId}/`,
      {
        body: form,
        method: 'POST',
      },
    )
  }

  async textToSpeech(chatId: number | undefined, text: string) {
    const path = chatId
      ? `/api/text_to_speech/${chatId}/`
      : `/api/text_to_speech/`

    const response = await this.httpClient.post<{ stream_key: string }>(path, {
      text,
    })

    return this.httpClient.getFullUrl(
      `/api/text_to_speech/download/${response.stream_key}/`,
    )
  }
}

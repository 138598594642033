import { HttpClient } from './httpClient.ts'
import { PickPublic } from '../lib/utils.ts'
import { SubscriptionPlan } from './chatApi.ts'

export type OnbEventSuffix =
  | `level_${'A2' | 'B1' | 'B2'}`
  | `goal_${string}`
  | `profession_${string}`
  | `onboarding_passed`
  | `trial_cancelled`
  | '1st_question'
  | 'demo_finished'
  | 'subscriptions'

type OnbEvents = `onb_${OnbEventSuffix}` | `onbdemo_${OnbEventSuffix}`

export type MarketingEvents =
  | 'finish_onboarding'
  | 'click_buy'
  | 'click_selection'
  | 'click_again'
  | 'subscribtion_page_load'
  | 'subscribtion_click'
  | 'subscribtion_trial_click'
  | 'click_plans_demo_onboarding'
  | 'click_help_demo_onboarding'
  | 'microfon_accept'
  | 'microfon_sent'
  | 'registration_page_load'
  | 'edman_intro_start'
  | 'profession_sent'
  | 'hobby_sent'
  | 'user_english_level_sent'
  | 'purpose_of_study_sent'
  | 'all_good_registration'
  | 'demo_lesson_start'
  | 'picture_demo_sent'
  | 'picture_demo_answer'
  | 'training_program_sent'
  | 'paing_link_sent'
  | 'paing_finished'
  | 'demo_popup_keep'
  | 'demo_popup_rus'
  | 'demo_level_answer'
  | 'demo_topic_answer'
  | 'demo_username_answer'
  | 'demo_subtopic_answer'
  | 'demo_complete_answer'
  | `trial_started_${SubscriptionPlan}`
  | 'demo2_level_chosen'
  | 'demo2_lesson_launched'
  | 'demo2_photo_received'
  | 'demo2_photo_answered'
  | 'demo2_text_answered'
  | 'demo2_puzzle_passed'
  | 'demo2_lesson_finished'
  | 'demo2_subscription_visit'
  | 'demo2_payment_link'
  | 'demo2_successful_payment'
  | 'tts_click'
  | OnbEvents

export interface MarkData {
  referrer_domain: string | null
  utm_content: string | null
  utm_source: string | null
  utm_medium: string | null
  utm_campaign: string | null
  utm_term: string | null
  utm_placement: string | null
  clientID: string | null
}
export type AuthApi = PickPublic<AppAuthApi>
export class AppAuthApi {
  constructor(private httpClient: HttpClient) {}

  register(
    email: string,
    password: string,
    markData: MarkData | null,
    userUUID: string,
  ) {
    const body = {
      email,
      password: password ? password : undefined,
      ...markData,
      userUUID,
    }
    return this.httpClient.post<{
      access: string
      refresh: string
      user_id: number
      sms_confirm?: boolean
    }>('/api/register/', body)
  }

  register_anon(level: 'A2' | 'B1' | 'B2') {
    return this.httpClient.post<{
      access: string
      refresh: string
      user_id: number
      sms_confirm?: boolean
    }>('/api/register_anon/', { level })
  }

  delete_anon() {
    return this.httpClient.del<never>('/api/delete_anon/', {})
  }

  register_applicant(login: string) {
    return this.httpClient.post<{
      access: string
      refresh: string
      user_id: number
      sms_confirm?: boolean
    }>('/api/register_applicant/', { email: login })
  }

  async login(email: string, password: string) {
    return this.httpClient.post<{
      access: string
      refresh: string
      user_id: number
      account_confirmed: boolean
    }>('/api/login/', { email, password })
  }

  confirm_phone(code: string) {
    return this.httpClient.post<{ status: 'success' | 'error' }>(
      '/api/confirm_phone/',
      { code },
    )
  }

  resend_code() {
    return this.httpClient.get<{ status: 'success' | 'error' }>(
      '/api/resend_code/',
    )
  }

  track_event(
    event_name: MarketingEvents,
    userId: number | null,
    edmanUserUUID: string | undefined,
  ) {
    return this.httpClient.post<never>('/api/track_event/', {
      event_name,
      event_src: 'app',
      user_id: edmanUserUUID ?? null,
      event_args: { additionalProp1: userId },
    })
  }
}

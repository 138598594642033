import messages_ru from './ru.json'
import messages_en from './en.json'

export type AppLanguages = 'en' | 'ru'
export type AppMessages = typeof messages_ru

export type AppMessageKeys = keyof AppMessages
export const messages: Record<AppLanguages, AppMessages> = {
  en: messages_en,
  ru: messages_ru,
}

export const languages = new Set(Object.keys(messages))

import { PickPublic } from '../lib/utils.ts'
import { Vocabulary } from './chatApi.ts'
import { HttpClient } from './httpClient.ts'

export type WordsApi = PickPublic<AppWordsApi>

export class AppWordsApi {
  constructor(private httpClient: HttpClient) {}

  wordAdd(request: { word_original: string }) {
    return this.httpClient.post<Vocabulary>('/api/vocabulary/add/', request)
  }

  wordRemove(id: number) {
    return this.httpClient.del<Vocabulary>(`/api/vocabulary/remove/${id}/`, {})
  }

  getWords(requestParams: {
    is_user_added?: boolean
    limit?: number
    offset?: number
    ordering?: string
    timestamp?: string
  }) {
    return this.httpClient.get<Vocabulary[]>('/api/vocabulary/', {
      ...requestParams,
    })
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment */
import * as Sentry from '@sentry/react'
import { appMainModule } from './appMainModule.ts'
import { subscribe } from 'valtio'

const hostname = document.location.hostname
if (hostname != 'localhost') {
  Sentry.init({
    dsn: 'https://427129077b8a19a3e501f359c4c0d833@sentry.mon.edman.ai/9',
    environment: hostname,
    tracePropagationTargets: ['localhost', 'edman.ai/api'],
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'fetch') {
        if (breadcrumb.data?.url?.includes?.('mc.yandex.ru')) {
          return null
        }
        breadcrumb.data = breadcrumb.data ?? {}
        if (hint?.input?.[1]) {
          breadcrumb.data.requestBody = hint.input[1]?.body
        }
      }
      return breadcrumb
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  const authState = appMainModule.authStore.state
  updateSentryUser()

  function updateSentryUser() {
    const user = authState.user
    if (user) {
      Sentry.setUser({ username: user.login, id: user.user_id })
    }
  }
  subscribe(authState, () => {
    updateSentryUser()
  })
}

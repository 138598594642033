export async function setLoading<T>(
  state: { loading: boolean },
  fn: () => Promise<T>,
) {
  try {
    state.loading = true
    return await fn()
  } finally {
    state.loading = false
  }
}

import { FormattedMessage, useIntl } from 'react-intl'
import { useIsMobile, usePromise } from '../shared/lib/hooks.ts'
import { Loadable } from '../shared/ui/loadable.tsx'
import {
  MetricsCards,
  ProgressPageHeader,
  ProgressPageHeaderData,
} from '../features/progress/progressView.tsx'
import { useAppModule } from '../features/appContext.ts'
import { cn } from '../shared/lib/utils.ts'

export function ProgressPage() {
  const intl = useIntl()
  const isMobile = useIsMobile()

  const appModule = useAppModule()
  const params = usePromise(() =>
    Promise.all([
      appModule.chatsApi.getAccount(),
      appModule.progressStore.getAllMetrics(),
    ]),
  )

  return (
    <div className="flex w-full flex-col p-20 pt-6">
      <ProgressPageHeader>
        {intl.formatMessage({ id: 'metrics.yourProgress' })}
      </ProgressPageHeader>
      <Loadable data={params}>
        {(data) => {
          return (
            <div className="mb-100 xl:mb-0">
              <ProgressPageHeaderData
                username={data[0].name}
                dateJoined={data[0].date_joined}
              />
              <MetricsCards />
              <p className="mx-12 mt-22 text-center text-14 font-normal text-gray4">
                <FormattedMessage
                  id="metrics.footerText"
                  values={{
                    boldText: (
                      <span className="font-bold">
                        {intl.formatMessage({ id: 'metrics.footerBoldSpan' })}
                      </span>
                    ),
                  }}
                />
              </p>
              <p className="mt-24 text-22 font-bold text-black">
                <FormattedMessage id="metrics.monthProgress" />
              </p>
              <div
                className={cn(
                  'relative mt-8 flex flex-col items-center justify-center gap-12 overflow-hidden rounded-18 px-16 py-128',
                  !isMobile && 'bg-blue-60',
                )}
              >
                {isMobile && (
                  <img
                    src="/images/mockMetrics.png"
                    className="absolute inset-y-0 size-full"
                  />
                )}
                <p className="z-10 text-center text-18 font-extrabold text-black">
                  <FormattedMessage id="metrics.monthProgressSoonTitle" />
                </p>
                <p className="z-10 text-center text-14 font-normal text-black">
                  <FormattedMessage id="metrics.monthProgressSoon" />
                </p>
              </div>
            </div>
          )
        }}
      </Loadable>
    </div>
  )
}

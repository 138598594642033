import * as React from 'react'
import { GbImage, RuImage } from '../images/images.tsx'
import { Popover } from '../popover/popover.tsx'
import { AppLanguages } from '../../translations/messages.ts'
import { cn } from '../../lib/utils.ts'

function LanguageButton(props: {
  language: AppLanguages
  item?: boolean
  darkBorder?: boolean
}) {
  const name = props.language == 'ru' ? 'Русский' : 'English'
  const Icon = props.language == 'ru' ? RuImage : GbImage
  return (
    <div className="flex items-center justify-between gap-8 text-14 font-bold">
      <div className={cn(props.item && 'hidden text-black sm:block')}>
        {name}
      </div>
      <div
        className={cn(
          'shrink-0 rounded-8 border-2 p-2',
          props.darkBorder ? 'border-gray-dark/50' : 'border-white',
        )}
      >
        <Icon className="h-20 overflow-hidden rounded-4" />
      </div>
    </div>
  )
}

export const SwitchLanguage = (props: {
  language: AppLanguages
  onChange: (lang: AppLanguages) => void
  darkBorder?: boolean
}) => {
  const [open, setOpen] = React.useState(false)

  const handleLanguageChange = (lang: AppLanguages) => {
    props.onChange(lang)
    setOpen(false)
  }

  return (
    <Popover.Root
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen)
      }}
    >
      <Popover.Trigger className="text-white">
        <LanguageButton
          darkBorder={props.darkBorder}
          language={props.language}
          item={true}
        />
      </Popover.Trigger>
      <Popover.Content>
        <div className="flex flex-col gap-8">
          <button
            onClick={() => {
              handleLanguageChange('en')
            }}
          >
            <LanguageButton language="en" />
          </button>
          <button
            onClick={() => {
              handleLanguageChange('ru')
            }}
          >
            <LanguageButton language="ru" />
          </button>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

import React from 'react'
import { cn } from '../../lib/utils.ts'
import { classed } from '@tw-classed/react'

interface StageProgressProps {
  stages: Record<string, string>
  currentStage?: string
  className?: string
  disabled?: boolean
  onStageSelected: (stage: string) => void
}

const Dot = classed.div('z-10 mb-2 size-12 rounded-full', {
  variants: {
    active: {
      false: 'bg-gray',
      true: 'bg-main-light',
    },
    current: {
      false: 'sm:invisible',
    },
  },
})

const Line = classed.div('absolute top-[5px] h-[3px]', {
  variants: {
    first: {
      true: 'rounded-l-full',
    },
    gray: {
      true: 'bg-gray-dark',
      false: 'bg-main-light',
    },
    last: {
      true: 'w-[200%] rounded-r-full',
      false: 'right-half w-full',
    },
  },
})

const StageTitle = classed.span('text-12 font-bold sm:w-auto', {
  variants: {
    current: {
      false: 'hidden sm:inline',
    },
    light: {
      false: 'text-white',
      true: 'text-light',
    },
    clickable: {
      true: '',
    },
  },
  compoundVariants: [
    {
      clickable: true,
      light: true,
      className: 'cursor-pointer underline underline-offset-2',
    },
  ],
})

export const StageProgress = (props: StageProgressProps) => {
  const stagesList = Object.entries(props.stages)
  if (!stagesList.length) {
    return null
  }
  const index = stagesList.findIndex((x) => x[0] === props.currentStage)
  const currentIndex = Math.max(index, 0)
  return (
    <div className={cn('flex', props.className)}>
      <div className="absolute h-1 bg-dark"></div>
      {stagesList.map((stage, index) => {
        const gray = index > currentIndex
        const half = index === 0 || index == stagesList.length - 1
        const last = index == stagesList.length - 1
        const current = index == currentIndex
        const lightTitle = !current || props.disabled
        // const clickable = index <= currentIndex
        return (
          <div
            key={stage[0]}
            className={cn(
              'relative flex min-w-0 basis-0 flex-col',
              last
                ? 'items-end text-right'
                : half
                ? 'items-start'
                : 'items-center text-center',
              half ? 'grow-[0.5]' : 'grow',
            )}
          >
            {index > 0 && <Line first={index === 0} gray={gray} last={last} />}
            <Dot current={current} active={!gray} />
            <StageTitle
              // onClick={() => {
              //   if(props.disabled){
              //     return
              //   }
              //   props.onStageSelected(stage)
              // }}
              current={current}
              // clickable={clickable}
              light={lightTitle}
            >
              {stage[1]}
            </StageTitle>
          </div>
        )
      })}
    </div>
  )
}

import * as React from 'react'
import { Drawer as DrawerPrimitive } from 'vaul'
import { cn } from '../../lib/utils.ts'

const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
)
Drawer.displayName = 'Drawer'

const DrawerTrigger = DrawerPrimitive.Trigger

const DrawerPortal = DrawerPrimitive.Portal

const DrawerClose = DrawerPrimitive.Close

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, onClick, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn('fixed inset-0 z-50 bg-black/30', className)}
    {...props}
    onClick={onClick}
  />
))
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> & {
    direction: 'bottom' | 'left'
    overlayClassName?: string
    onOverlayClick?: () => void
  }
>(
  (
    {
      direction,
      className,
      overlayClassName,
      children,
      onOverlayClick,
      ...props
    },
    ref,
  ) => (
    <DrawerPortal>
      <DrawerOverlay className={overlayClassName} onClick={onOverlayClick} />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          'fixed z-50 outline-none',
          direction == 'bottom' ? 'inset-x-0 bottom-0' : 'inset-y-0 left-0',
          className,
        )}
        {...props}
      >
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  ),
)
DrawerContent.displayName = 'DrawerContent'
export function DrawerBar() {
  return <div className="mx-auto mt-8 h-4 w-40 rounded-full bg-gray-dark/50" />
}

export {
  Drawer,
  DrawerPortal,
  DrawerOverlay,
  DrawerTrigger,
  DrawerClose,
  DrawerContent,
}

import { classed } from '@tw-classed/react'
import { IMetricCard, metricCards } from '../../shared/data/progressMetrics.ts'
import { FormattedMessage } from 'react-intl'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio'
import { format } from 'date-fns'
import MetricCard from '../../shared/ui/metricCard/metricCard.tsx'
import { IMetrics } from 'src/shared/lib/utils.ts'

export interface IProgressPageHeaderProps {
  username: string | undefined
  dateJoined: string | undefined | null
}

export interface IMetricCardsProps {
  metrics: IMetrics[]
}

export const ProgressPageHeader = classed.h1(
  'text-center text-18 font-bold text-black',
)

export const MetricsCards = () => {
  const progressStore = useAppModule().progressStore
  const { metrics } = useSnapshot(progressStore.state)

  return (
    <div className="mt-18 flex h-fit min-h-136 gap-10">
      {metricCards.map((el: IMetricCard) => {
        //eslint-disable-next-line
        const metricValue = metrics[el.metricKey]?.[0]?.value

        return <MetricCard key={el.key} value={metricValue} element={el} />
      })}
    </div>
  )
}

export const ProgressPageHeaderData = ({
  username,
  dateJoined,
}: IProgressPageHeaderProps) => {
  return (
    <>
      <h3 className="mt-28 text-center text-24 font-bold text-black">
        {username ? username : 'Student'}
      </h3>
      {!!dateJoined && (
        <p className="text-center text-12 font-medium text-gray4">
          <FormattedMessage
            id="metrics.joinedDate"
            values={{
              date: format(new Date(dateJoined), 'dd.MM.yyyy '),
            }}
          />
        </p>
      )}
    </>
  )
}

import { WordsStoreContext } from '../features/words/wordsStore.ts'
import { WordsView } from '../features/words/wordsView.tsx'
import { useAppModule } from '../features/appContext.ts'
import { useLazyRef } from '../shared/lib/hooks.ts'

export function WordsProvider({ children }: { children: React.ReactNode }) {
  const appModule = useAppModule()
  const store = useLazyRef(() => appModule.wordsStore()).current

  return (
    <WordsStoreContext.Provider value={store}>
      {children}
    </WordsStoreContext.Provider>
  )
}

export function WordsPage() {
  return (
    <WordsProvider>
      <WordsView />
    </WordsProvider>
  )
}

/* eslint-disable */

export const fakeProgramData: any = [
  [
    {
      id: 30965,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L1-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'introducing yourself',
      status: 'completed',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'introducing yourself',
    },
    {
      id: 30966,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L1-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'introducing yourself',
      status: 'completed',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'introducing yourself',
    },
    {
      id: 31147,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L6-General conversation',
      program: 'Nature (animals)',
      topic: 'Feeding and Caring for Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Feeding and Caring for Animals',
    },
    {
      id: 31101,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L4-Vocabulary',
      program: 'Jobs',
      topic: 'At the Workplace',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'At the Workplace',
    },
    {
      id: 31110,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L7-Vocabulary',
      program: 'Jobs',
      topic: 'Dream job',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Dream job',
    },
    {
      id: 30967,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L1-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'introducing yourself',
      status: 'completed',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'introducing yourself',
    },
    {
      id: 30968,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L2-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Days of the week',
      status: 'active',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Days of the week',
    },
    {
      id: 30969,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L2-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Days of the week',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Days of the week',
    },
    {
      id: 30970,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L2-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Days of the week',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Days of the week',
    },
    {
      id: 30971,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L3-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Leisure activities',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Leisure activities',
    },
    {
      id: 30972,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L3-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Leisure activities',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Leisure activities',
    },
    {
      id: 30973,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L3-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Leisure activities',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Leisure activities',
    },
    {
      id: 30974,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L4-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Meals during the day',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Meals during the day',
    },
    {
      id: 30975,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L4-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Meals during the day',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Meals during the day',
    },
    {
      id: 30976,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L4-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Meals during the day',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Meals during the day',
    },
    {
      id: 30977,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L5-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Exercises (sport)',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Exercises (sport)',
    },
    {
      id: 30978,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L5-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Exercises (sport)',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Exercises (sport)',
    },
    {
      id: 30979,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L5-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Exercises (sport)',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Exercises (sport)',
    },
    {
      id: 30980,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L6-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Everyday routine',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Everyday routine',
    },
    {
      id: 30981,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L6-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Everyday routine',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Everyday routine',
    },
    {
      id: 30982,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L6-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Everyday routine',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Everyday routine',
    },
    {
      id: 30983,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'General conversation',
      tag: 'A2-M1-L7-General conversation',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Weekend Activities',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Weekend Activities',
    },
    {
      id: 30984,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Grammar',
      tag: 'A2-M1-L7-Grammar',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Weekend Activities',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Weekend Activities',
    },
    {
      id: 30985,
      level: 'A2',
      month: 'M1',
      lesson: 'M1',
      lesson_type: 'Vocabulary',
      tag: 'A2-M1-L7-Vocabulary',
      program: 'Basic Communication Skills + Daily routine',
      topic: 'Weekend Activities',
      status: 'new',
      display_program: 'Basic Communication Skills + Daily routine',
      display_topic: 'Weekend Activities',
    },
    {
      id: 30986,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L1-General conversation',
      program: 'Family and Friends',
      topic: 'Introducing Family Members',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Introducing Family Members',
    },
    {
      id: 30987,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L1-Vocabulary',
      program: 'Family and Friends',
      topic: 'Introducing Family Members',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Introducing Family Members',
    },
    {
      id: 31013,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L3-Grammar',
      program: 'House and Home; Places',
      topic: 'Furniture and Household Items',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Furniture and Household Items',
    },
    {
      id: 30988,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L2-General conversation',
      program: 'Family and Friends',
      topic:
        'Describing family members (adjectives for physical description and personality traits)',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic:
        'Describing family members (adjectives for physical description and personality traits)',
    },
    {
      id: 30989,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Grammar',
      tag: 'A2-M2-L2-Grammar',
      program: 'Family and Friends',
      topic:
        'Describing family members (adjectives for physical description and personality traits)',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic:
        'Describing family members (adjectives for physical description and personality traits)',
    },
    {
      id: 30990,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L2-Vocabulary',
      program: 'Family and Friends',
      topic:
        'Describing family members (adjectives for physical description and personality traits)',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic:
        'Describing family members (adjectives for physical description and personality traits)',
    },
    {
      id: 30991,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L3-General conversation',
      program: 'Family and Friends',
      topic: 'Daily Routines of Family Members',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Daily Routines of Family Members',
    },
    {
      id: 30992,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Grammar',
      tag: 'A2-M2-L3-Grammar',
      program: 'Family and Friends',
      topic: 'Daily Routines of Family Members',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Daily Routines of Family Members',
    },
    {
      id: 30993,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L3-Vocabulary',
      program: 'Family and Friends',
      topic: 'Daily Routines of Family Members',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Daily Routines of Family Members',
    },
    {
      id: 30994,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L4-General conversation',
      program: 'Family and Friends',
      topic: 'Family Traditions and Celebrations',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Family Traditions and Celebrations',
    },
    {
      id: 30995,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Grammar',
      tag: 'A2-M2-L4-Grammar',
      program: 'Family and Friends',
      topic: 'Family Traditions and Celebrations',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Family Traditions and Celebrations',
    },
    {
      id: 30996,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L4-Vocabulary',
      program: 'Family and Friends',
      topic: 'Family Traditions and Celebrations',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Family Traditions and Celebrations',
    },
    {
      id: 30997,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L5-General conversation',
      program: 'Family and Friends',
      topic:
        'Making Plans with Friends (Days of the week, making invitations and suggestions)',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic:
        'Making Plans with Friends (Days of the week, making invitations and suggestions)',
    },
    {
      id: 31043,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L6-Grammar',
      program: 'Hobbies leisure',
      topic: 'Outdoor Activities',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Outdoor Activities',
    },
    {
      id: 30998,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Grammar',
      tag: 'A2-M2-L5-Grammar',
      program: 'Family and Friends',
      topic:
        'Making Plans with Friends (Days of the week, making invitations and suggestions)',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic:
        'Making Plans with Friends (Days of the week, making invitations and suggestions)',
    },
    {
      id: 30999,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L5-Vocabulary',
      program: 'Family and Friends',
      topic:
        'Making Plans with Friends (Days of the week, making invitations and suggestions)',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic:
        'Making Plans with Friends (Days of the week, making invitations and suggestions)',
    },
    {
      id: 31000,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L6-General conversation',
      program: 'Family and Friends',
      topic: 'Family conflicts',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Family conflicts',
    },
    {
      id: 31001,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Grammar',
      tag: 'A2-M2-L6-Grammar',
      program: 'Family and Friends',
      topic: 'Family conflicts',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Family conflicts',
    },
    {
      id: 31002,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L6-Vocabulary',
      program: 'Family and Friends',
      topic: 'Family conflicts',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Family conflicts',
    },
    {
      id: 31003,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'General conversation',
      tag: 'A2-M2-L7-General conversation',
      program: 'Family and Friends',
      topic: 'Problems with teens',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Problems with teens',
    },
    {
      id: 31004,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Grammar',
      tag: 'A2-M2-L7-Grammar',
      program: 'Family and Friends',
      topic: 'Problems with teens',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Problems with teens',
    },
    {
      id: 31005,
      level: 'A2',
      month: 'M2',
      lesson: 'M2',
      lesson_type: 'Vocabulary',
      tag: 'A2-M2-L7-Vocabulary',
      program: 'Family and Friends',
      topic: 'Problems with teens',
      status: 'new',
      display_program: 'Family and Friends',
      display_topic: 'Problems with teens',
    },
    {
      id: 31006,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L1-General conversation',
      program: 'House and Home; Places',
      topic: 'Types of Housing',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Types of Housing',
    },
    {
      id: 31007,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L1-Grammar',
      program: 'House and Home; Places',
      topic: 'Types of Housing',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Types of Housing',
    },
    {
      id: 31008,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L1-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Types of Housing',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Types of Housing',
    },
    {
      id: 31009,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L2-General conversation',
      program: 'House and Home; Places',
      topic: 'Rooms in a House',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Rooms in a House',
    },
    {
      id: 31010,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L2-Grammar',
      program: 'House and Home; Places',
      topic: 'Rooms in a House',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Rooms in a House',
    },
    {
      id: 31011,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L2-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Rooms in a House',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Rooms in a House',
    },
    {
      id: 31012,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L3-General conversation',
      program: 'House and Home; Places',
      topic: 'Furniture and Household Items',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Furniture and Household Items',
    },
    {
      id: 31014,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L3-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Furniture and Household Items',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Furniture and Household Items',
    },
    {
      id: 31015,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L4-General conversation',
      program: 'House and Home; Places',
      topic: 'Describing Your Home(+adj)',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Describing Your Home(+adj)',
    },
    {
      id: 31016,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L4-Grammar',
      program: 'House and Home; Places',
      topic: 'Describing Your Home(+adj)',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Describing Your Home(+adj)',
    },
    {
      id: 31017,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L4-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Describing Your Home(+adj)',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Describing Your Home(+adj)',
    },
    {
      id: 31018,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L5-General conversation',
      program: 'House and Home; Places',
      topic: 'Countryside (places) +services',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Countryside (places) +services',
    },
    {
      id: 31019,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L5-Grammar',
      program: 'House and Home; Places',
      topic: 'Countryside (places) +services',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Countryside (places) +services',
    },
    {
      id: 31020,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L5-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Countryside (places) +services',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Countryside (places) +services',
    },
    {
      id: 31021,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L6-General conversation',
      program: 'House and Home; Places',
      topic: 'Big cities (friendliest countries)',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Big cities (friendliest countries)',
    },
    {
      id: 31022,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L6-Grammar',
      program: 'House and Home; Places',
      topic: 'Big cities (friendliest countries)',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Big cities (friendliest countries)',
    },
    {
      id: 31023,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L6-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Big cities (friendliest countries)',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Big cities (friendliest countries)',
    },
    {
      id: 31024,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'General conversation',
      tag: 'A2-M3-L7-General conversation',
      program: 'House and Home; Places',
      topic: 'Asking for and Giving Directions',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Asking for and Giving Directions',
    },
    {
      id: 31025,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Grammar',
      tag: 'A2-M3-L7-Grammar',
      program: 'House and Home; Places',
      topic: 'Asking for and Giving Directions',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Asking for and Giving Directions',
    },
    {
      id: 31026,
      level: 'A2',
      month: 'M3',
      lesson: 'M3',
      lesson_type: 'Vocabulary',
      tag: 'A2-M3-L7-Vocabulary',
      program: 'House and Home; Places',
      topic: 'Asking for and Giving Directions',
      status: 'new',
      display_program: 'House and Home; Places',
      display_topic: 'Asking for and Giving Directions',
    },
    {
      id: 31027,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L1-General conversation',
      program: 'Hobbies leisure',
      topic: 'Common hobbies and interests',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Common hobbies and interests',
    },
    {
      id: 31028,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L1-Grammar',
      program: 'Hobbies leisure',
      topic: 'Common hobbies and interests',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Common hobbies and interests',
    },
    {
      id: 31029,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L1-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Common hobbies and interests',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Common hobbies and interests',
    },
    {
      id: 31030,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L2-General conversation',
      program: 'Hobbies leisure',
      topic: 'Sports and Physical Activities',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Sports and Physical Activities',
    },
    {
      id: 31031,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L2-Grammar',
      program: 'Hobbies leisure',
      topic: 'Sports and Physical Activities',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Sports and Physical Activities',
    },
    {
      id: 31032,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L2-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Sports and Physical Activities',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Sports and Physical Activities',
    },
    {
      id: 31033,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L3-General conversation',
      program: 'Hobbies leisure',
      topic: 'Arts and Crafts',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Arts and Crafts',
    },
    {
      id: 31034,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L3-Grammar',
      program: 'Hobbies leisure',
      topic: 'Arts and Crafts',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Arts and Crafts',
    },
    {
      id: 31035,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L3-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Arts and Crafts',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Arts and Crafts',
    },
    {
      id: 31036,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L4-General conversation',
      program: 'Hobbies leisure',
      topic: 'Music and Entertainment',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Music and Entertainment',
    },
    {
      id: 31037,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L4-Grammar',
      program: 'Hobbies leisure',
      topic: 'Music and Entertainment',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Music and Entertainment',
    },
    {
      id: 31038,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L4-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Music and Entertainment',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Music and Entertainment',
    },
    {
      id: 31039,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L5-General conversation',
      program: 'Hobbies leisure',
      topic: 'Reading and Literature',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Reading and Literature',
    },
    {
      id: 31040,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L5-Grammar',
      program: 'Hobbies leisure',
      topic: 'Reading and Literature',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Reading and Literature',
    },
    {
      id: 31041,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L5-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Reading and Literature',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Reading and Literature',
    },
    {
      id: 31042,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L6-General conversation',
      program: 'Hobbies leisure',
      topic: 'Outdoor Activities',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Outdoor Activities',
    },
    {
      id: 31100,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L4-Grammar',
      program: 'Jobs',
      topic: 'At the Workplace',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'At the Workplace',
    },
    {
      id: 31044,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L6-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Outdoor Activities',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Outdoor Activities',
    },
    {
      id: 31045,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'General conversation',
      tag: 'A2-M4-L7-General conversation',
      program: 'Hobbies leisure',
      topic: 'Popular hobbies in different countries',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Popular hobbies in different countries',
    },
    {
      id: 31046,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Grammar',
      tag: 'A2-M4-L7-Grammar',
      program: 'Hobbies leisure',
      topic: 'Popular hobbies in different countries',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Popular hobbies in different countries',
    },
    {
      id: 31047,
      level: 'A2',
      month: 'M4',
      lesson: 'M4',
      lesson_type: 'Vocabulary',
      tag: 'A2-M4-L7-Vocabulary',
      program: 'Hobbies leisure',
      topic: 'Popular hobbies in different countries',
      status: 'new',
      display_program: 'Hobbies leisure',
      display_topic: 'Popular hobbies in different countries',
    },
    {
      id: 31048,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L1-General conversation',
      program: 'Transport and Travel',
      topic: 'Types of Transport',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Types of Transport',
    },
    {
      id: 31049,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L1-Grammar',
      program: 'Transport and Travel',
      topic: 'Types of Transport',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Types of Transport',
    },
    {
      id: 31050,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L1-Vocabulary',
      program: 'Transport and Travel',
      topic: 'Types of Transport',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Types of Transport',
    },
    {
      id: 31051,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L2-General conversation',
      program: 'Transport and Travel',
      topic: 'Buying tickets',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Buying tickets',
    },
    {
      id: 31052,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L2-Grammar',
      program: 'Transport and Travel',
      topic: 'Buying tickets',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Buying tickets',
    },
    {
      id: 31053,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L2-Vocabulary',
      program: 'Transport and Travel',
      topic: 'Buying tickets',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Buying tickets',
    },
    {
      id: 31054,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L3-General conversation',
      program: 'Transport and Travel',
      topic: 'Airport, Railway station',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Airport, Railway station',
    },
    {
      id: 31055,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L3-Grammar',
      program: 'Transport and Travel',
      topic: 'Airport, Railway station',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Airport, Railway station',
    },
    {
      id: 31056,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L3-Vocabulary',
      program: 'Transport and Travel',
      topic: 'Airport, Railway station',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Airport, Railway station',
    },
    {
      id: 31057,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L4-General conversation',
      program: 'Transport and Travel',
      topic: 'Travel Preparations (Packing essentials and travel preparations)',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic:
        'Travel Preparations (Packing essentials and travel preparations)',
    },
    {
      id: 31058,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L4-Grammar',
      program: 'Transport and Travel',
      topic: 'Travel Preparations (Packing essentials and travel preparations)',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic:
        'Travel Preparations (Packing essentials and travel preparations)',
    },
    {
      id: 31059,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L4-Vocabulary',
      program: 'Transport and Travel',
      topic: 'Travel Preparations (Packing essentials and travel preparations)',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic:
        'Travel Preparations (Packing essentials and travel preparations)',
    },
    {
      id: 31060,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L5-General conversation',
      program: 'Transport and Travel',
      topic: 'Accommodation',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Accommodation',
    },
    {
      id: 31061,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L5-Grammar',
      program: 'Transport and Travel',
      topic: 'Accommodation',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Accommodation',
    },
    {
      id: 31062,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L5-Vocabulary',
      program: 'Transport and Travel',
      topic: 'Accommodation',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Accommodation',
    },
    {
      id: 31063,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L6-General conversation',
      program: 'Transport and Travel',
      topic: 'Amazing transport (personal experience)',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Amazing transport (personal experience)',
    },
    {
      id: 31064,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L6-Grammar',
      program: 'Transport and Travel',
      topic: 'Amazing transport (personal experience)',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Amazing transport (personal experience)',
    },
    {
      id: 31065,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L6-Vocabulary',
      program: 'Transport and Travel',
      topic: 'Amazing transport (personal experience)',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'Amazing transport (personal experience)',
    },
    {
      id: 31066,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'General conversation',
      tag: 'A2-M5-L7-General conversation',
      program: 'Transport and Travel',
      topic: 'unusual modes of transport around the world',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'unusual modes of transport around the world',
    },
    {
      id: 31067,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Grammar',
      tag: 'A2-M5-L7-Grammar',
      program: 'Transport and Travel',
      topic: 'unusual modes of transport around the world',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'unusual modes of transport around the world',
    },
    {
      id: 31068,
      level: 'A2',
      month: 'M5',
      lesson: 'M5',
      lesson_type: 'Vocabulary',
      tag: 'A2-M5-L7-Vocabulary',
      program: 'Transport and Travel',
      topic: 'unusual modes of transport around the world',
      status: 'new',
      display_program: 'Transport and Travel',
      display_topic: 'unusual modes of transport around the world',
    },
    {
      id: 31069,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L1-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'Types of Shops and Shopping Places',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Types of Shops and Shopping Places',
    },
    {
      id: 31070,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L1-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'Types of Shops and Shopping Places',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Types of Shops and Shopping Places',
    },
    {
      id: 31071,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L1-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'Types of Shops and Shopping Places',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Types of Shops and Shopping Places',
    },
    {
      id: 31072,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L2-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'Shopping for Clothes',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Shopping for Clothes',
    },
    {
      id: 31073,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L2-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'Shopping for Clothes',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Shopping for Clothes',
    },
    {
      id: 31074,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L2-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'Shopping for Clothes',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Shopping for Clothes',
    },
    {
      id: 31075,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L3-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'Grocery Shopping',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Grocery Shopping',
    },
    {
      id: 31076,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L3-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'Grocery Shopping',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Grocery Shopping',
    },
    {
      id: 31077,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L3-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'Grocery Shopping',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Grocery Shopping',
    },
    {
      id: 31078,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L4-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'At the Market',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'At the Market',
    },
    {
      id: 31079,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L4-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'At the Market',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'At the Market',
    },
    {
      id: 31080,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L4-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'At the Market',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'At the Market',
    },
    {
      id: 31081,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L5-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'Ordering Food in a Restaurant',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Ordering Food in a Restaurant',
    },
    {
      id: 31082,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L5-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'Ordering Food in a Restaurant',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Ordering Food in a Restaurant',
    },
    {
      id: 31083,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L5-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'Ordering Food in a Restaurant',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Ordering Food in a Restaurant',
    },
    {
      id: 31084,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L6-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'Describing Food and Taste',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Describing Food and Taste',
    },
    {
      id: 31085,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L6-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'Describing Food and Taste',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Describing Food and Taste',
    },
    {
      id: 31086,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L6-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'Describing Food and Taste',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Describing Food and Taste',
    },
    {
      id: 31087,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'General conversation',
      tag: 'A2-M6-L7-General conversation',
      program: 'Shopping+ Food, restaurants',
      topic: 'Food Preparation and Recipes',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Food Preparation and Recipes',
    },
    {
      id: 31088,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Grammar',
      tag: 'A2-M6-L7-Grammar',
      program: 'Shopping+ Food, restaurants',
      topic: 'Food Preparation and Recipes',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Food Preparation and Recipes',
    },
    {
      id: 31089,
      level: 'A2',
      month: 'M6',
      lesson: 'M6',
      lesson_type: 'Vocabulary',
      tag: 'A2-M6-L7-Vocabulary',
      program: 'Shopping+ Food, restaurants',
      topic: 'Food Preparation and Recipes',
      status: 'new',
      display_program: 'Shopping+ Food, restaurants',
      display_topic: 'Food Preparation and Recipes',
    },
    {
      id: 31090,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L1-General conversation',
      program: 'Jobs',
      topic: 'Different Types of Jobs ( Job titles and workplaces)',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Different Types of Jobs ( Job titles and workplaces)',
    },
    {
      id: 31091,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L1-Grammar',
      program: 'Jobs',
      topic: 'Different Types of Jobs ( Job titles and workplaces)',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Different Types of Jobs ( Job titles and workplaces)',
    },
    {
      id: 31092,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L1-Vocabulary',
      program: 'Jobs',
      topic: 'Different Types of Jobs ( Job titles and workplaces)',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Different Types of Jobs ( Job titles and workplaces)',
    },
    {
      id: 31093,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L2-General conversation',
      program: 'Jobs',
      topic:
        'Describing Job Responsibilities (Tasks and duties associated with various jobs)+ your job',
      status: 'new',
      display_program: 'Jobs',
      display_topic:
        'Describing Job Responsibilities (Tasks and duties associated with various jobs)+ your job',
    },
    {
      id: 31094,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L2-Grammar',
      program: 'Jobs',
      topic:
        'Describing Job Responsibilities (Tasks and duties associated with various jobs)+ your job',
      status: 'new',
      display_program: 'Jobs',
      display_topic:
        'Describing Job Responsibilities (Tasks and duties associated with various jobs)+ your job',
    },
    {
      id: 31095,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L2-Vocabulary',
      program: 'Jobs',
      topic:
        'Describing Job Responsibilities (Tasks and duties associated with various jobs)+ your job',
      status: 'new',
      display_program: 'Jobs',
      display_topic:
        'Describing Job Responsibilities (Tasks and duties associated with various jobs)+ your job',
    },
    {
      id: 31096,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L3-General conversation',
      program: 'Jobs',
      topic: 'Job Hunting',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Job Hunting',
    },
    {
      id: 31097,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L3-Grammar',
      program: 'Jobs',
      topic: 'Job Hunting',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Job Hunting',
    },
    {
      id: 31098,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L3-Vocabulary',
      program: 'Jobs',
      topic: 'Job Hunting',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Job Hunting',
    },
    {
      id: 31099,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L4-General conversation',
      program: 'Jobs',
      topic: 'At the Workplace',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'At the Workplace',
    },
    {
      id: 31102,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L5-General conversation',
      program: 'Jobs',
      topic: 'Daily work routines and work schedules',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Daily work routines and work schedules',
    },
    {
      id: 31103,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L5-Grammar',
      program: 'Jobs',
      topic: 'Daily work routines and work schedules',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Daily work routines and work schedules',
    },
    {
      id: 31104,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L5-Vocabulary',
      program: 'Jobs',
      topic: 'Daily work routines and work schedules',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Daily work routines and work schedules',
    },
    {
      id: 31105,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L6-General conversation',
      program: 'Jobs',
      topic: 'Experience (From rags to riches)',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Experience (From rags to riches)',
    },
    {
      id: 31106,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L6-Grammar',
      program: 'Jobs',
      topic: 'Experience (From rags to riches)',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Experience (From rags to riches)',
    },
    {
      id: 31107,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Vocabulary',
      tag: 'A2-M7-L6-Vocabulary',
      program: 'Jobs',
      topic: 'Experience (From rags to riches)',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Experience (From rags to riches)',
    },
    {
      id: 31108,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'General conversation',
      tag: 'A2-M7-L7-General conversation',
      program: 'Jobs',
      topic: 'Dream job',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Dream job',
    },
    {
      id: 31109,
      level: 'A2',
      month: 'M7',
      lesson: 'M7',
      lesson_type: 'Grammar',
      tag: 'A2-M7-L7-Grammar',
      program: 'Jobs',
      topic: 'Dream job',
      status: 'new',
      display_program: 'Jobs',
      display_topic: 'Dream job',
    },
    {
      id: 31111,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L1-General conversation',
      program: 'Weather seasons',
      topic: 'Weather types (temperature + climate)',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Weather types (temperature + climate)',
    },
    {
      id: 31112,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L1-Grammar',
      program: 'Weather seasons',
      topic: 'Weather types (temperature + climate)',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Weather types (temperature + climate)',
    },
    {
      id: 31113,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L1-Vocabulary',
      program: 'Weather seasons',
      topic: 'Weather types (temperature + climate)',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Weather types (temperature + climate)',
    },
    {
      id: 31114,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L2-General conversation',
      program: 'Weather seasons',
      topic: 'Seasons and Months',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Seasons and Months',
    },
    {
      id: 31115,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L2-Grammar',
      program: 'Weather seasons',
      topic: 'Seasons and Months',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Seasons and Months',
    },
    {
      id: 31116,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L2-Vocabulary',
      program: 'Weather seasons',
      topic: 'Seasons and Months',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Seasons and Months',
    },
    {
      id: 31117,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L3-General conversation',
      program: 'Weather seasons',
      topic: 'Clothing for Different Weathers',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Clothing for Different Weathers',
    },
    {
      id: 31118,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L3-Grammar',
      program: 'Weather seasons',
      topic: 'Clothing for Different Weathers',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Clothing for Different Weathers',
    },
    {
      id: 31119,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L3-Vocabulary',
      program: 'Weather seasons',
      topic: 'Clothing for Different Weathers',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Clothing for Different Weathers',
    },
    {
      id: 31120,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L4-General conversation',
      program: 'Weather seasons',
      topic: 'Activities for Different Seasons',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Activities for Different Seasons',
    },
    {
      id: 31121,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L4-Grammar',
      program: 'Weather seasons',
      topic: 'Activities for Different Seasons',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Activities for Different Seasons',
    },
    {
      id: 31122,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L4-Vocabulary',
      program: 'Weather seasons',
      topic: 'Activities for Different Seasons',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Activities for Different Seasons',
    },
    {
      id: 31123,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L5-General conversation',
      program: 'Weather seasons',
      topic:
        '(Extreme Weather Conditions(Terms for extreme weather (blizzard, drought, flood, etc.))',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic:
        '(Extreme Weather Conditions(Terms for extreme weather (blizzard, drought, flood, etc.))',
    },
    {
      id: 31124,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L5-Grammar',
      program: 'Weather seasons',
      topic:
        '(Extreme Weather Conditions(Terms for extreme weather (blizzard, drought, flood, etc.))',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic:
        '(Extreme Weather Conditions(Terms for extreme weather (blizzard, drought, flood, etc.))',
    },
    {
      id: 31125,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L5-Vocabulary',
      program: 'Weather seasons',
      topic:
        '(Extreme Weather Conditions(Terms for extreme weather (blizzard, drought, flood, etc.))',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic:
        '(Extreme Weather Conditions(Terms for extreme weather (blizzard, drought, flood, etc.))',
    },
    {
      id: 31126,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L6-General conversation',
      program: 'Weather seasons',
      topic: 'Describing all the seasons + Weather Forecast',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Describing all the seasons + Weather Forecast',
    },
    {
      id: 31127,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L6-Grammar',
      program: 'Weather seasons',
      topic: 'Describing all the seasons + Weather Forecast',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Describing all the seasons + Weather Forecast',
    },
    {
      id: 31128,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L6-Vocabulary',
      program: 'Weather seasons',
      topic: 'Describing all the seasons + Weather Forecast',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Describing all the seasons + Weather Forecast',
    },
    {
      id: 31129,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'General conversation',
      tag: 'A2-M8-L7-General conversation',
      program: 'Weather seasons',
      topic: 'Favourite season',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Favourite season',
    },
    {
      id: 31130,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Grammar',
      tag: 'A2-M8-L7-Grammar',
      program: 'Weather seasons',
      topic: 'Favourite season',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Favourite season',
    },
    {
      id: 31131,
      level: 'A2',
      month: 'M8',
      lesson: 'M8',
      lesson_type: 'Vocabulary',
      tag: 'A2-M8-L7-Vocabulary',
      program: 'Weather seasons',
      topic: 'Favourite season',
      status: 'new',
      display_program: 'Weather seasons',
      display_topic: 'Favourite season',
    },
    {
      id: 31132,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L1-General conversation',
      program: 'Nature (animals)',
      topic: 'Wild Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Wild Animals',
    },
    {
      id: 31133,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L1-Grammar',
      program: 'Nature (animals)',
      topic: 'Wild Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Wild Animals',
    },
    {
      id: 31134,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L1-Vocabulary',
      program: 'Nature (animals)',
      topic: 'Wild Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Wild Animals',
    },
    {
      id: 31135,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L2-General conversation',
      program: 'Nature (animals)',
      topic: 'Pets and Domestic Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Pets and Domestic Animals',
    },
    {
      id: 31136,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L2-Grammar',
      program: 'Nature (animals)',
      topic: 'Pets and Domestic Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Pets and Domestic Animals',
    },
    {
      id: 31137,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L2-Vocabulary',
      program: 'Nature (animals)',
      topic: 'Pets and Domestic Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Pets and Domestic Animals',
    },
    {
      id: 31138,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L3-General conversation',
      program: 'Nature (animals)',
      topic: 'Animal Descriptions',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Animal Descriptions',
    },
    {
      id: 31139,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L3-Grammar',
      program: 'Nature (animals)',
      topic: 'Animal Descriptions',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Animal Descriptions',
    },
    {
      id: 31140,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L3-Vocabulary',
      program: 'Nature (animals)',
      topic: 'Animal Descriptions',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Animal Descriptions',
    },
    {
      id: 31141,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L4-General conversation',
      program: 'Nature (animals)',
      topic: 'Endangered Species',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Endangered Species',
    },
    {
      id: 31142,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L4-Grammar',
      program: 'Nature (animals)',
      topic: 'Endangered Species',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Endangered Species',
    },
    {
      id: 31143,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L4-Vocabulary',
      program: 'Nature (animals)',
      topic: 'Endangered Species',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Endangered Species',
    },
    {
      id: 31144,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L5-General conversation',
      program: 'Nature (animals)',
      topic:
        '(Animal Habitats (Different types of habitats (forest, jungle, ocean, etc.))',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic:
        '(Animal Habitats (Different types of habitats (forest, jungle, ocean, etc.))',
    },
    {
      id: 31145,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L5-Grammar',
      program: 'Nature (animals)',
      topic:
        '(Animal Habitats (Different types of habitats (forest, jungle, ocean, etc.))',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic:
        '(Animal Habitats (Different types of habitats (forest, jungle, ocean, etc.))',
    },
    {
      id: 31146,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L5-Vocabulary',
      program: 'Nature (animals)',
      topic:
        '(Animal Habitats (Different types of habitats (forest, jungle, ocean, etc.))',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic:
        '(Animal Habitats (Different types of habitats (forest, jungle, ocean, etc.))',
    },
    {
      id: 31148,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L6-Grammar',
      program: 'Nature (animals)',
      topic: 'Feeding and Caring for Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Feeding and Caring for Animals',
    },
    {
      id: 31149,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L6-Vocabulary',
      program: 'Nature (animals)',
      topic: 'Feeding and Caring for Animals',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Feeding and Caring for Animals',
    },
    {
      id: 31150,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'General conversation',
      tag: 'A2-M9-L7-General conversation',
      program: 'Nature (animals)',
      topic: 'Animals in Culture and Stories',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Animals in Culture and Stories',
    },
    {
      id: 31151,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Grammar',
      tag: 'A2-M9-L7-Grammar',
      program: 'Nature (animals)',
      topic: 'Animals in Culture and Stories',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Animals in Culture and Stories',
    },
    {
      id: 31152,
      level: 'A2',
      month: 'M9',
      lesson: 'M9',
      lesson_type: 'Vocabulary',
      tag: 'A2-M9-L7-Vocabulary',
      program: 'Nature (animals)',
      topic: 'Animals in Culture and Stories',
      status: 'new',
      display_program: 'Nature (animals)',
      display_topic: 'Animals in Culture and Stories',
    },
    {
      id: 31153,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L1-General conversation',
      program: 'Body and Health',
      topic: 'Parts of body',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Parts of body',
    },
    {
      id: 31154,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L1-Grammar',
      program: 'Body and Health',
      topic: 'Parts of body',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Parts of body',
    },
    {
      id: 31155,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L1-Vocabulary',
      program: 'Body and Health',
      topic: 'Parts of body',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Parts of body',
    },
    {
      id: 31156,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L2-General conversation',
      program: 'Body and Health',
      topic: 'Health Problems (Common illnesses and how to describe symptoms)',
      status: 'new',
      display_program: 'Body and Health',
      display_topic:
        'Health Problems (Common illnesses and how to describe symptoms)',
    },
    {
      id: 31157,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L2-Grammar',
      program: 'Body and Health',
      topic: 'Health Problems (Common illnesses and how to describe symptoms)',
      status: 'new',
      display_program: 'Body and Health',
      display_topic:
        'Health Problems (Common illnesses and how to describe symptoms)',
    },
    {
      id: 31158,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L2-Vocabulary',
      program: 'Body and Health',
      topic: 'Health Problems (Common illnesses and how to describe symptoms)',
      status: 'new',
      display_program: 'Body and Health',
      display_topic:
        'Health Problems (Common illnesses and how to describe symptoms)',
    },
    {
      id: 31159,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L3-General conversation',
      program: 'Body and Health',
      topic: 'Going to the Doctor',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Going to the Doctor',
    },
    {
      id: 31160,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L3-Grammar',
      program: 'Body and Health',
      topic: 'Going to the Doctor',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Going to the Doctor',
    },
    {
      id: 31161,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L3-Vocabulary',
      program: 'Body and Health',
      topic: 'Going to the Doctor',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Going to the Doctor',
    },
    {
      id: 31162,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L4-General conversation',
      program: 'Body and Health',
      topic: 'Medicine and Treatments',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Medicine and Treatments',
    },
    {
      id: 31163,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L4-Grammar',
      program: 'Body and Health',
      topic: 'Medicine and Treatments',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Medicine and Treatments',
    },
    {
      id: 31164,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L4-Vocabulary',
      program: 'Body and Health',
      topic: 'Medicine and Treatments',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Medicine and Treatments',
    },
    {
      id: 31165,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L5-General conversation',
      program: 'Body and Health',
      topic: 'At the pharmacy',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'At the pharmacy',
    },
    {
      id: 31166,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L5-Grammar',
      program: 'Body and Health',
      topic: 'At the pharmacy',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'At the pharmacy',
    },
    {
      id: 31167,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L5-Vocabulary',
      program: 'Body and Health',
      topic: 'At the pharmacy',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'At the pharmacy',
    },
    {
      id: 31168,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L6-General conversation',
      program: 'Body and Health',
      topic: 'Injuries and First Aid',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Injuries and First Aid',
    },
    {
      id: 31169,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L6-Grammar',
      program: 'Body and Health',
      topic: 'Injuries and First Aid',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Injuries and First Aid',
    },
    {
      id: 31170,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L6-Vocabulary',
      program: 'Body and Health',
      topic: 'Injuries and First Aid',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Injuries and First Aid',
    },
    {
      id: 31171,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'General conversation',
      tag: 'A2-M10-L7-General conversation',
      program: 'Body and Health',
      topic: 'Fears and phobias',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Fears and phobias',
    },
    {
      id: 31172,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Grammar',
      tag: 'A2-M10-L7-Grammar',
      program: 'Body and Health',
      topic: 'Fears and phobias',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Fears and phobias',
    },
    {
      id: 31173,
      level: 'A2',
      month: 'M10',
      lesson: 'M10',
      lesson_type: 'Vocabulary',
      tag: 'A2-M10-L7-Vocabulary',
      program: 'Body and Health',
      topic: 'Fears and phobias',
      status: 'new',
      display_program: 'Body and Health',
      display_topic: 'Fears and phobias',
    },
  ],
  {
    name: 'sdf',
    level: 'A2',
    onboarding_info: {
      goal: 'Relationships',
      interests: ['Science', 'Movies', 'Health'],
      profession: 'Research Scientist',
    },
    phone_number: null,
    account_confirmed: true,
  },
  [
    {
      id: 1928,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 1,
      topic: 'Introducing Yourself',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Introducing Yourself',
    },
    {
      id: 1929,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 1,
      topic: 'Introducing Yourself',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Introducing Yourself',
    },
    {
      id: 1930,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 1,
      topic: 'Introducing Yourself',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Introducing Yourself',
    },
    {
      id: 1931,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 2,
      topic: 'Days of the Week',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Days of the Week',
    },
    {
      id: 1932,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 2,
      topic: 'Days of the Week',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Days of the Week',
    },
    {
      id: 1933,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 2,
      topic: 'Days of the Week',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Days of the Week',
    },
    {
      id: 1934,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 3,
      topic: 'Leisure Activities',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Leisure Activities',
    },
    {
      id: 1935,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 3,
      topic: 'Leisure Activities',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Leisure Activities',
    },
    {
      id: 1936,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 3,
      topic: 'Leisure Activities',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Leisure Activities',
    },
    {
      id: 1937,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 4,
      topic: 'Meals during the Day',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Meals during the Day',
    },
    {
      id: 1938,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 4,
      topic: 'Meals during the Day',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Meals during the Day',
    },
    {
      id: 1939,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 4,
      topic: 'Meals during the Day',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Meals during the Day',
    },
    {
      id: 1940,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 5,
      topic: 'Sport',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Sport',
    },
    {
      id: 1941,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 5,
      topic: 'Sport',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Sport',
    },
    {
      id: 1942,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 5,
      topic: 'Sport',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Sport',
    },
    {
      id: 1943,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 6,
      topic: 'Everyday Routine',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Everyday Routine',
    },
    {
      id: 1944,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 6,
      topic: 'Everyday Routine',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Everyday Routine',
    },
    {
      id: 1945,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 6,
      topic: 'Everyday Routine',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Everyday Routine',
    },
    {
      id: 1946,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 7,
      topic: 'Weekend Activities',
      lesson_type_order: 0,
      display_program: 'Basic Communication Skills',
      display_topic: 'Weekend Activities',
    },
    {
      id: 1947,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 7,
      topic: 'Weekend Activities',
      lesson_type_order: 1,
      display_program: 'Basic Communication Skills',
      display_topic: 'Weekend Activities',
    },
    {
      id: 1948,
      level: 'A2',
      month: 1,
      program: 'Basic Communication Skills',
      lesson: 7,
      topic: 'Weekend Activities',
      lesson_type_order: 2,
      display_program: 'Basic Communication Skills',
      display_topic: 'Weekend Activities',
    },
    {
      id: 1949,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 1,
      topic: 'Family Members',
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Members',
    },
    {
      id: 1950,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 1,
      topic: 'Family Members',
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Members',
    },
    {
      id: 1951,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 2,
      topic: 'Describing family',
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: 'Describing family',
    },
    {
      id: 1952,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 2,
      topic: 'Describing family',
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: 'Describing family',
    },
    {
      id: 1953,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 2,
      topic: 'Describing family',
      lesson_type_order: 2,
      display_program: 'Nearest and Dearest',
      display_topic: 'Describing family',
    },
    {
      id: 1954,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 3,
      topic: "Family's Everyday Activities",
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: "Family's Everyday Activities",
    },
    {
      id: 1955,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 3,
      topic: "Family's Everyday Activities",
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: "Family's Everyday Activities",
    },
    {
      id: 1956,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 3,
      topic: "Family's Everyday Activities",
      lesson_type_order: 2,
      display_program: 'Nearest and Dearest',
      display_topic: "Family's Everyday Activities",
    },
    {
      id: 1957,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 4,
      topic: 'Family Traditions and Celebrations',
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Traditions and Celebrations',
    },
    {
      id: 1958,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 4,
      topic: 'Family Traditions and Celebrations',
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Traditions and Celebrations',
    },
    {
      id: 1959,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 4,
      topic: 'Family Traditions and Celebrations',
      lesson_type_order: 2,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Traditions and Celebrations',
    },
    {
      id: 1960,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 5,
      topic: 'Making Plans with Friends ',
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: 'Making Plans with Friends ',
    },
    {
      id: 1961,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 5,
      topic: 'Making Plans with Friends ',
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: 'Making Plans with Friends ',
    },
    {
      id: 1962,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 5,
      topic: 'Making Plans with Friends ',
      lesson_type_order: 2,
      display_program: 'Nearest and Dearest',
      display_topic: 'Making Plans with Friends ',
    },
    {
      id: 1963,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 6,
      topic: 'Family Conflicts',
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Conflicts',
    },
    {
      id: 1964,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 6,
      topic: 'Family Conflicts',
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Conflicts',
    },
    {
      id: 1965,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 6,
      topic: 'Family Conflicts',
      lesson_type_order: 2,
      display_program: 'Nearest and Dearest',
      display_topic: 'Family Conflicts',
    },
    {
      id: 1966,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 7,
      topic: 'Problems with Teens',
      lesson_type_order: 0,
      display_program: 'Nearest and Dearest',
      display_topic: 'Problems with Teens',
    },
    {
      id: 1967,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 7,
      topic: 'Problems with Teens',
      lesson_type_order: 1,
      display_program: 'Nearest and Dearest',
      display_topic: 'Problems with Teens',
    },
    {
      id: 1968,
      level: 'A2',
      month: 2,
      program: 'Nearest and Dearest',
      lesson: 7,
      topic: 'Problems with Teens',
      lesson_type_order: 2,
      display_program: 'Nearest and Dearest',
      display_topic: 'Problems with Teens',
    },
    {
      id: 1969,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 1,
      topic: 'Types of Housing',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Types of Housing',
    },
    {
      id: 1970,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 1,
      topic: 'Types of Housing',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Types of Housing',
    },
    {
      id: 1971,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 1,
      topic: 'Types of Housing',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Types of Housing',
    },
    {
      id: 1972,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 2,
      topic: 'Room Tour',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Room Tour',
    },
    {
      id: 1973,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 2,
      topic: 'Room Tour',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Room Tour',
    },
    {
      id: 1974,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 2,
      topic: 'Room Tour',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Room Tour',
    },
    {
      id: 1975,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 3,
      topic: 'Furniture and Household Items',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Furniture and Household Items',
    },
    {
      id: 1976,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 3,
      topic: 'Furniture and Household Items',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Furniture and Household Items',
    },
    {
      id: 1977,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 3,
      topic: 'Furniture and Household Items',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Furniture and Household Items',
    },
    {
      id: 1978,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 4,
      topic: 'Describing Your Home',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Describing Your Home',
    },
    {
      id: 1979,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 4,
      topic: 'Describing Your Home',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Describing Your Home',
    },
    {
      id: 1980,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 4,
      topic: 'Describing Your Home',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Describing Your Home',
    },
    {
      id: 1981,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 5,
      topic: 'Out of the City',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Out of the City',
    },
    {
      id: 1982,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 5,
      topic: 'Out of the City',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Out of the City',
    },
    {
      id: 1983,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 5,
      topic: 'Out of the City',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Out of the City',
    },
    {
      id: 1984,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 6,
      topic: 'Big City Life',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Big City Life',
    },
    {
      id: 1985,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 6,
      topic: 'Big City Life',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Big City Life',
    },
    {
      id: 1986,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 6,
      topic: 'Big City Life',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Big City Life',
    },
    {
      id: 1987,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 7,
      topic: 'Directions',
      lesson_type_order: 0,
      display_program: 'There is no Place Like Home',
      display_topic: 'Directions',
    },
    {
      id: 1988,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 7,
      topic: 'Directions',
      lesson_type_order: 1,
      display_program: 'There is no Place Like Home',
      display_topic: 'Directions',
    },
    {
      id: 1989,
      level: 'A2',
      month: 3,
      program: 'There is no Place Like Home',
      lesson: 7,
      topic: 'Directions',
      lesson_type_order: 2,
      display_program: 'There is no Place Like Home',
      display_topic: 'Directions',
    },
    {
      id: 1990,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 1,
      topic: 'Common Hobbies and Interests',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Common Hobbies and Interests',
    },
    {
      id: 1991,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 1,
      topic: 'Common Hobbies and Interests',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Common Hobbies and Interests',
    },
    {
      id: 1992,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 1,
      topic: 'Common Hobbies and Interests',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Common Hobbies and Interests',
    },
    {
      id: 1993,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 2,
      topic: 'Sports and Physical Activities',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Sports and Physical Activities',
    },
    {
      id: 1994,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 2,
      topic: 'Sports and Physical Activities',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Sports and Physical Activities',
    },
    {
      id: 1995,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 2,
      topic: 'Sports and Physical Activities',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Sports and Physical Activities',
    },
    {
      id: 1996,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 3,
      topic: 'Arts and Crafts',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Arts and Crafts',
    },
    {
      id: 1997,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 3,
      topic: 'Arts and Crafts',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Arts and Crafts',
    },
    {
      id: 1998,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 3,
      topic: 'Arts and Crafts',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Arts and Crafts',
    },
    {
      id: 1999,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 4,
      topic: 'Music and Entertainment',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Music and Entertainment',
    },
    {
      id: 2000,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 4,
      topic: 'Music and Entertainment',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Music and Entertainment',
    },
    {
      id: 2001,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 4,
      topic: 'Music and Entertainment',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Music and Entertainment',
    },
    {
      id: 2002,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 5,
      topic: 'Reading and Literature',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Reading and Literature',
    },
    {
      id: 2003,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 5,
      topic: 'Reading and Literature',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Reading and Literature',
    },
    {
      id: 2004,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 5,
      topic: 'Reading and Literature',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Reading and Literature',
    },
    {
      id: 2005,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 6,
      topic: 'Outdoor Activities',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Outdoor Activities',
    },
    {
      id: 2006,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 6,
      topic: 'Outdoor Activities',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Outdoor Activities',
    },
    {
      id: 2007,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 6,
      topic: 'Outdoor Activities',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Outdoor Activities',
    },
    {
      id: 2008,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 7,
      topic: 'Popular Hobbies in Different Countries',
      lesson_type_order: 0,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Popular Hobbies in Different Countries',
    },
    {
      id: 2009,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 7,
      topic: 'Popular Hobbies in Different Countries',
      lesson_type_order: 1,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Popular Hobbies in Different Countries',
    },
    {
      id: 2010,
      level: 'A2',
      month: 4,
      program: 'Finding Joy in Hobbies',
      lesson: 7,
      topic: 'Popular Hobbies in Different Countries',
      lesson_type_order: 2,
      display_program: 'Finding Joy in Hobbies',
      display_topic: 'Popular Hobbies in Different Countries',
    },
    {
      id: 2011,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 1,
      topic: 'Types of Transport',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Types of Transport',
    },
    {
      id: 2012,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 1,
      topic: 'Types of Transport',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Types of Transport',
    },
    {
      id: 2013,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 1,
      topic: 'Types of Transport',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Types of Transport',
    },
    {
      id: 2014,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 2,
      topic: 'Buying Tickets',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Buying Tickets',
    },
    {
      id: 2015,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 2,
      topic: 'Buying Tickets',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Buying Tickets',
    },
    {
      id: 2016,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 2,
      topic: 'Buying Tickets',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Buying Tickets',
    },
    {
      id: 2017,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 3,
      topic: 'Airport and Railway Station',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Airport and Railway Station',
    },
    {
      id: 2018,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 3,
      topic: 'Airport and Railway Station',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Airport and Railway Station',
    },
    {
      id: 2019,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 3,
      topic: 'Airport and Railway Station',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Airport and Railway Station',
    },
    {
      id: 2020,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 4,
      topic: 'Travel Preparations',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Travel Preparations',
    },
    {
      id: 2021,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 4,
      topic: 'Travel Preparations',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Travel Preparations',
    },
    {
      id: 2022,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 4,
      topic: 'Travel Preparations',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Travel Preparations',
    },
    {
      id: 2023,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 5,
      topic: 'Accommodation',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Accommodation',
    },
    {
      id: 2024,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 5,
      topic: 'Accommodation',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Accommodation',
    },
    {
      id: 2025,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 5,
      topic: 'Accommodation',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Accommodation',
    },
    {
      id: 2026,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 6,
      topic: 'Amazing Transport',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Amazing Transport',
    },
    {
      id: 2027,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 6,
      topic: 'Amazing Transport',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Amazing Transport',
    },
    {
      id: 2028,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 6,
      topic: 'Amazing Transport',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Amazing Transport',
    },
    {
      id: 2029,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 7,
      topic: 'Around the World',
      lesson_type_order: 0,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Around the World',
    },
    {
      id: 2030,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 7,
      topic: 'Around the World',
      lesson_type_order: 1,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Around the World',
    },
    {
      id: 2031,
      level: 'A2',
      month: 5,
      program: 'Tales of Transport and Adventure',
      lesson: 7,
      topic: 'Around the World',
      lesson_type_order: 2,
      display_program: 'Tales of Transport and Adventure',
      display_topic: 'Around the World',
    },
    {
      id: 2032,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 1,
      topic: 'Types of Shops ',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'Types of Shops ',
    },
    {
      id: 2033,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 1,
      topic: 'Types of Shops ',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'Types of Shops ',
    },
    {
      id: 2034,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 1,
      topic: 'Types of Shops ',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'Types of Shops ',
    },
    {
      id: 2035,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 2,
      topic: 'Shopping for Clothes',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'Shopping for Clothes',
    },
    {
      id: 2036,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 2,
      topic: 'Shopping for Clothes',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'Shopping for Clothes',
    },
    {
      id: 2037,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 2,
      topic: 'Shopping for Clothes',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'Shopping for Clothes',
    },
    {
      id: 2038,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 3,
      topic: 'Grocery Shopping',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'Grocery Shopping',
    },
    {
      id: 2039,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 3,
      topic: 'Grocery Shopping',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'Grocery Shopping',
    },
    {
      id: 2040,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 3,
      topic: 'Grocery Shopping',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'Grocery Shopping',
    },
    {
      id: 2041,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 4,
      topic: 'At the Market',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'At the Market',
    },
    {
      id: 2042,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 4,
      topic: 'At the Market',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'At the Market',
    },
    {
      id: 2043,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 4,
      topic: 'At the Market',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'At the Market',
    },
    {
      id: 2044,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 5,
      topic: 'What’s on the Menu?',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'What’s on the Menu?',
    },
    {
      id: 2045,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 5,
      topic: 'What’s on the Menu?',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'What’s on the Menu?',
    },
    {
      id: 2046,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 5,
      topic: 'What’s on the Menu?',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'What’s on the Menu?',
    },
    {
      id: 2047,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 6,
      topic: 'Taste It',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'Taste It',
    },
    {
      id: 2048,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 6,
      topic: 'Taste It',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'Taste It',
    },
    {
      id: 2049,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 6,
      topic: 'Taste It',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'Taste It',
    },
    {
      id: 2050,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 7,
      topic: 'Sharing Recipes',
      lesson_type_order: 0,
      display_program: "A Shopper's Guide",
      display_topic: 'Sharing Recipes',
    },
    {
      id: 2051,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 7,
      topic: 'Sharing Recipes',
      lesson_type_order: 1,
      display_program: "A Shopper's Guide",
      display_topic: 'Sharing Recipes',
    },
    {
      id: 2052,
      level: 'A2',
      month: 6,
      program: "A Shopper's Guide",
      lesson: 7,
      topic: 'Sharing Recipes',
      lesson_type_order: 2,
      display_program: "A Shopper's Guide",
      display_topic: 'Sharing Recipes',
    },
    {
      id: 2053,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 1,
      topic: 'Types of Jobs ',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'Types of Jobs ',
    },
    {
      id: 2054,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 1,
      topic: 'Types of Jobs ',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'Types of Jobs ',
    },
    {
      id: 2055,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 1,
      topic: 'Types of Jobs ',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'Types of Jobs ',
    },
    {
      id: 2056,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 2,
      topic: 'Job Responsibilities ',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'Job Responsibilities ',
    },
    {
      id: 2057,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 2,
      topic: 'Job Responsibilities ',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'Job Responsibilities ',
    },
    {
      id: 2058,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 2,
      topic: 'Job Responsibilities ',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'Job Responsibilities ',
    },
    {
      id: 2059,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 3,
      topic: 'Job Hunting',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'Job Hunting',
    },
    {
      id: 2060,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 3,
      topic: 'Job Hunting',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'Job Hunting',
    },
    {
      id: 2061,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 3,
      topic: 'Job Hunting',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'Job Hunting',
    },
    {
      id: 2062,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 4,
      topic: 'At the Workplace',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'At the Workplace',
    },
    {
      id: 2063,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 4,
      topic: 'At the Workplace',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'At the Workplace',
    },
    {
      id: 2064,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 4,
      topic: 'At the Workplace',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'At the Workplace',
    },
    {
      id: 2065,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 5,
      topic: 'Daily Work Routines and Work Schedules',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'Daily Work Routines and Work Schedules',
    },
    {
      id: 2066,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 5,
      topic: 'Daily Work Routines and Work Schedules',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'Daily Work Routines and Work Schedules',
    },
    {
      id: 2067,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 5,
      topic: 'Daily Work Routines and Work Schedules',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'Daily Work Routines and Work Schedules',
    },
    {
      id: 2068,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 6,
      topic: 'From Rags to Riches',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'From Rags to Riches',
    },
    {
      id: 2069,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 6,
      topic: 'From Rags to Riches',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'From Rags to Riches',
    },
    {
      id: 2070,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 6,
      topic: 'From Rags to Riches',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'From Rags to Riches',
    },
    {
      id: 2071,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 7,
      topic: 'Dream Job',
      lesson_type_order: 0,
      display_program: 'Find Your Job',
      display_topic: 'Dream Job',
    },
    {
      id: 2072,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 7,
      topic: 'Dream Job',
      lesson_type_order: 1,
      display_program: 'Find Your Job',
      display_topic: 'Dream Job',
    },
    {
      id: 2073,
      level: 'A2',
      month: 7,
      program: 'Find Your Job',
      lesson: 7,
      topic: 'Dream Job',
      lesson_type_order: 2,
      display_program: 'Find Your Job',
      display_topic: 'Dream Job',
    },
    {
      id: 2074,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 1,
      topic: 'Weather Types ',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Weather Types ',
    },
    {
      id: 2075,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 1,
      topic: 'Weather Types ',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Weather Types ',
    },
    {
      id: 2076,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 1,
      topic: 'Weather Types ',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Weather Types ',
    },
    {
      id: 2077,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 2,
      topic: 'Seasons and Months',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Seasons and Months',
    },
    {
      id: 2078,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 2,
      topic: 'Seasons and Months',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Seasons and Months',
    },
    {
      id: 2079,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 2,
      topic: 'Seasons and Months',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Seasons and Months',
    },
    {
      id: 2080,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 3,
      topic: 'Clothing for Different Weathers',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Clothing for Different Weathers',
    },
    {
      id: 2081,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 3,
      topic: 'Clothing for Different Weathers',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Clothing for Different Weathers',
    },
    {
      id: 2082,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 3,
      topic: 'Clothing for Different Weathers',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Clothing for Different Weathers',
    },
    {
      id: 2083,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 4,
      topic: 'Four Seasons: Activities',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Four Seasons: Activities',
    },
    {
      id: 2084,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 4,
      topic: 'Four Seasons: Activities',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Four Seasons: Activities',
    },
    {
      id: 2085,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 4,
      topic: 'Four Seasons: Activities',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Four Seasons: Activities',
    },
    {
      id: 2086,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 5,
      topic: 'Extreme Weather',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Extreme Weather',
    },
    {
      id: 2087,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 5,
      topic: 'Extreme Weather',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Extreme Weather',
    },
    {
      id: 2088,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 5,
      topic: 'Extreme Weather',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Extreme Weather',
    },
    {
      id: 2089,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 6,
      topic: 'Weather Forecast',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Weather Forecast',
    },
    {
      id: 2090,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 6,
      topic: 'Weather Forecast',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Weather Forecast',
    },
    {
      id: 2091,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 6,
      topic: 'Weather Forecast',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Weather Forecast',
    },
    {
      id: 2092,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 7,
      topic: 'Favourite Season',
      lesson_type_order: 0,
      display_program: 'After the storm',
      display_topic: 'Favourite Season',
    },
    {
      id: 2093,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 7,
      topic: 'Favourite Season',
      lesson_type_order: 1,
      display_program: 'After the storm',
      display_topic: 'Favourite Season',
    },
    {
      id: 2094,
      level: 'A2',
      month: 8,
      program: 'After the storm',
      lesson: 7,
      topic: 'Favourite Season',
      lesson_type_order: 2,
      display_program: 'After the storm',
      display_topic: 'Favourite Season',
    },
    {
      id: 2095,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 1,
      topic: 'Wild Animals',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Wild Animals',
    },
    {
      id: 2096,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 1,
      topic: 'Wild Animals',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Wild Animals',
    },
    {
      id: 2097,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 1,
      topic: 'Wild Animals',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Wild Animals',
    },
    {
      id: 2098,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 2,
      topic: 'Pets and Domestic Animals',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Pets and Domestic Animals',
    },
    {
      id: 2099,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 2,
      topic: 'Pets and Domestic Animals',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Pets and Domestic Animals',
    },
    {
      id: 2100,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 2,
      topic: 'Pets and Domestic Animals',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Pets and Domestic Animals',
    },
    {
      id: 2101,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 3,
      topic: 'Animal Descriptions',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Animal Descriptions',
    },
    {
      id: 2102,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 3,
      topic: 'Animal Descriptions',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Animal Descriptions',
    },
    {
      id: 2103,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 3,
      topic: 'Animal Descriptions',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Animal Descriptions',
    },
    {
      id: 2104,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 4,
      topic: 'Endangered Species',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Endangered Species',
    },
    {
      id: 2105,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 4,
      topic: 'Endangered Species',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Endangered Species',
    },
    {
      id: 2106,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 4,
      topic: 'Endangered Species',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Endangered Species',
    },
    {
      id: 2107,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 5,
      topic: 'Habitats: Forest, Jungle, Ocean',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Habitats: Forest, Jungle, Ocean',
    },
    {
      id: 2108,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 5,
      topic: 'Habitats: Forest, Jungle, Ocean',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Habitats: Forest, Jungle, Ocean',
    },
    {
      id: 2109,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 5,
      topic: 'Habitats: Forest, Jungle, Ocean',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Habitats: Forest, Jungle, Ocean',
    },
    {
      id: 2110,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 6,
      topic: 'Caring for Animals',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Caring for Animals',
    },
    {
      id: 2111,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 6,
      topic: 'Caring for Animals',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Caring for Animals',
    },
    {
      id: 2112,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 6,
      topic: 'Caring for Animals',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Caring for Animals',
    },
    {
      id: 2113,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 7,
      topic: 'Animals in Culture and Stories',
      lesson_type_order: 0,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Animals in Culture and Stories',
    },
    {
      id: 2114,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 7,
      topic: 'Animals in Culture and Stories',
      lesson_type_order: 1,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Animals in Culture and Stories',
    },
    {
      id: 2115,
      level: 'A2',
      month: 9,
      program: 'Exploring the Animal Kingdom',
      lesson: 7,
      topic: 'Animals in Culture and Stories',
      lesson_type_order: 2,
      display_program: 'Exploring the Animal Kingdom',
      display_topic: 'Animals in Culture and Stories',
    },
    {
      id: 2116,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 1,
      topic: 'Head, Shoulders, Knees, and Toes',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Head, Shoulders, Knees, and Toes',
    },
    {
      id: 2117,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 1,
      topic: 'Head, Shoulders, Knees, and Toes',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Head, Shoulders, Knees, and Toes',
    },
    {
      id: 2118,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 1,
      topic: 'Head, Shoulders, Knees, and Toes',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Head, Shoulders, Knees, and Toes',
    },
    {
      id: 2119,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 2,
      topic: 'When Your Body Talks',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'When Your Body Talks',
    },
    {
      id: 2120,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 2,
      topic: 'When Your Body Talks',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'When Your Body Talks',
    },
    {
      id: 2121,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 2,
      topic: 'When Your Body Talks',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'When Your Body Talks',
    },
    {
      id: 2122,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 3,
      topic: 'I Need a Doctor',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'I Need a Doctor',
    },
    {
      id: 2123,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 3,
      topic: 'I Need a Doctor',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'I Need a Doctor',
    },
    {
      id: 2124,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 3,
      topic: 'I Need a Doctor',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'I Need a Doctor',
    },
    {
      id: 2125,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 4,
      topic: 'Medicine and Treatments',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Medicine and Treatments',
    },
    {
      id: 2126,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 4,
      topic: 'Medicine and Treatments',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Medicine and Treatments',
    },
    {
      id: 2127,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 4,
      topic: 'Medicine and Treatments',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Medicine and Treatments',
    },
    {
      id: 2128,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 5,
      topic: 'At the Pharmacy',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'At the Pharmacy',
    },
    {
      id: 2129,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 5,
      topic: 'At the Pharmacy',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'At the Pharmacy',
    },
    {
      id: 2130,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 5,
      topic: 'At the Pharmacy',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'At the Pharmacy',
    },
    {
      id: 2131,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 6,
      topic: 'Injuries and First Aid',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Injuries and First Aid',
    },
    {
      id: 2132,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 6,
      topic: 'Injuries and First Aid',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Injuries and First Aid',
    },
    {
      id: 2133,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 6,
      topic: 'Injuries and First Aid',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Injuries and First Aid',
    },
    {
      id: 2134,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 7,
      topic: 'Fears and Phobias',
      lesson_type_order: 0,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Fears and Phobias',
    },
    {
      id: 2135,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 7,
      topic: 'Fears and Phobias',
      lesson_type_order: 1,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Fears and Phobias',
    },
    {
      id: 2136,
      level: 'A2',
      month: 10,
      program: 'An Apple a Day: Secrets to Health',
      lesson: 7,
      topic: 'Fears and Phobias',
      lesson_type_order: 2,
      display_program: 'An Apple a Day: Secrets to Health',
      display_topic: 'Fears and Phobias',
    },
    {
      id: 2137,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 1,
      topic: 'Meet and Greet',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Meet and Greet',
    },
    {
      id: 2138,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 1,
      topic: 'Meet and Greet',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Meet and Greet',
    },
    {
      id: 2139,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 1,
      topic: 'Meet and Greet',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Meet and Greet',
    },
    {
      id: 2140,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 2,
      topic: 'Daily Routine',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Daily Routine',
    },
    {
      id: 2141,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 2,
      topic: 'Daily Routine',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Daily Routine',
    },
    {
      id: 2142,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 2,
      topic: 'Daily Routine',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Daily Routine',
    },
    {
      id: 2143,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 2,
      topic: 'Daily Routine',
      lesson_type_order: 3,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Daily Routine',
    },
    {
      id: 2144,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 3,
      topic: 'Family and Friends: a Brief Portrait',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Family and Friends: a Brief Portrait',
    },
    {
      id: 2145,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 3,
      topic: 'Family and Friends: a Brief Portrait',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Family and Friends: a Brief Portrait',
    },
    {
      id: 2146,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 3,
      topic: 'Family and Friends: a Brief Portrait',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Family and Friends: a Brief Portrait',
    },
    {
      id: 2147,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 4,
      topic: 'Hobbies and Interests',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Hobbies and Interests',
    },
    {
      id: 2148,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 4,
      topic: 'Hobbies and Interests',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Hobbies and Interests',
    },
    {
      id: 2149,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 4,
      topic: 'Hobbies and Interests',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Hobbies and Interests',
    },
    {
      id: 2150,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 4,
      topic: 'Hobbies and Interests',
      lesson_type_order: 3,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Hobbies and Interests',
    },
    {
      id: 2151,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 5,
      topic: 'Plans and Invites: the Simple Guide',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Plans and Invites: the Simple Guide',
    },
    {
      id: 2152,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 5,
      topic: 'Plans and Invites: the Simple Guide',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Plans and Invites: the Simple Guide',
    },
    {
      id: 2153,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 5,
      topic: 'Plans and Invites: the Simple Guide',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Plans and Invites: the Simple Guide',
    },
    {
      id: 2154,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 6,
      topic: 'Shopping and Transactions',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Shopping and Transactions',
    },
    {
      id: 2155,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 6,
      topic: 'Shopping and Transactions',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Shopping and Transactions',
    },
    {
      id: 2156,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 6,
      topic: 'Shopping and Transactions',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Shopping and Transactions',
    },
    {
      id: 2157,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 6,
      topic: 'Shopping and Transactions',
      lesson_type_order: 3,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Shopping and Transactions',
    },
    {
      id: 2158,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 7,
      topic: 'Eating Out',
      lesson_type_order: 0,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Eating Out',
    },
    {
      id: 2159,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 7,
      topic: 'Eating Out',
      lesson_type_order: 1,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Eating Out',
    },
    {
      id: 2160,
      level: 'B1',
      month: 1,
      program: 'Mastering Daily Dialogues',
      lesson: 7,
      topic: 'Eating Out',
      lesson_type_order: 2,
      display_program: 'Mastering Daily Dialogues',
      display_topic: 'Eating Out',
    },
    {
      id: 2161,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 1,
      topic: 'Job Roles: Defining Your Work Identity',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Job Roles: Defining Your Work Identity',
    },
    {
      id: 2162,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 1,
      topic: 'Job Roles: Defining Your Work Identity',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Job Roles: Defining Your Work Identity',
    },
    {
      id: 2163,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 1,
      topic: 'Job Roles: Defining Your Work Identity',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Job Roles: Defining Your Work Identity',
    },
    {
      id: 2164,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 2,
      topic: 'Writing a CV and Cover Letter',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Writing a CV and Cover Letter',
    },
    {
      id: 2165,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 2,
      topic: 'Writing a CV and Cover Letter',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Writing a CV and Cover Letter',
    },
    {
      id: 2166,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 2,
      topic: 'Writing a CV and Cover Letter',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Writing a CV and Cover Letter',
    },
    {
      id: 2167,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 2,
      topic: 'Writing a CV and Cover Letter',
      lesson_type_order: 3,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Writing a CV and Cover Letter',
    },
    {
      id: 2168,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 3,
      topic: 'Preparing for a Job interview',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Preparing for a Job interview',
    },
    {
      id: 2169,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 3,
      topic: 'Preparing for a Job interview',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Preparing for a Job interview',
    },
    {
      id: 2170,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 3,
      topic: 'Preparing for a Job interview',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Preparing for a Job interview',
    },
    {
      id: 2171,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 4,
      topic: 'Professional email and Phone Etiquette',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Professional email and Phone Etiquette',
    },
    {
      id: 2172,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 4,
      topic: 'Professional email and Phone Etiquette',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Professional email and Phone Etiquette',
    },
    {
      id: 2173,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 4,
      topic: 'Professional email and Phone Etiquette',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Professional email and Phone Etiquette',
    },
    {
      id: 2174,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 4,
      topic: 'Professional email and Phone Etiquette',
      lesson_type_order: 3,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Professional email and Phone Etiquette',
    },
    {
      id: 2175,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 5,
      topic: 'Career Goals and Aspirations',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Career Goals and Aspirations',
    },
    {
      id: 2176,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 5,
      topic: 'Career Goals and Aspirations',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Career Goals and Aspirations',
    },
    {
      id: 2177,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 5,
      topic: 'Career Goals and Aspirations',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Career Goals and Aspirations',
    },
    {
      id: 2178,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 6,
      topic: 'Networking ',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Networking ',
    },
    {
      id: 2179,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 6,
      topic: 'Networking ',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Networking ',
    },
    {
      id: 2180,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 6,
      topic: 'Networking ',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Networking ',
    },
    {
      id: 2181,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 6,
      topic: 'Networking ',
      lesson_type_order: 3,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Networking ',
    },
    {
      id: 2182,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 7,
      topic: 'Workplace Challenges',
      lesson_type_order: 0,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Workplace Challenges',
    },
    {
      id: 2183,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 7,
      topic: 'Workplace Challenges',
      lesson_type_order: 1,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Workplace Challenges',
    },
    {
      id: 2184,
      level: 'B1',
      month: 2,
      program: 'Climbing the Career Ladder',
      lesson: 7,
      topic: 'Workplace Challenges',
      lesson_type_order: 2,
      display_program: 'Climbing the Career Ladder',
      display_topic: 'Workplace Challenges',
    },
    {
      id: 2185,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 1,
      topic: "The Traveler's Toolkit",
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: "The Traveler's Toolkit",
    },
    {
      id: 2186,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 1,
      topic: "The Traveler's Toolkit",
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: "The Traveler's Toolkit",
    },
    {
      id: 2187,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 1,
      topic: "The Traveler's Toolkit",
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: "The Traveler's Toolkit",
    },
    {
      id: 2188,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 2,
      topic: 'Navigating Airports and Transportation',
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Navigating Airports and Transportation',
    },
    {
      id: 2189,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 2,
      topic: 'Navigating Airports and Transportation',
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Navigating Airports and Transportation',
    },
    {
      id: 2190,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 2,
      topic: 'Navigating Airports and Transportation',
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Navigating Airports and Transportation',
    },
    {
      id: 2191,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 2,
      topic: 'Navigating Airports and Transportation',
      lesson_type_order: 3,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Navigating Airports and Transportation',
    },
    {
      id: 2192,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 3,
      topic: 'Booking ',
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Booking ',
    },
    {
      id: 2193,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 3,
      topic: 'Booking ',
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Booking ',
    },
    {
      id: 2194,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 3,
      topic: 'Booking ',
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Booking ',
    },
    {
      id: 2195,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 4,
      topic: 'Exploring New Cities and Attractions',
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Exploring New Cities and Attractions',
    },
    {
      id: 2196,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 4,
      topic: 'Exploring New Cities and Attractions',
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Exploring New Cities and Attractions',
    },
    {
      id: 2197,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 4,
      topic: 'Exploring New Cities and Attractions',
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Exploring New Cities and Attractions',
    },
    {
      id: 2198,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 4,
      topic: 'Exploring New Cities and Attractions',
      lesson_type_order: 3,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Exploring New Cities and Attractions',
    },
    {
      id: 2199,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 5,
      topic: 'Sharing Travel Experiences ',
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Sharing Travel Experiences ',
    },
    {
      id: 2200,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 5,
      topic: 'Sharing Travel Experiences ',
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Sharing Travel Experiences ',
    },
    {
      id: 2201,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 5,
      topic: 'Sharing Travel Experiences ',
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Sharing Travel Experiences ',
    },
    {
      id: 2202,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 6,
      topic: 'Cultural Differences ',
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Cultural Differences ',
    },
    {
      id: 2203,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 6,
      topic: 'Cultural Differences ',
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Cultural Differences ',
    },
    {
      id: 2204,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 6,
      topic: 'Cultural Differences ',
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Cultural Differences ',
    },
    {
      id: 2205,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 6,
      topic: 'Cultural Differences ',
      lesson_type_order: 3,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Cultural Differences ',
    },
    {
      id: 2206,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 7,
      topic: 'Travel Problems',
      lesson_type_order: 0,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Travel Problems',
    },
    {
      id: 2207,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 7,
      topic: 'Travel Problems',
      lesson_type_order: 1,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Travel Problems',
    },
    {
      id: 2208,
      level: 'B1',
      month: 3,
      program: 'Beyond the Beaten Path',
      lesson: 7,
      topic: 'Travel Problems',
      lesson_type_order: 2,
      display_program: 'Beyond the Beaten Path',
      display_topic: 'Travel Problems',
    },
    {
      id: 2209,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 1,
      topic: 'Health and fitness routines',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Health and fitness routines',
    },
    {
      id: 2210,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 1,
      topic: 'Health and fitness routines',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Health and fitness routines',
    },
    {
      id: 2211,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 1,
      topic: 'Health and fitness routines',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Health and fitness routines',
    },
    {
      id: 2212,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 2,
      topic: 'Healthcare System ',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Healthcare System ',
    },
    {
      id: 2213,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 2,
      topic: 'Healthcare System ',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Healthcare System ',
    },
    {
      id: 2214,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 2,
      topic: 'Healthcare System ',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Healthcare System ',
    },
    {
      id: 2215,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 2,
      topic: 'Healthcare System ',
      lesson_type_order: 3,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Healthcare System ',
    },
    {
      id: 2216,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 3,
      topic: 'Mental Health and Well-being',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Mental Health and Well-being',
    },
    {
      id: 2217,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 3,
      topic: 'Mental Health and Well-being',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Mental Health and Well-being',
    },
    {
      id: 2218,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 3,
      topic: 'Mental Health and Well-being',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Mental Health and Well-being',
    },
    {
      id: 2219,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 4,
      topic: 'Lifestyle and Culture ',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Lifestyle and Culture ',
    },
    {
      id: 2220,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 4,
      topic: 'Lifestyle and Culture ',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Lifestyle and Culture ',
    },
    {
      id: 2221,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 4,
      topic: 'Lifestyle and Culture ',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Lifestyle and Culture ',
    },
    {
      id: 2222,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 4,
      topic: 'Lifestyle and Culture ',
      lesson_type_order: 3,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Lifestyle and Culture ',
    },
    {
      id: 2223,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 5,
      topic: 'Work Life Balance',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Work Life Balance',
    },
    {
      id: 2224,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 5,
      topic: 'Work Life Balance',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Work Life Balance',
    },
    {
      id: 2225,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 5,
      topic: 'Work Life Balance',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Work Life Balance',
    },
    {
      id: 2226,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 6,
      topic: 'Relaxation and Stress Relief Techniques',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Relaxation and Stress Relief Techniques',
    },
    {
      id: 2227,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 6,
      topic: 'Relaxation and Stress Relief Techniques',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Relaxation and Stress Relief Techniques',
    },
    {
      id: 2228,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 6,
      topic: 'Relaxation and Stress Relief Techniques',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Relaxation and Stress Relief Techniques',
    },
    {
      id: 2229,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 6,
      topic: 'Relaxation and Stress Relief Techniques',
      lesson_type_order: 3,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Relaxation and Stress Relief Techniques',
    },
    {
      id: 2230,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 7,
      topic: 'Participating in Sports and Outdoor Activities',
      lesson_type_order: 0,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Participating in Sports and Outdoor Activities',
    },
    {
      id: 2231,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 7,
      topic: 'Participating in Sports and Outdoor Activities',
      lesson_type_order: 1,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Participating in Sports and Outdoor Activities',
    },
    {
      id: 2232,
      level: 'B1',
      month: 4,
      program: 'Keys to a Healthier You',
      lesson: 7,
      topic: 'Participating in Sports and Outdoor Activities',
      lesson_type_order: 2,
      display_program: 'Keys to a Healthier You',
      display_topic: 'Participating in Sports and Outdoor Activities',
    },
    {
      id: 2233,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 1,
      topic: 'Arts Talk: Simple Insights',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Arts Talk: Simple Insights',
    },
    {
      id: 2234,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 1,
      topic: 'Arts Talk: Simple Insights',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Arts Talk: Simple Insights',
    },
    {
      id: 2235,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 1,
      topic: 'Arts Talk: Simple Insights',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Arts Talk: Simple Insights',
    },
    {
      id: 2236,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 2,
      topic: 'Attending Cultural Events and performances',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Attending Cultural Events and performances',
    },
    {
      id: 2237,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 2,
      topic: 'Attending Cultural Events and performances',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Attending Cultural Events and performances',
    },
    {
      id: 2238,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 2,
      topic: 'Attending Cultural Events and performances',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Attending Cultural Events and performances',
    },
    {
      id: 2239,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 2,
      topic: 'Attending Cultural Events and performances',
      lesson_type_order: 3,
      display_program: 'Cultural Mosaic',
      display_topic: 'Attending Cultural Events and performances',
    },
    {
      id: 2240,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 3,
      topic: 'Exploring Hobbies and Creative Pursuits',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Exploring Hobbies and Creative Pursuits',
    },
    {
      id: 2241,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 3,
      topic: 'Exploring Hobbies and Creative Pursuits',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Exploring Hobbies and Creative Pursuits',
    },
    {
      id: 2242,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 3,
      topic: 'Exploring Hobbies and Creative Pursuits',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Exploring Hobbies and Creative Pursuits',
    },
    {
      id: 2243,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 4,
      topic: 'Movies and Television Shows',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Movies and Television Shows',
    },
    {
      id: 2244,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 4,
      topic: 'Movies and Television Shows',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Movies and Television Shows',
    },
    {
      id: 2245,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 4,
      topic: 'Movies and Television Shows',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Movies and Television Shows',
    },
    {
      id: 2246,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 4,
      topic: 'Movies and Television Shows',
      lesson_type_order: 3,
      display_program: 'Cultural Mosaic',
      display_topic: 'Movies and Television Shows',
    },
    {
      id: 2247,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 5,
      topic: 'Cultural Heritage and Traditions',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Cultural Heritage and Traditions',
    },
    {
      id: 2248,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 5,
      topic: 'Cultural Heritage and Traditions',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Cultural Heritage and Traditions',
    },
    {
      id: 2249,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 5,
      topic: 'Cultural Heritage and Traditions',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Cultural Heritage and Traditions',
    },
    {
      id: 2250,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 6,
      topic: 'Visiting Museums and Historical Sites',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Visiting Museums and Historical Sites',
    },
    {
      id: 2251,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 6,
      topic: 'Visiting Museums and Historical Sites',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Visiting Museums and Historical Sites',
    },
    {
      id: 2252,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 6,
      topic: 'Visiting Museums and Historical Sites',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Visiting Museums and Historical Sites',
    },
    {
      id: 2253,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 6,
      topic: 'Visiting Museums and Historical Sites',
      lesson_type_order: 3,
      display_program: 'Cultural Mosaic',
      display_topic: 'Visiting Museums and Historical Sites',
    },
    {
      id: 2254,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 7,
      topic: 'Sharing Opinions on Cultural Topics',
      lesson_type_order: 0,
      display_program: 'Cultural Mosaic',
      display_topic: 'Sharing Opinions on Cultural Topics',
    },
    {
      id: 2255,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 7,
      topic: 'Sharing Opinions on Cultural Topics',
      lesson_type_order: 1,
      display_program: 'Cultural Mosaic',
      display_topic: 'Sharing Opinions on Cultural Topics',
    },
    {
      id: 2256,
      level: 'B1',
      month: 5,
      program: 'Cultural Mosaic',
      lesson: 7,
      topic: 'Sharing Opinions on Cultural Topics',
      lesson_type_order: 2,
      display_program: 'Cultural Mosaic',
      display_topic: 'Sharing Opinions on Cultural Topics',
    },
    {
      id: 2257,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 1,
      topic: 'Introduction to Media ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'Introduction to Media ',
    },
    {
      id: 2258,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 1,
      topic: 'Introduction to Media ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'Introduction to Media ',
    },
    {
      id: 2259,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 1,
      topic: 'Introduction to Media ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'Introduction to Media ',
    },
    {
      id: 2260,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 2,
      topic: 'Newspapers and Magazines ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'Newspapers and Magazines ',
    },
    {
      id: 2261,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 2,
      topic: 'Newspapers and Magazines ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'Newspapers and Magazines ',
    },
    {
      id: 2262,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 2,
      topic: 'Newspapers and Magazines ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'Newspapers and Magazines ',
    },
    {
      id: 2263,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 2,
      topic: 'Newspapers and Magazines ',
      lesson_type_order: 3,
      display_program: 'Media Mirror',
      display_topic: 'Newspapers and Magazines ',
    },
    {
      id: 2264,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 3,
      topic: 'Television and Radio ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'Television and Radio ',
    },
    {
      id: 2265,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 3,
      topic: 'Television and Radio ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'Television and Radio ',
    },
    {
      id: 2266,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 3,
      topic: 'Television and Radio ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'Television and Radio ',
    },
    {
      id: 2267,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 4,
      topic: 'Online Media ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'Online Media ',
    },
    {
      id: 2268,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 4,
      topic: 'Online Media ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'Online Media ',
    },
    {
      id: 2269,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 4,
      topic: 'Online Media ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'Online Media ',
    },
    {
      id: 2270,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 4,
      topic: 'Online Media ',
      lesson_type_order: 3,
      display_program: 'Media Mirror',
      display_topic: 'Online Media ',
    },
    {
      id: 2271,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 5,
      topic: 'Advertising ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'Advertising ',
    },
    {
      id: 2272,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 5,
      topic: 'Advertising ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'Advertising ',
    },
    {
      id: 2273,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 5,
      topic: 'Advertising ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'Advertising ',
    },
    {
      id: 2274,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 6,
      topic: 'Journalism ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'Journalism ',
    },
    {
      id: 2275,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 6,
      topic: 'Journalism ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'Journalism ',
    },
    {
      id: 2276,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 6,
      topic: 'Journalism ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'Journalism ',
    },
    {
      id: 2277,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 6,
      topic: 'Journalism ',
      lesson_type_order: 3,
      display_program: 'Media Mirror',
      display_topic: 'Journalism ',
    },
    {
      id: 2278,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 7,
      topic: 'The Future of Media ',
      lesson_type_order: 0,
      display_program: 'Media Mirror',
      display_topic: 'The Future of Media ',
    },
    {
      id: 2279,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 7,
      topic: 'The Future of Media ',
      lesson_type_order: 1,
      display_program: 'Media Mirror',
      display_topic: 'The Future of Media ',
    },
    {
      id: 2280,
      level: 'B1',
      month: 6,
      program: 'Media Mirror',
      lesson: 7,
      topic: 'The Future of Media ',
      lesson_type_order: 2,
      display_program: 'Media Mirror',
      display_topic: 'The Future of Media ',
    },
    {
      id: 2281,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 1,
      topic: 'Technology and Society',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Technology and Society',
    },
    {
      id: 2282,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 1,
      topic: 'Technology and Society',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Technology and Society',
    },
    {
      id: 2283,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 1,
      topic: 'Technology and Society',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Technology and Society',
    },
    {
      id: 2284,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 2,
      topic: 'Future Trends and Innovations',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Future Trends and Innovations',
    },
    {
      id: 2285,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 2,
      topic: 'Future Trends and Innovations',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Future Trends and Innovations',
    },
    {
      id: 2286,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 2,
      topic: 'Future Trends and Innovations',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Future Trends and Innovations',
    },
    {
      id: 2287,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 2,
      topic: 'Future Trends and Innovations',
      lesson_type_order: 3,
      display_program: 'Tech Horizons',
      display_topic: 'Future Trends and Innovations',
    },
    {
      id: 2288,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 3,
      topic: 'Online Safety and Digital Literacy',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Online Safety and Digital Literacy',
    },
    {
      id: 2289,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 3,
      topic: 'Online Safety and Digital Literacy',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Online Safety and Digital Literacy',
    },
    {
      id: 2290,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 3,
      topic: 'Online Safety and Digital Literacy',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Online Safety and Digital Literacy',
    },
    {
      id: 2291,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 4,
      topic: 'Social Media and Communication',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Social Media and Communication',
    },
    {
      id: 2292,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 4,
      topic: 'Social Media and Communication',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Social Media and Communication',
    },
    {
      id: 2293,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 4,
      topic: 'Social Media and Communication',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Social Media and Communication',
    },
    {
      id: 2294,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 4,
      topic: 'Social Media and Communication',
      lesson_type_order: 3,
      display_program: 'Tech Horizons',
      display_topic: 'Social Media and Communication',
    },
    {
      id: 2295,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 5,
      topic: 'Discussing the Future of Work and Automation',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Discussing the Future of Work and Automation',
    },
    {
      id: 2296,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 5,
      topic: 'Discussing the Future of Work and Automation',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Discussing the Future of Work and Automation',
    },
    {
      id: 2297,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 5,
      topic: 'Discussing the Future of Work and Automation',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Discussing the Future of Work and Automation',
    },
    {
      id: 2298,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 6,
      topic: 'Space and Scientific Advancements',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Space and Scientific Advancements',
    },
    {
      id: 2299,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 6,
      topic: 'Space and Scientific Advancements',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Space and Scientific Advancements',
    },
    {
      id: 2300,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 6,
      topic: 'Space and Scientific Advancements',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Space and Scientific Advancements',
    },
    {
      id: 2301,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 6,
      topic: 'Space and Scientific Advancements',
      lesson_type_order: 3,
      display_program: 'Tech Horizons',
      display_topic: 'Space and Scientific Advancements',
    },
    {
      id: 2302,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 7,
      topic: 'Ethical Considerations in Technology',
      lesson_type_order: 0,
      display_program: 'Tech Horizons',
      display_topic: 'Ethical Considerations in Technology',
    },
    {
      id: 2303,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 7,
      topic: 'Ethical Considerations in Technology',
      lesson_type_order: 1,
      display_program: 'Tech Horizons',
      display_topic: 'Ethical Considerations in Technology',
    },
    {
      id: 2304,
      level: 'B1',
      month: 7,
      program: 'Tech Horizons',
      lesson: 7,
      topic: 'Ethical Considerations in Technology',
      lesson_type_order: 2,
      display_program: 'Tech Horizons',
      display_topic: 'Ethical Considerations in Technology',
    },
    {
      id: 2305,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 1,
      topic: 'Setting Personal Goals and Objectives',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Setting Personal Goals and Objectives',
    },
    {
      id: 2306,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 1,
      topic: 'Setting Personal Goals and Objectives',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Setting Personal Goals and Objectives',
    },
    {
      id: 2307,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 1,
      topic: 'Setting Personal Goals and Objectives',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Setting Personal Goals and Objectives',
    },
    {
      id: 2308,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 2,
      topic: 'Time Management and Productivity',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Time Management and Productivity',
    },
    {
      id: 2309,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 2,
      topic: 'Time Management and Productivity',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Time Management and Productivity',
    },
    {
      id: 2310,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 2,
      topic: 'Time Management and Productivity',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Time Management and Productivity',
    },
    {
      id: 2311,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 2,
      topic: 'Time Management and Productivity',
      lesson_type_order: 3,
      display_program: 'Becoming Your Best',
      display_topic: 'Time Management and Productivity',
    },
    {
      id: 2312,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 3,
      topic: 'Learning Styles and Techniques',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Learning Styles and Techniques',
    },
    {
      id: 2313,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 3,
      topic: 'Learning Styles and Techniques',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Learning Styles and Techniques',
    },
    {
      id: 2314,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 3,
      topic: 'Learning Styles and Techniques',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Learning Styles and Techniques',
    },
    {
      id: 2315,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 4,
      topic: 'Talking About Motivation and Inspiration',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Talking About Motivation and Inspiration',
    },
    {
      id: 2316,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 4,
      topic: 'Talking About Motivation and Inspiration',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Talking About Motivation and Inspiration',
    },
    {
      id: 2317,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 4,
      topic: 'Talking About Motivation and Inspiration',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Talking About Motivation and Inspiration',
    },
    {
      id: 2318,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 4,
      topic: 'Talking About Motivation and Inspiration',
      lesson_type_order: 3,
      display_program: 'Becoming Your Best',
      display_topic: 'Talking About Motivation and Inspiration',
    },
    {
      id: 2319,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 5,
      topic: 'Discussing Self-Improvement and Life Skills',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Discussing Self-Improvement and Life Skills',
    },
    {
      id: 2320,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 5,
      topic: 'Discussing Self-Improvement and Life Skills',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Discussing Self-Improvement and Life Skills',
    },
    {
      id: 2321,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 5,
      topic: 'Discussing Self-Improvement and Life Skills',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Discussing Self-Improvement and Life Skills',
    },
    {
      id: 2322,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 6,
      topic: 'Exploring Mindfulness and Meditation',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Exploring Mindfulness and Meditation',
    },
    {
      id: 2323,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 6,
      topic: 'Exploring Mindfulness and Meditation',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Exploring Mindfulness and Meditation',
    },
    {
      id: 2324,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 6,
      topic: 'Exploring Mindfulness and Meditation',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Exploring Mindfulness and Meditation',
    },
    {
      id: 2325,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 6,
      topic: 'Exploring Mindfulness and Meditation',
      lesson_type_order: 3,
      display_program: 'Becoming Your Best',
      display_topic: 'Exploring Mindfulness and Meditation',
    },
    {
      id: 2326,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 7,
      topic: 'Discussing Resilience and Overcoming Challenges',
      lesson_type_order: 0,
      display_program: 'Becoming Your Best',
      display_topic: 'Discussing Resilience and Overcoming Challenges',
    },
    {
      id: 2327,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 7,
      topic: 'Discussing Resilience and Overcoming Challenges',
      lesson_type_order: 1,
      display_program: 'Becoming Your Best',
      display_topic: 'Discussing Resilience and Overcoming Challenges',
    },
    {
      id: 2328,
      level: 'B1',
      month: 8,
      program: 'Becoming Your Best',
      lesson: 7,
      topic: 'Discussing Resilience and Overcoming Challenges',
      lesson_type_order: 2,
      display_program: 'Becoming Your Best',
      display_topic: 'Discussing Resilience and Overcoming Challenges',
    },
    {
      id: 2329,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 1,
      topic: 'Discussing Holiday Traditions and Customs',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Holiday Traditions and Customs',
    },
    {
      id: 2330,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 1,
      topic: 'Discussing Holiday Traditions and Customs',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Holiday Traditions and Customs',
    },
    {
      id: 2331,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 1,
      topic: 'Discussing Holiday Traditions and Customs',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Holiday Traditions and Customs',
    },
    {
      id: 2332,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 2,
      topic: 'Planning and Celebrating Personal Milestones',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Planning and Celebrating Personal Milestones',
    },
    {
      id: 2333,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 2,
      topic: 'Planning and Celebrating Personal Milestones',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Planning and Celebrating Personal Milestones',
    },
    {
      id: 2334,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 2,
      topic: 'Planning and Celebrating Personal Milestones',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Planning and Celebrating Personal Milestones',
    },
    {
      id: 2335,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 2,
      topic: 'Planning and Celebrating Personal Milestones',
      lesson_type_order: 3,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Planning and Celebrating Personal Milestones',
    },
    {
      id: 2336,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 3,
      topic: 'Exploring Wedding Customs and Celebrations',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Exploring Wedding Customs and Celebrations',
    },
    {
      id: 2337,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 3,
      topic: 'Exploring Wedding Customs and Celebrations',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Exploring Wedding Customs and Celebrations',
    },
    {
      id: 2338,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 3,
      topic: 'Exploring Wedding Customs and Celebrations',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Exploring Wedding Customs and Celebrations',
    },
    {
      id: 2339,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 4,
      topic: 'Discussing Birthday Traditions and Parties',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Birthday Traditions and Parties',
    },
    {
      id: 2340,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 4,
      topic: 'Discussing Birthday Traditions and Parties',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Birthday Traditions and Parties',
    },
    {
      id: 2341,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 4,
      topic: 'Discussing Birthday Traditions and Parties',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Birthday Traditions and Parties',
    },
    {
      id: 2342,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 4,
      topic: 'Discussing Birthday Traditions and Parties',
      lesson_type_order: 3,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Birthday Traditions and Parties',
    },
    {
      id: 2343,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 5,
      topic: 'Exploring Cultural Festivals and National Holidays',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Exploring Cultural Festivals and National Holidays',
    },
    {
      id: 2344,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 5,
      topic: 'Exploring Cultural Festivals and National Holidays',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Exploring Cultural Festivals and National Holidays',
    },
    {
      id: 2345,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 5,
      topic: 'Exploring Cultural Festivals and National Holidays',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Exploring Cultural Festivals and National Holidays',
    },
    {
      id: 2346,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 6,
      topic: 'Discussing Gift-giving and Etiquette',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Gift-giving and Etiquette',
    },
    {
      id: 2347,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 6,
      topic: 'Discussing Gift-giving and Etiquette',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Gift-giving and Etiquette',
    },
    {
      id: 2348,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 6,
      topic: 'Discussing Gift-giving and Etiquette',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Gift-giving and Etiquette',
    },
    {
      id: 2349,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 6,
      topic: 'Discussing Gift-giving and Etiquette',
      lesson_type_order: 3,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Discussing Gift-giving and Etiquette',
    },
    {
      id: 2350,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 7,
      topic: 'Sharing Experiences of Celebrations ',
      lesson_type_order: 0,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Sharing Experiences of Celebrations ',
    },
    {
      id: 2351,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 7,
      topic: 'Sharing Experiences of Celebrations ',
      lesson_type_order: 1,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Sharing Experiences of Celebrations ',
    },
    {
      id: 2352,
      level: 'B1',
      month: 9,
      program: 'Celebrations and Traditions',
      lesson: 7,
      topic: 'Sharing Experiences of Celebrations ',
      lesson_type_order: 2,
      display_program: 'Celebrations and Traditions',
      display_topic: 'Sharing Experiences of Celebrations ',
    },
    {
      id: 2353,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 1,
      topic: 'Discussing Cooking and Food Preparation',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Discussing Cooking and Food Preparation',
    },
    {
      id: 2354,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 1,
      topic: 'Discussing Cooking and Food Preparation',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Discussing Cooking and Food Preparation',
    },
    {
      id: 2355,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 1,
      topic: 'Discussing Cooking and Food Preparation',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Discussing Cooking and Food Preparation',
    },
    {
      id: 2356,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 2,
      topic: 'Exploring International Cuisines and Dishes',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Exploring International Cuisines and Dishes',
    },
    {
      id: 2357,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 2,
      topic: 'Exploring International Cuisines and Dishes',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Exploring International Cuisines and Dishes',
    },
    {
      id: 2358,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 2,
      topic: 'Exploring International Cuisines and Dishes',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Exploring International Cuisines and Dishes',
    },
    {
      id: 2359,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 2,
      topic: 'Exploring International Cuisines and Dishes',
      lesson_type_order: 3,
      display_program: 'Culinary Journeys',
      display_topic: 'Exploring International Cuisines and Dishes',
    },
    {
      id: 2360,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 3,
      topic: 'Talking about Dining Etiquette and Customs',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Talking about Dining Etiquette and Customs',
    },
    {
      id: 2361,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 3,
      topic: 'Talking about Dining Etiquette and Customs',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Talking about Dining Etiquette and Customs',
    },
    {
      id: 2362,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 3,
      topic: 'Talking about Dining Etiquette and Customs',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Talking about Dining Etiquette and Customs',
    },
    {
      id: 2363,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 4,
      topic: 'Planning Meals and Grocery Shopping',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Planning Meals and Grocery Shopping',
    },
    {
      id: 2364,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 4,
      topic: 'Planning Meals and Grocery Shopping',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Planning Meals and Grocery Shopping',
    },
    {
      id: 2365,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 4,
      topic: 'Planning Meals and Grocery Shopping',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Planning Meals and Grocery Shopping',
    },
    {
      id: 2366,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 4,
      topic: 'Planning Meals and Grocery Shopping',
      lesson_type_order: 3,
      display_program: 'Culinary Journeys',
      display_topic: 'Planning Meals and Grocery Shopping',
    },
    {
      id: 2367,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 5,
      topic: 'Food Allergies and Dietary Preferences',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Allergies and Dietary Preferences',
    },
    {
      id: 2368,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 5,
      topic: 'Food Allergies and Dietary Preferences',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Allergies and Dietary Preferences',
    },
    {
      id: 2369,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 5,
      topic: 'Food Allergies and Dietary Preferences',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Allergies and Dietary Preferences',
    },
    {
      id: 2370,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 6,
      topic: 'Food Festivals ',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Festivals ',
    },
    {
      id: 2371,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 6,
      topic: 'Food Festivals ',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Festivals ',
    },
    {
      id: 2372,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 6,
      topic: 'Food Festivals ',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Festivals ',
    },
    {
      id: 2373,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 6,
      topic: 'Food Festivals ',
      lesson_type_order: 3,
      display_program: 'Culinary Journeys',
      display_topic: 'Food Festivals ',
    },
    {
      id: 2374,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 7,
      topic: 'Cooking Tips',
      lesson_type_order: 0,
      display_program: 'Culinary Journeys',
      display_topic: 'Cooking Tips',
    },
    {
      id: 2375,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 7,
      topic: 'Cooking Tips',
      lesson_type_order: 1,
      display_program: 'Culinary Journeys',
      display_topic: 'Cooking Tips',
    },
    {
      id: 2376,
      level: 'B1',
      month: 10,
      program: 'Culinary Journeys',
      lesson: 7,
      topic: 'Cooking Tips',
      lesson_type_order: 2,
      display_program: 'Culinary Journeys',
      display_topic: 'Cooking Tips',
    },
    {
      id: 2377,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 1,
      topic: 'Articulating opinions',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Articulating opinions',
    },
    {
      id: 2378,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 1,
      topic: 'Articulating opinions',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Articulating opinions',
    },
    {
      id: 2379,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 1,
      topic: 'Articulating opinions',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Articulating opinions',
    },
    {
      id: 2602,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 3,
      topic: 'Cultural Differences',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Cultural Differences',
    },
    {
      id: 2380,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 2,
      topic: 'Persuasive Techniques',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Persuasive Techniques',
    },
    {
      id: 2381,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 2,
      topic: 'Persuasive Techniques',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Persuasive Techniques',
    },
    {
      id: 2382,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 2,
      topic: 'Persuasive Techniques',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Persuasive Techniques',
    },
    {
      id: 2383,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 2,
      topic: 'Persuasive Techniques',
      lesson_type_order: 3,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Persuasive Techniques',
    },
    {
      id: 2384,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 3,
      topic: 'Negotiation Strategies',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Negotiation Strategies',
    },
    {
      id: 2385,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 3,
      topic: 'Negotiation Strategies',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Negotiation Strategies',
    },
    {
      id: 2386,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 3,
      topic: 'Negotiation Strategies',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Negotiation Strategies',
    },
    {
      id: 2387,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 4,
      topic: 'Famous People',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Famous People',
    },
    {
      id: 2388,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 4,
      topic: 'Famous People',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Famous People',
    },
    {
      id: 2389,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 4,
      topic: 'Famous People',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Famous People',
    },
    {
      id: 2390,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 4,
      topic: 'Famous People',
      lesson_type_order: 3,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Famous People',
    },
    {
      id: 2391,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 5,
      topic: 'Non-verbal Communication ',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Non-verbal Communication ',
    },
    {
      id: 2392,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 5,
      topic: 'Non-verbal Communication ',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Non-verbal Communication ',
    },
    {
      id: 2393,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 5,
      topic: 'Non-verbal Communication ',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Non-verbal Communication ',
    },
    {
      id: 2394,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 6,
      topic: 'Effective Public Speaking  ',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Effective Public Speaking  ',
    },
    {
      id: 2395,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 6,
      topic: 'Effective Public Speaking  ',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Effective Public Speaking  ',
    },
    {
      id: 2396,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 6,
      topic: 'Effective Public Speaking  ',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Effective Public Speaking  ',
    },
    {
      id: 2397,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 6,
      topic: 'Effective Public Speaking  ',
      lesson_type_order: 3,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Effective Public Speaking  ',
    },
    {
      id: 2398,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 7,
      topic: 'Humor in Communication',
      lesson_type_order: 0,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Humor in Communication',
    },
    {
      id: 2399,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 7,
      topic: 'Humor in Communication',
      lesson_type_order: 1,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Humor in Communication',
    },
    {
      id: 2400,
      level: 'B2',
      month: 1,
      program: 'Perfecting the Art of Expression',
      lesson: 7,
      topic: 'Humor in Communication',
      lesson_type_order: 2,
      display_program: 'Perfecting the Art of Expression',
      display_topic: 'Humor in Communication',
    },
    {
      id: 2401,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 1,
      topic: 'Environmental policies',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'Environmental policies',
    },
    {
      id: 2402,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 1,
      topic: 'Environmental policies',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'Environmental policies',
    },
    {
      id: 2403,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 1,
      topic: 'Environmental policies',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'Environmental policies',
    },
    {
      id: 2404,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 2,
      topic: 'Global Health issues ',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'Global Health issues ',
    },
    {
      id: 2405,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 2,
      topic: 'Global Health issues ',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'Global Health issues ',
    },
    {
      id: 2406,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 2,
      topic: 'Global Health issues ',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'Global Health issues ',
    },
    {
      id: 2407,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 2,
      topic: 'Global Health issues ',
      lesson_type_order: 3,
      display_program: 'Green Dialogues',
      display_topic: 'Global Health issues ',
    },
    {
      id: 2408,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 3,
      topic: 'International Relations and Diplomacy',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'International Relations and Diplomacy',
    },
    {
      id: 2409,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 3,
      topic: 'International Relations and Diplomacy',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'International Relations and Diplomacy',
    },
    {
      id: 2410,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 3,
      topic: 'International Relations and Diplomacy',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'International Relations and Diplomacy',
    },
    {
      id: 2411,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 4,
      topic: 'Economic Trends and Global Markets',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'Economic Trends and Global Markets',
    },
    {
      id: 2412,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 4,
      topic: 'Economic Trends and Global Markets',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'Economic Trends and Global Markets',
    },
    {
      id: 2413,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 4,
      topic: 'Economic Trends and Global Markets',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'Economic Trends and Global Markets',
    },
    {
      id: 2414,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 4,
      topic: 'Economic Trends and Global Markets',
      lesson_type_order: 3,
      display_program: 'Green Dialogues',
      display_topic: 'Economic Trends and Global Markets',
    },
    {
      id: 2415,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 5,
      topic: 'Technological Advancements ',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'Technological Advancements ',
    },
    {
      id: 2416,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 5,
      topic: 'Technological Advancements ',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'Technological Advancements ',
    },
    {
      id: 2417,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 5,
      topic: 'Technological Advancements ',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'Technological Advancements ',
    },
    {
      id: 2418,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 6,
      topic: 'Human Rights and Social Justice Movements',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'Human Rights and Social Justice Movements',
    },
    {
      id: 2419,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 6,
      topic: 'Human Rights and Social Justice Movements',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'Human Rights and Social Justice Movements',
    },
    {
      id: 2420,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 6,
      topic: 'Human Rights and Social Justice Movements',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'Human Rights and Social Justice Movements',
    },
    {
      id: 2421,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 6,
      topic: 'Human Rights and Social Justice Movements',
      lesson_type_order: 3,
      display_program: 'Green Dialogues',
      display_topic: 'Human Rights and Social Justice Movements',
    },
    {
      id: 2422,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 7,
      topic: 'How Eco-guilty are You',
      lesson_type_order: 0,
      display_program: 'Green Dialogues',
      display_topic: 'How Eco-guilty are You',
    },
    {
      id: 2423,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 7,
      topic: 'How Eco-guilty are You',
      lesson_type_order: 1,
      display_program: 'Green Dialogues',
      display_topic: 'How Eco-guilty are You',
    },
    {
      id: 2424,
      level: 'B2',
      month: 2,
      program: 'Green Dialogues',
      lesson: 7,
      topic: 'How Eco-guilty are You',
      lesson_type_order: 2,
      display_program: 'Green Dialogues',
      display_topic: 'How Eco-guilty are You',
    },
    {
      id: 2425,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 1,
      topic: 'Social Norms and Cultural Values',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Social Norms and Cultural Values',
    },
    {
      id: 2426,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 1,
      topic: 'Social Norms and Cultural Values',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Social Norms and Cultural Values',
    },
    {
      id: 2427,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 1,
      topic: 'Social Norms and Cultural Values',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Social Norms and Cultural Values',
    },
    {
      id: 2428,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 2,
      topic: 'Types of Crimes',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Types of Crimes',
    },
    {
      id: 2429,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 2,
      topic: 'Types of Crimes',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Types of Crimes',
    },
    {
      id: 2430,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 2,
      topic: 'Types of Crimes',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Types of Crimes',
    },
    {
      id: 2431,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 2,
      topic: 'Types of Crimes',
      lesson_type_order: 3,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Types of Crimes',
    },
    {
      id: 2432,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 3,
      topic: 'Shaping Public Opinion',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Shaping Public Opinion',
    },
    {
      id: 2433,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 3,
      topic: 'Shaping Public Opinion',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Shaping Public Opinion',
    },
    {
      id: 2434,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 3,
      topic: 'Shaping Public Opinion',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Shaping Public Opinion',
    },
    {
      id: 2435,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 4,
      topic: 'Privacy, Data Security, and the Digital Age',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Privacy, Data Security, and the Digital Age',
    },
    {
      id: 2436,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 4,
      topic: 'Privacy, Data Security, and the Digital Age',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Privacy, Data Security, and the Digital Age',
    },
    {
      id: 2437,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 4,
      topic: 'Privacy, Data Security, and the Digital Age',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Privacy, Data Security, and the Digital Age',
    },
    {
      id: 2438,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 4,
      topic: 'Privacy, Data Security, and the Digital Age',
      lesson_type_order: 3,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Privacy, Data Security, and the Digital Age',
    },
    {
      id: 2439,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 5,
      topic: 'Legal Systems and Justice',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Legal Systems and Justice',
    },
    {
      id: 2440,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 5,
      topic: 'Legal Systems and Justice',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Legal Systems and Justice',
    },
    {
      id: 2441,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 5,
      topic: 'Legal Systems and Justice',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Legal Systems and Justice',
    },
    {
      id: 2442,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 6,
      topic: 'Do Punishments Usually Fit the Crime?',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Do Punishments Usually Fit the Crime?',
    },
    {
      id: 2443,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 6,
      topic: 'Do Punishments Usually Fit the Crime?',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Do Punishments Usually Fit the Crime?',
    },
    {
      id: 2444,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 6,
      topic: 'Do Punishments Usually Fit the Crime?',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Do Punishments Usually Fit the Crime?',
    },
    {
      id: 2445,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 6,
      topic: 'Do Punishments Usually Fit the Crime?',
      lesson_type_order: 3,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Do Punishments Usually Fit the Crime?',
    },
    {
      id: 2446,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 7,
      topic: 'Mysterious Crimes',
      lesson_type_order: 0,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Mysterious Crimes',
    },
    {
      id: 2447,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 7,
      topic: 'Mysterious Crimes',
      lesson_type_order: 1,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Mysterious Crimes',
    },
    {
      id: 2448,
      level: 'B2',
      month: 3,
      program: 'Balancing Crime, Punishment, and Social Values',
      lesson: 7,
      topic: 'Mysterious Crimes',
      lesson_type_order: 2,
      display_program: 'Balancing Crime, Punishment, and Social Values',
      display_topic: 'Mysterious Crimes',
    },
    {
      id: 2449,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 1,
      topic: 'The Entrepreneurial Mindset ',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'The Entrepreneurial Mindset ',
    },
    {
      id: 2450,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 1,
      topic: 'The Entrepreneurial Mindset ',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'The Entrepreneurial Mindset ',
    },
    {
      id: 2451,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 1,
      topic: 'The Entrepreneurial Mindset ',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'The Entrepreneurial Mindset ',
    },
    {
      id: 2452,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 2,
      topic: 'Identifying Business Opportunities',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Identifying Business Opportunities',
    },
    {
      id: 2453,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 2,
      topic: 'Identifying Business Opportunities',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Identifying Business Opportunities',
    },
    {
      id: 2603,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 4,
      topic: 'Career Paths',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Career Paths',
    },
    {
      id: 2454,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 2,
      topic: 'Identifying Business Opportunities',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Identifying Business Opportunities',
    },
    {
      id: 2455,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 2,
      topic: 'Identifying Business Opportunities',
      lesson_type_order: 3,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Identifying Business Opportunities',
    },
    {
      id: 2456,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 3,
      topic: 'What Drives Innovation',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'What Drives Innovation',
    },
    {
      id: 2457,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 3,
      topic: 'What Drives Innovation',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'What Drives Innovation',
    },
    {
      id: 2458,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 3,
      topic: 'What Drives Innovation',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'What Drives Innovation',
    },
    {
      id: 2459,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 4,
      topic: 'Funding and Finance for Startups',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Funding and Finance for Startups',
    },
    {
      id: 2460,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 4,
      topic: 'Funding and Finance for Startups',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Funding and Finance for Startups',
    },
    {
      id: 2461,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 4,
      topic: 'Funding and Finance for Startups',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Funding and Finance for Startups',
    },
    {
      id: 2462,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 4,
      topic: 'Funding and Finance for Startups',
      lesson_type_order: 3,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Funding and Finance for Startups',
    },
    {
      id: 2463,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 5,
      topic: 'The Role of Technology in Business',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'The Role of Technology in Business',
    },
    {
      id: 2464,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 5,
      topic: 'The Role of Technology in Business',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'The Role of Technology in Business',
    },
    {
      id: 2465,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 5,
      topic: 'The Role of Technology in Business',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'The Role of Technology in Business',
    },
    {
      id: 2466,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 6,
      topic: 'Social Entrepreneurship and Impact',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Social Entrepreneurship and Impact',
    },
    {
      id: 2467,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 6,
      topic: 'Social Entrepreneurship and Impact',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Social Entrepreneurship and Impact',
    },
    {
      id: 2468,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 6,
      topic: 'Social Entrepreneurship and Impact',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Social Entrepreneurship and Impact',
    },
    {
      id: 2469,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 6,
      topic: 'Social Entrepreneurship and Impact',
      lesson_type_order: 3,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Social Entrepreneurship and Impact',
    },
    {
      id: 2470,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 7,
      topic: 'Leadership and Team Building',
      lesson_type_order: 0,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Leadership and Team Building',
    },
    {
      id: 2471,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 7,
      topic: 'Leadership and Team Building',
      lesson_type_order: 1,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Leadership and Team Building',
    },
    {
      id: 2472,
      level: 'B2',
      month: 4,
      program: 'Entrepreneurship and Innovation',
      lesson: 7,
      topic: 'Leadership and Team Building',
      lesson_type_order: 2,
      display_program: 'Entrepreneurship and Innovation',
      display_topic: 'Leadership and Team Building',
    },
    {
      id: 2473,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 1,
      topic: 'City Life',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'City Life',
    },
    {
      id: 2474,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 1,
      topic: 'City Life',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'City Life',
    },
    {
      id: 2475,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 1,
      topic: 'City Life',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'City Life',
    },
    {
      id: 2476,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 2,
      topic: 'Cities of the Future',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'Cities of the Future',
    },
    {
      id: 2477,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 2,
      topic: 'Cities of the Future',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'Cities of the Future',
    },
    {
      id: 2478,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 2,
      topic: 'Cities of the Future',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'Cities of the Future',
    },
    {
      id: 2479,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 2,
      topic: 'Cities of the Future',
      lesson_type_order: 3,
      display_program: 'Urban Odyssey',
      display_topic: 'Cities of the Future',
    },
    {
      id: 2480,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 3,
      topic: 'Adventure Activities',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'Adventure Activities',
    },
    {
      id: 2481,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 3,
      topic: 'Adventure Activities',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'Adventure Activities',
    },
    {
      id: 2482,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 3,
      topic: 'Adventure Activities',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'Adventure Activities',
    },
    {
      id: 2483,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 4,
      topic: 'Risky Trips',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'Risky Trips',
    },
    {
      id: 2484,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 4,
      topic: 'Risky Trips',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'Risky Trips',
    },
    {
      id: 2485,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 4,
      topic: 'Risky Trips',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'Risky Trips',
    },
    {
      id: 2486,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 4,
      topic: 'Risky Trips',
      lesson_type_order: 3,
      display_program: 'Urban Odyssey',
      display_topic: 'Risky Trips',
    },
    {
      id: 2487,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 5,
      topic: 'Are you a Risk Taker?',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'Are you a Risk Taker?',
    },
    {
      id: 2488,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 5,
      topic: 'Are you a Risk Taker?',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'Are you a Risk Taker?',
    },
    {
      id: 2489,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 5,
      topic: 'Are you a Risk Taker?',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'Are you a Risk Taker?',
    },
    {
      id: 2490,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 6,
      topic: 'Survivors’s Club',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'Survivors’s Club',
    },
    {
      id: 2491,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 6,
      topic: 'Survivors’s Club',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'Survivors’s Club',
    },
    {
      id: 2492,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 6,
      topic: 'Survivors’s Club',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'Survivors’s Club',
    },
    {
      id: 2493,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 6,
      topic: 'Survivors’s Club',
      lesson_type_order: 3,
      display_program: 'Urban Odyssey',
      display_topic: 'Survivors’s Club',
    },
    {
      id: 2494,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 7,
      topic: 'Surviving Strategies ',
      lesson_type_order: 0,
      display_program: 'Urban Odyssey',
      display_topic: 'Surviving Strategies ',
    },
    {
      id: 2495,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 7,
      topic: 'Surviving Strategies ',
      lesson_type_order: 1,
      display_program: 'Urban Odyssey',
      display_topic: 'Surviving Strategies ',
    },
    {
      id: 2496,
      level: 'B2',
      month: 5,
      program: 'Urban Odyssey',
      lesson: 7,
      topic: 'Surviving Strategies ',
      lesson_type_order: 2,
      display_program: 'Urban Odyssey',
      display_topic: 'Surviving Strategies ',
    },
    {
      id: 2497,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 1,
      topic: 'Psychology and Understanding Human Behavior',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Psychology and Understanding Human Behavior',
    },
    {
      id: 2498,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 1,
      topic: 'Psychology and Understanding Human Behavior',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Psychology and Understanding Human Behavior',
    },
    {
      id: 2499,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 1,
      topic: 'Psychology and Understanding Human Behavior',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Psychology and Understanding Human Behavior',
    },
    {
      id: 2500,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 2,
      topic: 'Mental Health Awareness ',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Mental Health Awareness ',
    },
    {
      id: 2501,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 2,
      topic: 'Mental Health Awareness ',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Mental Health Awareness ',
    },
    {
      id: 2502,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 2,
      topic: 'Mental Health Awareness ',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Mental Health Awareness ',
    },
    {
      id: 2503,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 2,
      topic: 'Mental Health Awareness ',
      lesson_type_order: 3,
      display_program: 'Mind Matters',
      display_topic: 'Mental Health Awareness ',
    },
    {
      id: 2504,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 3,
      topic: 'Motivation and Inspiration',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Motivation and Inspiration',
    },
    {
      id: 2505,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 3,
      topic: 'Motivation and Inspiration',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Motivation and Inspiration',
    },
    {
      id: 2506,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 3,
      topic: 'Motivation and Inspiration',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Motivation and Inspiration',
    },
    {
      id: 2507,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 4,
      topic: 'Personality Types',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Personality Types',
    },
    {
      id: 2508,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 4,
      topic: 'Personality Types',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Personality Types',
    },
    {
      id: 2509,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 4,
      topic: 'Personality Types',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Personality Types',
    },
    {
      id: 2510,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 4,
      topic: 'Personality Types',
      lesson_type_order: 3,
      display_program: 'Mind Matters',
      display_topic: 'Personality Types',
    },
    {
      id: 2511,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 5,
      topic: 'Spotting Liars',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Spotting Liars',
    },
    {
      id: 2512,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 5,
      topic: 'Spotting Liars',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Spotting Liars',
    },
    {
      id: 2513,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 5,
      topic: 'Spotting Liars',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Spotting Liars',
    },
    {
      id: 2514,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 6,
      topic: 'Meaning of Gestures',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Meaning of Gestures',
    },
    {
      id: 2515,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 6,
      topic: 'Meaning of Gestures',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Meaning of Gestures',
    },
    {
      id: 2516,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 6,
      topic: 'Meaning of Gestures',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Meaning of Gestures',
    },
    {
      id: 2517,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 6,
      topic: 'Meaning of Gestures',
      lesson_type_order: 3,
      display_program: 'Mind Matters',
      display_topic: 'Meaning of Gestures',
    },
    {
      id: 2518,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 7,
      topic: 'Fake News Reaction',
      lesson_type_order: 0,
      display_program: 'Mind Matters',
      display_topic: 'Fake News Reaction',
    },
    {
      id: 2519,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 7,
      topic: 'Fake News Reaction',
      lesson_type_order: 1,
      display_program: 'Mind Matters',
      display_topic: 'Fake News Reaction',
    },
    {
      id: 2520,
      level: 'B2',
      month: 6,
      program: 'Mind Matters',
      lesson: 7,
      topic: 'Fake News Reaction',
      lesson_type_order: 2,
      display_program: 'Mind Matters',
      display_topic: 'Fake News Reaction',
    },
    {
      id: 2521,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 1,
      topic: 'Medical Myths?',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Medical Myths?',
    },
    {
      id: 2522,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 1,
      topic: 'Medical Myths?',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Medical Myths?',
    },
    {
      id: 2523,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 1,
      topic: 'Medical Myths?',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Medical Myths?',
    },
    {
      id: 2524,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 2,
      topic: 'Confessions of Cyberchondriac',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Confessions of Cyberchondriac',
    },
    {
      id: 2525,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 2,
      topic: 'Confessions of Cyberchondriac',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Confessions of Cyberchondriac',
    },
    {
      id: 2526,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 2,
      topic: 'Confessions of Cyberchondriac',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Confessions of Cyberchondriac',
    },
    {
      id: 2527,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 2,
      topic: 'Confessions of Cyberchondriac',
      lesson_type_order: 3,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Confessions of Cyberchondriac',
    },
    {
      id: 2528,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 3,
      topic: 'Act your Age',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Act your Age',
    },
    {
      id: 2529,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 3,
      topic: 'Act your Age',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Act your Age',
    },
    {
      id: 2530,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 3,
      topic: 'Act your Age',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Act your Age',
    },
    {
      id: 2531,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 4,
      topic: 'Can the Same Clothes Act for all Ages?',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Can the Same Clothes Act for all Ages?',
    },
    {
      id: 2532,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 4,
      topic: 'Can the Same Clothes Act for all Ages?',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Can the Same Clothes Act for all Ages?',
    },
    {
      id: 2533,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 4,
      topic: 'Can the Same Clothes Act for all Ages?',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Can the Same Clothes Act for all Ages?',
    },
    {
      id: 2534,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 4,
      topic: 'Can the Same Clothes Act for all Ages?',
      lesson_type_order: 3,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Can the Same Clothes Act for all Ages?',
    },
    {
      id: 2535,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 5,
      topic: 'Alternative Medicine and Holistic Health Approaches',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Alternative Medicine and Holistic Health Approaches',
    },
    {
      id: 2536,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 5,
      topic: 'Alternative Medicine and Holistic Health Approaches',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Alternative Medicine and Holistic Health Approaches',
    },
    {
      id: 2537,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 5,
      topic: 'Alternative Medicine and Holistic Health Approaches',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Alternative Medicine and Holistic Health Approaches',
    },
    {
      id: 2538,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 6,
      topic: 'Music as a Treatment',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Music as a Treatment',
    },
    {
      id: 2539,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 6,
      topic: 'Music as a Treatment',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Music as a Treatment',
    },
    {
      id: 2540,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 6,
      topic: 'Music as a Treatment',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Music as a Treatment',
    },
    {
      id: 2541,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 6,
      topic: 'Music as a Treatment',
      lesson_type_order: 3,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Music as a Treatment',
    },
    {
      id: 2542,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 7,
      topic: 'Night Night Problems ',
      lesson_type_order: 0,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Night Night Problems ',
    },
    {
      id: 2543,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 7,
      topic: 'Night Night Problems ',
      lesson_type_order: 1,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Night Night Problems ',
    },
    {
      id: 2544,
      level: 'B2',
      month: 7,
      program: 'Navigating Health Across the Ages',
      lesson: 7,
      topic: 'Night Night Problems ',
      lesson_type_order: 2,
      display_program: 'Navigating Health Across the Ages',
      display_topic: 'Night Night Problems ',
    },
    {
      id: 2545,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 1,
      topic: 'The Price of Success',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'The Price of Success',
    },
    {
      id: 2546,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 1,
      topic: 'The Price of Success',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'The Price of Success',
    },
    {
      id: 2547,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 1,
      topic: 'The Price of Success',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'The Price of Success',
    },
    {
      id: 2548,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 2,
      topic: 'Getting Rich Quick',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Getting Rich Quick',
    },
    {
      id: 2549,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 2,
      topic: 'Getting Rich Quick',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Getting Rich Quick',
    },
    {
      id: 2550,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 2,
      topic: 'Getting Rich Quick',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Getting Rich Quick',
    },
    {
      id: 2551,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 2,
      topic: 'Getting Rich Quick',
      lesson_type_order: 3,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Getting Rich Quick',
    },
    {
      id: 2552,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 3,
      topic: 'Happiness is True Wealth',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Happiness is True Wealth',
    },
    {
      id: 2553,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 3,
      topic: 'Happiness is True Wealth',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Happiness is True Wealth',
    },
    {
      id: 2554,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 3,
      topic: 'Happiness is True Wealth',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Happiness is True Wealth',
    },
    {
      id: 2555,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 4,
      topic: 'Way to Wealth',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Way to Wealth',
    },
    {
      id: 2556,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 4,
      topic: 'Way to Wealth',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Way to Wealth',
    },
    {
      id: 2557,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 4,
      topic: 'Way to Wealth',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Way to Wealth',
    },
    {
      id: 2558,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 4,
      topic: 'Way to Wealth',
      lesson_type_order: 3,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Way to Wealth',
    },
    {
      id: 2559,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 5,
      topic: 'Building Strong Arguments',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Building Strong Arguments',
    },
    {
      id: 2560,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 5,
      topic: 'Building Strong Arguments',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Building Strong Arguments',
    },
    {
      id: 2561,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 5,
      topic: 'Building Strong Arguments',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Building Strong Arguments',
    },
    {
      id: 2562,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 6,
      topic: 'My Brilliant Pitch ',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'My Brilliant Pitch ',
    },
    {
      id: 2563,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 6,
      topic: 'My Brilliant Pitch ',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'My Brilliant Pitch ',
    },
    {
      id: 2564,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 6,
      topic: 'My Brilliant Pitch ',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'My Brilliant Pitch ',
    },
    {
      id: 2565,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 6,
      topic: 'My Brilliant Pitch ',
      lesson_type_order: 3,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'My Brilliant Pitch ',
    },
    {
      id: 2566,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 7,
      topic: 'Debate Techniques',
      lesson_type_order: 0,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Debate Techniques',
    },
    {
      id: 2567,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 7,
      topic: 'Debate Techniques',
      lesson_type_order: 1,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Debate Techniques',
    },
    {
      id: 2568,
      level: 'B2',
      month: 8,
      program: 'Wealth Building and Argument Tactics',
      lesson: 7,
      topic: 'Debate Techniques',
      lesson_type_order: 2,
      display_program: 'Wealth Building and Argument Tactics',
      display_topic: 'Debate Techniques',
    },
    {
      id: 2569,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 1,
      topic: 'Role of Arts',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'Role of Arts',
    },
    {
      id: 2570,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 1,
      topic: 'Role of Arts',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'Role of Arts',
    },
    {
      id: 2571,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 1,
      topic: 'Role of Arts',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'Role of Arts',
    },
    {
      id: 2572,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 2,
      topic: 'Exploring Visual Arts and art History',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'Exploring Visual Arts and art History',
    },
    {
      id: 2573,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 2,
      topic: 'Exploring Visual Arts and art History',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'Exploring Visual Arts and art History',
    },
    {
      id: 2574,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 2,
      topic: 'Exploring Visual Arts and art History',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'Exploring Visual Arts and art History',
    },
    {
      id: 2575,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 2,
      topic: 'Exploring Visual Arts and art History',
      lesson_type_order: 3,
      display_program: 'Creative Wisdom',
      display_topic: 'Exploring Visual Arts and art History',
    },
    {
      id: 2576,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 3,
      topic: 'The role of Literature and Film in Culture',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'The role of Literature and Film in Culture',
    },
    {
      id: 2577,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 3,
      topic: 'The role of Literature and Film in Culture',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'The role of Literature and Film in Culture',
    },
    {
      id: 2578,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 3,
      topic: 'The role of Literature and Film in Culture',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'The role of Literature and Film in Culture',
    },
    {
      id: 2579,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 4,
      topic: 'Adaptations from Literature to Screen',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'Adaptations from Literature to Screen',
    },
    {
      id: 2580,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 4,
      topic: 'Adaptations from Literature to Screen',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'Adaptations from Literature to Screen',
    },
    {
      id: 2581,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 4,
      topic: 'Adaptations from Literature to Screen',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'Adaptations from Literature to Screen',
    },
    {
      id: 2582,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 4,
      topic: 'Adaptations from Literature to Screen',
      lesson_type_order: 3,
      display_program: 'Creative Wisdom',
      display_topic: 'Adaptations from Literature to Screen',
    },
    {
      id: 2583,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 5,
      topic: 'The future of storytelling in the digital age',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'The future of storytelling in the digital age',
    },
    {
      id: 2584,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 5,
      topic: 'The future of storytelling in the digital age',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'The future of storytelling in the digital age',
    },
    {
      id: 2585,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 5,
      topic: 'The future of storytelling in the digital age',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'The future of storytelling in the digital age',
    },
    {
      id: 2586,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 6,
      topic: 'The Role of Technology in Education',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'The Role of Technology in Education',
    },
    {
      id: 2587,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 6,
      topic: 'The Role of Technology in Education',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'The Role of Technology in Education',
    },
    {
      id: 2588,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 6,
      topic: 'The Role of Technology in Education',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'The Role of Technology in Education',
    },
    {
      id: 2589,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 6,
      topic: 'The Role of Technology in Education',
      lesson_type_order: 3,
      display_program: 'Creative Wisdom',
      display_topic: 'The Role of Technology in Education',
    },
    {
      id: 2590,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 7,
      topic: 'The Future of Education',
      lesson_type_order: 0,
      display_program: 'Creative Wisdom',
      display_topic: 'The Future of Education',
    },
    {
      id: 2591,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 7,
      topic: 'The Future of Education',
      lesson_type_order: 1,
      display_program: 'Creative Wisdom',
      display_topic: 'The Future of Education',
    },
    {
      id: 2592,
      level: 'B2',
      month: 9,
      program: 'Creative Wisdom',
      lesson: 7,
      topic: 'The Future of Education',
      lesson_type_order: 2,
      display_program: 'Creative Wisdom',
      display_topic: 'The Future of Education',
    },
    {
      id: 2593,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 1,
      topic: 'Intercultural Communication',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Intercultural Communication',
    },
    {
      id: 2594,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 1,
      topic: 'Intercultural Communication',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Intercultural Communication',
    },
    {
      id: 2595,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 1,
      topic: 'Intercultural Communication',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Intercultural Communication',
    },
    {
      id: 2596,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 2,
      topic: 'Communication with Foreign Collegues',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Communication with Foreign Collegues',
    },
    {
      id: 2597,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 2,
      topic: 'Communication with Foreign Collegues',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Communication with Foreign Collegues',
    },
    {
      id: 2598,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 2,
      topic: 'Communication with Foreign Collegues',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Communication with Foreign Collegues',
    },
    {
      id: 2599,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 2,
      topic: 'Communication with Foreign Collegues',
      lesson_type_order: 3,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Communication with Foreign Collegues',
    },
    {
      id: 2600,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 3,
      topic: 'Cultural Differences',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Cultural Differences',
    },
    {
      id: 2601,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 3,
      topic: 'Cultural Differences',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Cultural Differences',
    },
    {
      id: 2604,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 4,
      topic: 'Career Paths',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Career Paths',
    },
    {
      id: 2605,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 4,
      topic: 'Career Paths',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Career Paths',
    },
    {
      id: 2606,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 4,
      topic: 'Career Paths',
      lesson_type_order: 3,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Career Paths',
    },
    {
      id: 2607,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 5,
      topic: 'New Career Opportunities',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'New Career Opportunities',
    },
    {
      id: 2608,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 5,
      topic: 'New Career Opportunities',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'New Career Opportunities',
    },
    {
      id: 2609,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 5,
      topic: 'New Career Opportunities',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'New Career Opportunities',
    },
    {
      id: 2610,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 6,
      topic: 'Jobs and Artificially Intelligent Robots',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Jobs and Artificially Intelligent Robots',
    },
    {
      id: 2611,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 6,
      topic: 'Jobs and Artificially Intelligent Robots',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Jobs and Artificially Intelligent Robots',
    },
    {
      id: 2612,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 6,
      topic: 'Jobs and Artificially Intelligent Robots',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Jobs and Artificially Intelligent Robots',
    },
    {
      id: 2613,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 6,
      topic: 'Jobs and Artificially Intelligent Robots',
      lesson_type_order: 3,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Jobs and Artificially Intelligent Robots',
    },
    {
      id: 2614,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 7,
      topic: 'Office Work VS. Remote Work',
      lesson_type_order: 0,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Office Work VS. Remote Work',
    },
    {
      id: 2615,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 7,
      topic: 'Office Work VS. Remote Work',
      lesson_type_order: 1,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Office Work VS. Remote Work',
    },
    {
      id: 2616,
      level: 'B2',
      month: 10,
      program: 'Career Crossroads: Professional Paths Through Diverse Cultures',
      lesson: 7,
      topic: 'Office Work VS. Remote Work',
      lesson_type_order: 2,
      display_program:
        'Career Crossroads: Professional Paths Through Diverse Cultures',
      display_topic: 'Office Work VS. Remote Work',
    },
  ],
  { total: 209, new: 205, active: 1, completed: 3, vocab: [] },
  { tag: 'active' },
]

import React, { useEffect, useState } from 'react'
import { classed } from '@tw-classed/react'
import { cn } from '../../../shared/lib/utils'

interface TaskSliderProps {
  id: number
  children: React.ReactNode
  isLast: boolean
}

const Card = classed.div(
  'col-start-1 row-start-1 overflow-hidden rounded-18 bg-gray-light p-16 shadow-chat-content',
)

const duration = 300

interface State {
  currentContent: React.ReactNode
  currentId: number
  previousContent: React.ReactNode | null
  previousId: number
  transition: boolean
}

export const TaskSlider = (props: TaskSliderProps) => {
  const [state, setState] = useState<State>({
    currentContent: props.children,
    currentId: props.id,
    previousContent: null,
    previousId: -1,
    transition: false,
  })

  useEffect(() => {
    if (props.id !== state.currentId) {
      setState((prevState) => ({
        previousContent: prevState.currentContent,
        previousId: prevState.currentId,
        currentContent: props.children,
        currentId: props.id,
        transition: true,
      }))
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          transition: false,
        }))
      }, duration)
    } else {
      setState((prevState) => ({
        ...prevState,
        currentContent: props.children,
      }))
    }
  }, [props.children, props.id, state.currentId])

  const animation = state.transition || props.isLast
  return (
    <div className="grid">
      <Card>
        {state.transition ? state.previousContent : state.currentContent}
      </Card>
      <Card
        style={{
          transitionDuration: animation ? `${duration}ms` : '0ms',
        }}
        className={cn(
          animation
            ? 'translate-x-0 transition-transform ease-out-circ'
            : 'translate-x-[calc(100%+8px)]',
        )}
      >
        {state.currentContent}
      </Card>
      {!props.isLast && <Card className="translate-x-[calc(100%+8px)]"></Card>}
    </div>
  )
}

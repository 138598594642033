import { FillTheGapsStore } from './fillTheGapsStore.ts'
import { useLazyRef } from '../../../shared/lib/hooks.ts'
import { useSnapshot } from 'valtio/react'
import {
  ExerciseButton,
  ExerciseContainer,
  ExerciseTitle,
} from '../exerciseComponents.tsx'
import { classed } from '@tw-classed/react'
import { TaskSlider } from '../taskSlider/TaskSlider.tsx'
import { useAppModule } from '../../appContext.ts'
import { FormattedMessage } from 'react-intl'
import { ICurrentAnswer, Payload } from '../../../shared/lib/utils.ts'

type FillWordText = readonly (string | { word: string })[]
export interface FillWordTextData {
  text: FillWordText
  options: readonly string[]
  incorrect: string
  title: string
}
interface FillWordsProps {
  texts: readonly FillWordTextData[]
  completed: boolean
  onComplete: (
    result: string,
    correctAnswersCount: number,
    payload: Payload[],
  ) => void
  onTaskComplete?: (currentTaskIndex: number) => void
}
export const FillTheGaps = (props: FillWordsProps) => {
  const answerStore = useAppModule().answerStatusStore
  const store = useLazyRef(
    () =>
      new FillTheGapsStore(
        props.texts,
        answerStore,
        props.onComplete,
        props.onTaskComplete,
      ),
  ).current
  const state = useSnapshot(store.state)
  const currentTask = state.texts[state.currentIndex]
  const maxWordLength = Math.max(...state.words.map((x) => x.length))
  return (
    <ExerciseContainer>
      <TaskSlider
        id={state.currentIndex}
        isLast={state.currentIndex == state.texts.length - 1}
      >
        <ExerciseTitle
          title={<FormattedMessage id="Multiple choice" />}
          className="mb-52"
        />
        <Sentence
          text={currentTask.text}
          maxWordLength={maxWordLength}
          words={state.words}
          currentAnswer={state.currentAnswer}
        />

        <div className="mt-52 grid grid-cols-3 gap-8">
          {state.words.map((word, i) => {
            const isAnswer = state.currentAnswer?.index == i
            return (
              <ExerciseButton
                key={`fill-the-gaps-${i}-${word}`}
                onClick={() => {
                  store.select(i)
                }}
                isCorrect={isAnswer ? 'filled' : 'none'}
              >
                {!isAnswer && word}
              </ExerciseButton>
            )
          })}
        </div>
      </TaskSlider>
    </ExerciseContainer>
  )
}

function Sentence(props: {
  text: FillWordText
  words: Readonly<string[]>
  currentAnswer: Readonly<ICurrentAnswer> | undefined
  maxWordLength: number
}) {
  const underline = '&ensp;'.repeat(props.maxWordLength)

  return (
    <div className="text-center text-20 font-semibold">
      {props.text.map((part, i) => {
        if (typeof part === 'string') {
          return <span key={`sentense-mc-${i}-${part}`}>{part}</span>
        } else {
          const content = props.currentAnswer
            ? props.words[props.currentAnswer.index]
            : underline
          return (
            <Word
              answer={!!props.currentAnswer}
              isCorrect={props.currentAnswer?.isCorrect ?? 'none'}
              key={`sentense-mc-${i}`}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Word>
          )
        }
      })}
    </div>
  )
}

const Word = classed.span('mx-8 inline-block py-8', {
  variants: {
    isCorrect: {
      true: 'text-green-dark',
      false: 'text-alert',
      none: '',
    },
    answer: {
      true: 'rounded-8 bg-white px-12',
      false: 'underline',
    },
  },
})

import { classed } from '@tw-classed/react'
import { useIntl } from 'react-intl'
import { IMetricCard } from 'src/shared/data/progressMetrics'
import { motion } from 'framer-motion'

const Text = classed.p('font-extrabold text-10 text-center mt-4', {
  variants: {
    type: {
      fire: 'text-orange3',
      book: 'text-blue2',
      teacher: 'text-green2',
    },
  },
})

const ImageWrapper = classed.div(
  'flex size-42 items-center justify-center rounded-full',
  {
    variants: {
      type: {
        fire: 'bg-orange2',
        book: 'bg-blue',
        teacher: 'bg-green-light3',
      },
    },
  },
)

const Image = classed.img({
  variants: {
    type: {
      fire: 'size-32',
      book: 'w-28 h-23',
      teacher: 'size-26',
    },
  },
})

interface IMetricCardProps {
  element: IMetricCard
  value: string | number
  valReplacement?: string | number
}

const MetricCard = ({
  element,
  value,
  valReplacement = 0,
}: IMetricCardProps) => {
  const intl = useIntl()

  return (
    <motion.div
      className="flex min-h-full w-full flex-col items-center rounded-12 bg-gray-light p-10 pt-16"
      key={element.metricKey}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <ImageWrapper type={element.type}>
        <Image src={element.icon} type={element.type} />
      </ImageWrapper>

      <p className="mt-10 text-20 font-extrabold text-black">
        {value ? value : valReplacement}
      </p>

      <Text type={element.type}>
        {intl.formatMessage({ id: element.key }).toUpperCase()}
      </Text>
    </motion.div>
  )
}

export default MetricCard

import { proxy } from 'valtio'
import { IFilteredMetrics } from '../../shared/lib/utils.ts'
import { PracticeApi } from '../../shared/api/practiceApi.ts'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import { AccountHints, ChatApi } from 'src/shared/api/chatApi.ts'

interface State {
  programs: IFilteredMetrics | []
  words: {
    loading: boolean
    count: number | null
  }
  stat: { new: number; weak: number; strong: number; sum: number } | null
  hints: AccountHints | null
}

export class PracticeStore {
  state: State

  constructor(
    private practiceApi: PracticeApi,
    private chatApi: ChatApi,
  ) {
    this.state = proxy<State>({
      words: {
        loading: false,
        count: null,
      },
      stat: null,
      programs: [],
      hints: null,
    })
  }

  async getPrograms() {
    return await this.practiceApi.getPracticePrograms()
  }

  async getPracticeInfo() {
    try {
      this.state.words.loading = true
      this.state.words.count = await this.practiceApi.getCount()
      const hints = await this.chatApi.getHints()
      if (Object.keys(hints).length) {
        this.state.hints = hints
      }
      const statistics = await this.practiceApi.getVocabStat()

      this.state.stat = {
        ...statistics,
        sum: statistics.new + statistics.strong + statistics.weak,
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.state.words.loading = false
    }
  }

  async deleteMarketingHints() {
    await this.chatApi.deleteHint('marketingPracticeHint')
    await this.chatApi.deleteHint('marketingLearningProfileHint')
    this.state.hints = null
  }
}

export const PracticeStoreContext = createStoreContext<PracticeStore>()

export function usePracticeStore() {
  return useStoreContext(PracticeStoreContext)
}

import { ProgressApi } from '../../shared/api/progressApi.ts'
import { proxy } from 'valtio'
import { IFilteredMetrics, filterMetrics } from '../../shared/lib/utils.ts'

interface State {
  metrics: IFilteredMetrics
}

export class ProgressStore {
  state: State

  constructor(private progressApi: ProgressApi) {
    this.state = proxy<State>({
      metrics: {},
    })
  }

  async getAllMetrics() {
    const data = await this.progressApi.getMetrics()
    this.state.metrics = filterMetrics(data)
  }
}

export interface ICustomSvgProps {
  size: number
  mainColor: string
  secondColor: string
}

export const CustomMessageSvg = ({
  size,
  mainColor,
  secondColor,
}: ICustomSvgProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.3099C16.8276 19.3099 22 16.8866 22 10.655C22 4.42339 16.8276 2 12 2C7.17241 2 2 4.42339 2 10.655C2 12.081 2.27088 13.3077 2.74652 14.3514C3.04301 15.0021 3.20931 15.7196 3.07799 16.4228L2.71633 18.3593C2.53757 19.3165 3.37279 20.1551 4.32622 19.9756L8.75018 19.1428C9.09793 19.0773 9.45448 19.083 9.80386 19.1386C10.531 19.2544 11.2695 19.3099 12 19.3099Z"
        fill={mainColor}
        stroke={mainColor}
        strokeWidth="2.06897"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55119 10.6549C8.55119 11.0373 8.24241 11.3473 7.86153 11.3473C7.48065 11.3473 7.17188 11.0373 7.17188 10.6549C7.17188 10.2726 7.48065 9.96252 7.86153 9.96252C8.24241 9.96252 8.55119 10.2726 8.55119 10.6549Z"
        stroke={secondColor}
        strokeWidth="1.37931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6899 10.6549C12.6899 11.0373 12.381 11.3473 12.0002 11.3473C11.6194 11.3473 11.3105 11.0373 11.3105 10.6549C11.3105 10.2726 11.6194 9.96252 12.0002 9.96252C12.381 9.96252 12.6899 10.2726 12.6899 10.6549Z"
        stroke={secondColor}
        strokeWidth="1.37931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8285 10.6549C16.8285 11.0373 16.5197 11.3473 16.1389 11.3473C15.758 11.3473 15.4492 11.0373 15.4492 10.6549C15.4492 10.2726 15.758 9.96252 16.1389 9.96252C16.5197 9.96252 16.8285 10.2726 16.8285 10.6549Z"
        stroke={secondColor}
        strokeWidth="1.37931"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '../../shared/ui/button/button.tsx'
import { ProgressBar, ProgressTags } from '../practice/PracticeProgressBar.tsx'
import { AppMessageKeys } from '../../shared/translations/messages.ts'
import { cn, useMount } from '../../shared/lib/utils.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { urls } from '../../shared/urls.ts'
import { IProgressBlockRects } from '../practice/PracticeView.tsx'
import { useIsMobile } from '../../shared/lib/hooks.ts'
type MarketingHintSteps = 'vocabModal' | 'progressModal'

interface ProgressHintTagType {
  mainId: AppMessageKeys
  word: string
}

export const PROGRESS_HINT_TAGS: ProgressHintTagType[] = [
  { mainId: 'ProgressHintTextNew', word: 'New' },
  { mainId: 'ProgressHintTextWeak', word: 'Weak' },
  { mainId: 'ProgressHintTextStrong', word: 'Strong' },
]

export const HintWrapper = ({
  children,
  isFocusElement = false,
  onFocusElementClick,
}: {
  children: React.ReactNode
  isFocusElement: boolean
  onFocusElementClick?: () => void
}) => {
  const [holeStyle, setHoleStyle] = useState<React.CSSProperties | null>(null)

  useEffect(() => {
    if (!isFocusElement) return
    const button = document.getElementById('navbar.practice')
    if (button) {
      const { top, left, height } = button.getBoundingClientRect()
      setHoleStyle({
        left: left - 10,
        top: top,
        width: height,
        height: height,
        boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.60)',
      })
    }
  }, [isFocusElement])
  return (
    <motion.div
      className={cn(
        'fixed left-0 top-0 z-50 flex size-full max-h-dvh',
        isFocusElement ? '' : 'bg-black-80',
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
      {isFocusElement && holeStyle && (
        <div
          style={holeStyle}
          className="pointer-events-auto absolute rounded-full bg-transparent"
          onClick={onFocusElementClick}
        />
      )}
    </motion.div>
  )
}

export const PracticeMarketingVPHint = ({
  rects,
  onEnd,
}: {
  rects: IProgressBlockRects | null
  onEnd: () => void
}) => {
  const [hintStep, setHintStep] = useState<MarketingHintSteps>('vocabModal')
  if (rects === null) return null

  return (
    <HintWrapper isFocusElement={false}>
      <AnimatePresence mode="wait" initial={false}>
        {hintStep === 'vocabModal' && (
          <VocabModalHint
            handler={() => {
              setHintStep('progressModal')
            }}
            key="vocabModal"
          />
        )}
        {hintStep === 'progressModal' && (
          <ProgressModalHint
            handler={() => {
              onEnd()
            }}
            key="progressModal"
            rects={rects}
          />
        )}
      </AnimatePresence>
    </HintWrapper>
  )
}

export const LearnProfileMarketingVPHint = ({
  handler,
}: {
  handler: () => void
}) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  return (
    <HintWrapper
      isFocusElement={isMobile}
      onFocusElementClick={() => {
        isMobile ? navigate(urls.practice) : {}
      }}
    >
      <AnimatePresence mode="wait">
        <VocabModalHint handler={handler} key="vocabModal" />
      </AnimatePresence>
    </HintWrapper>
  )
}

const VocabModalHint = ({ handler }: { handler: () => void }) => {
  const location = useLocation()
  const isPracticePage = location.pathname === '/practice'

  return (
    <motion.div
      className={cn(
        'relative z-50 m-auto flex  h-fit w-[calc(100%-32px)] max-w-[600px] flex-col items-center rounded-12  bg-white pb-20 text-center',
        !isPracticePage ? 'mb-[100px] mt-auto sm:my-auto' : '',
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <img src="/images/practiceHintHeader.png" className="rounded-t-12" />
      <h3 className="font-nunito-7-semicondensed text-28 font-black text-black">
        <FormattedMessage id="VocabHintHeader" values={{ br: <br /> }} />
      </h3>
      <p className="mx-28 mt-8 font-nunito-7-semicondensed text-16 font-normal">
        <FormattedMessage id="VocabHintText" />
      </p>
      <Button
        size="extralarge"
        bg="blue-gradient-shadow-inset"
        className="mt-20 w-[calc(100%-32px)]"
        rounded="full"
        onClick={handler}
      >
        <FormattedMessage id={isPracticePage ? 'Continue' : 'ok'} />
      </Button>
      {!isPracticePage && (
        <Button
          className="absolute right-12 top-12 size-28 border-none p-0 text-purple3"
          size="custom"
          rounded="full"
          bg="white"
          onClick={handler}
        >
          <Icon iconName="close" size="small" className="min-h-28 min-w-28" />
        </Button>
      )}
      {!isPracticePage && <BottomArrow />}
    </motion.div>
  )
}

const BottomArrow = () => {
  const [leftOffset, setLeftOffset] = useState<number | null>(null)

  useMount(() => {
    const button = document.getElementById('navbar.practice')
    if (button) {
      const { left } = button.getBoundingClientRect()
      setLeftOffset(left)
    }
  })
  if (!leftOffset) return null
  return (
    <div
      className="absolute"
      style={{
        bottom: -14,
        left: leftOffset,
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '14px solid white',
      }}
    />
  )
}

const ProgressModalHint = ({
  handler,
  rects,
}: {
  handler: () => void
  rects: IProgressBlockRects
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      onClick={() => {
        handler()
      }}
      className="size-full "
    >
      <div
        style={{
          paddingTop: rects.top,
          paddingLeft: rects.left,
        }}
      >
        <ProgressBar
          className={cn(
            'flex h-132 w-full overflow-hidden rounded-16 bg-white p-6',
          )}
          wrapperWidth={rects.width}
        />
        <div style={{ width: rects.width }}>
          <ProgressTags className="mx-auto flex w-fit justify-center gap-16 rounded-b-18 bg-white px-16 py-6" />
        </div>
        <p className="mt-28 px-24 text-24 font-extrabold text-white">
          <FormattedMessage id="ProgressHintHeader" />
        </p>
        <div className="mx-auto mt-10 px-36">
          {PROGRESS_HINT_TAGS.map((el) => (
            <HintText id={el.mainId} word={el.word} key={el.mainId} />
          ))}
        </div>
        <p className="mt-16 px-36 text-16 font-semibold text-white">
          <FormattedMessage id="ProgressHintFooter" />
        </p>
      </div>
    </motion.div>
  )
}

const HintText = ({ id, word }: { id: AppMessageKeys; word: string }) => {
  return (
    <p className="font-nunito-7-semicondensed text-16 font-semibold text-white">
      <FormattedMessage
        id={id}
        values={{
          word: <span className="font-bold">{word}</span>,
        }}
      />
    </p>
  )
}

import { proxy } from 'valtio'

interface State {
  suitableLevel?: number
  experienceRating?: number
  additionalFeedback: string
  disableButton: boolean
}

export class RateLessonStore {
  state = proxy<State>({
    additionalFeedback: '',
    get disableButton() {
      return (
        this.suitableLevel === undefined &&
        this.experienceRating === undefined &&
        this.additionalFeedback === ''
      )
    },
  })

  setSuitableLevel(level: number) {
    this.state.suitableLevel = level
  }

  setExperienceRating(rating: number) {
    this.state.experienceRating = rating
  }

  setAdditionalFeedback(feedback: string) {
    this.state.additionalFeedback = feedback
  }
}

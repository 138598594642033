import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '../../lib/utils.ts'

const Root = PopoverPrimitive.Root

const Trigger = PopoverPrimitive.Trigger

const Anchor = PopoverPrimitive.Anchor
const Portal = PopoverPrimitive.Portal

const Content = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      onOpenAutoFocus={(e) => {
        e.preventDefault()
      }}
      className={cn(
        'z-50 rounded-8 bg-white p-16 shadow-popover outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
))
Content.displayName = PopoverPrimitive.Content.displayName

export const Popover = { Root, Trigger, Content, Anchor, Portal }

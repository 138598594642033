import { AppAudioRecorder } from './appAudioRecorder.ts'
import { AuthStore } from '../../auth/authStore.ts'

export class StreamService {
  items?: {
    stream: MediaStream
    recorder: AppAudioRecorder
    streamSource: MediaStreamAudioSourceNode
    audioContext: AudioContext
    analyser: AnalyserNode
  }

  analyzerData = new Uint8Array()
  constructor(private authStore: AuthStore) {}

  async getRecorder() {
    if (!this.items) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { echoCancellation: true, noiseSuppression: true },
        })
        this.authStore.trackEvent('microfon_accept')
        const recorder = new AppAudioRecorder(stream)
        const audioContext = new AudioContext()
        const analyser = audioContext.createAnalyser()
        analyser.fftSize = 256
        analyser.smoothingTimeConstant = 1
        const streamSource = audioContext.createMediaStreamSource(stream)
        streamSource.connect(analyser)
        this.items = {
          stream,
          recorder,
          streamSource,
          audioContext,
          analyser,
        }
        this.analyzerData = new Uint8Array(analyser.fftSize)
      } catch {
        throw new Error('Необходимо разрешить доступ к микрофону')
      }
    }
    return this.items.recorder
  }

  getUpdatedAnalyzerData() {
    if (this.items) {
      this.items.analyser.getByteTimeDomainData(this.analyzerData)
    }
    return this.analyzerData
  }

  stopTracks() {
    if (!this.items) {
      return
    }
    this.items.analyser.disconnect()
    this.items.streamSource.disconnect()
    this.items.stream.getAudioTracks().forEach((x) => {
      x.stop()
    })
    this.items = undefined
  }
}

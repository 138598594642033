import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '../../lib/utils.ts'

const Root = TabsPrimitive.Root

const List = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('flex items-center border-b border-b-violet/60', className)}
    {...props}
  />
))
List.displayName = TabsPrimitive.List.displayName

const Trigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex h-40 items-center justify-center whitespace-nowrap  border-b-2 border-b-transparent px-16 text-14 font-medium transition-all hover:text-main-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray  focus-visible:ring-offset-4 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-b-violet',
      className,
    )}
    {...props}
  />
))
Trigger.displayName = TabsPrimitive.Trigger.displayName

const Content = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray focus-visible:ring-offset-4',
      className,
    )}
    {...props}
  />
))
Content.displayName = TabsPrimitive.Content.displayName

export const Tabs = { Root, List, Content, Trigger }

import { proxy } from 'valtio'
import { AnswerStatus, AnswerStatusStore } from '../answerStatusDrawer.tsx'
import { TrueOrFalseQuestion } from '../../../shared/api/chatApi.ts'
import { ICurrentAnswer, Payload } from '../../../shared/lib/utils.ts'

interface State {
  questions: readonly TrueOrFalseQuestion[]
  currentIndex: number
  currentAnswer?: boolean
  answerStatus?: AnswerStatus
  correctAnswersCount: number
  userAnswers: ICurrentAnswer[]
}

export class TrueOrFalseExerciseStore {
  state = proxy<State>({
    questions: [],
    currentIndex: 0,
    correctAnswersCount: 0,
    userAnswers: [],
  })

  constructor(
    questions: readonly TrueOrFalseQuestion[],
    private answerStore: AnswerStatusStore,
    private onComplete: (
      result: string,
      correctAnswersCount: number,
      payload: Payload[],
    ) => void,
  ) {
    this.state.questions = questions
  }

  nextQuestion(): void {
    if (this.state.currentIndex < this.state.questions.length - 1) {
      this.state.currentIndex += 1
      setTimeout(() => {
        this.state.answerStatus = undefined
      }, 50)
    } else {
      this.state.answerStatus = AnswerStatus.complete()
      const getTOFPayload = () => {
        return this.state.questions.reduce<Payload[]>((acc, cur, i) => {
          return [
            ...acc,
            {
              item: cur.title,
              correct: this.state.userAnswers[i].isCorrect,
            },
          ]
        }, [])
      }
      this.onComplete(
        'I have completed the exercise',
        this.state.correctAnswersCount,
        getTOFPayload(),
      )
    }
  }

  answerQuestion(userAnswer: boolean) {
    if (this.state.answerStatus) {
      return
    }
    const answer = this.state.questions[this.state.currentIndex].isCorrect
    this.state.currentAnswer = userAnswer
    const isCorrect = answer === userAnswer
    const status = isCorrect
      ? AnswerStatus.correct()
      : AnswerStatus.wrong({
          description: 'Answer:',
          messageValue: answer ? 'True' : 'False',
        })
    userAnswer == answer && this.state.correctAnswersCount++
    this.state.answerStatus = status
    const index = this.state.currentIndex + 1

    this.state.userAnswers.push({ index, isCorrect })
    this.answerStore.setStatus(status, () => {
      this.nextQuestion()
    })
  }
}

import { WriteTheGapsStore } from './writeTheGapsStore.ts'
import { cn, splitByCount } from '../../shared/lib/utils.ts'
import { useLazyRef } from '../../shared/lib/hooks.ts'
import { useSnapshot } from 'valtio/react'
import { CompleteButton, ExerciseHeader } from './exerciseComponents.tsx'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { FormattedMessage } from 'react-intl'
import { ClickableWords } from '../../shared/ui/ClickableWords/ClickableWords.tsx'
import { useStores } from '../chats/chatView.tsx'

export type WriteTheGapsText = readonly (
  | string
  | { word: string; hint: string }
)[]
interface WriteTheGapssProps {
  texts: readonly WriteTheGapsText[]
  completed: boolean
  onComplete: (result: string) => void
}
export const WriteTheGapss = (props: WriteTheGapssProps) => {
  const store = useLazyRef(() => new WriteTheGapsStore(props.texts)).current
  const state = useSnapshot(store.state)
  const hintLists = splitByCount(state.hints, 2)

  return (
    <div>
      <ExerciseHeader
        title={<FormattedMessage id="exercise.writeTheGaps.title" />}
        subtitle={<FormattedMessage id="exercise.writeTheGaps.subtitle" />}
      />

      <div className="mb-24 flex items-end">
        <img className="hidden sm:block" src="/images/exercise-hints.svg" />
        <div className="relative flex overflow-x-auto rounded-4  bg-gray-light px-16">
          <div className="mr-32 text-14 font-bold">
            {hintLists.map((hints, i) => (
              <div className="flex items-center gap-16 py-4 " key={i}>
                {hints.map((meaning, i) => (
                  <span key={i}>{meaning}</span>
                ))}
              </div>
            ))}
          </div>
          <div className="absolute inset-x-0 top-half h-2 bg-purple-light2"></div>
          <img src="/images/exercise-box.svg" />
        </div>
      </div>

      <div className="flex flex-col gap-12">
        {props.texts.map((text, i) => (
          <Sentence index={i} key={i} text={text} store={store} />
        ))}
      </div>

      <CompleteButton
        completed={props.completed}
        onComplete={() => {
          props.onComplete(store.getResult())
        }}
      />
    </div>
  )
}

function Sentence(props: {
  index: number
  text: WriteTheGapsText
  store: WriteTheGapsStore
}) {
  const chatStore = useStores().chatStore
  const chatState = useSnapshot(chatStore.state)
  const state = useSnapshot(props.store.state, { sync: true })
  const answers = state.answers
  const writtenWords = state.writtenWords
  return (
    <div className="text-14 font-semibold">
      <span className="mr-8 inline-block text-14 font-bold">
        {props.index + 1}.
      </span>
      {props.text.map((part, i) => {
        if (typeof part === 'string') {
          return (
            <ClickableWords
              key={i}
              className="inline-block"
              disabled={false}
              selectedWords={chatState.selectedWords}
              onClick={() => {
                void chatStore.handleWord()
              }}
            >
              {part}
            </ClickableWords>
          )
        } else {
          const wordIndex = answers.indexOf(part.word)
          const writtenWord = writtenWords[wordIndex]
          const status = state.statuses[wordIndex]
          const bg =
            status === 'correct'
              ? 'green-light'
              : status == 'incorrect'
              ? 'alert-light'
              : 'gray'
          return (
            <TextInput
              className={cn('max-w-100 font-semibold')}
              bg={bg}
              size="little"
              onBlur={() => {
                props.store.check(wordIndex)
              }}
              onChange={(e) => {
                props.store.writeWord(wordIndex, e.target.value)
              }}
              value={writtenWord ?? ''}
              key={part.word}
            />
          )
        }
      })}
    </div>
  )
}

import { IMetrics, PickPublic } from '../lib/utils.ts'
import { HttpClient } from './httpClient.ts'

export type ProgressApi = PickPublic<AppProgressApi>
export class AppProgressApi {
  constructor(private httpClient: HttpClient) {}

  async getMetrics() {
    return await this.httpClient.get<IMetrics[]>(`/api/account/stat/metrics`)
  }

  async getCurrentLessonMetrics(lessonId: string) {
    return await this.httpClient.get<IMetrics[]>(
      `/api/account/stat/metrics/lesson/${lessonId}/`,
    )
  }
}

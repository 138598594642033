import * as React from 'react'
import { ReactNode } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from '../../lib/utils.ts'
import { Icon } from '../icon/icon.tsx'

const Content = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    light?: boolean
  }
>(({ className, light = false, children, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      light ? 'bg-purple3 text-default' : 'bg-violetd text-white',
      'relative z-40 rounded-8 p-16 shadow-popover outline-none animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  >
    {children}
    <TooltipPrimitive.Arrow
      width={16}
      height={8}
      className={light ? 'fill-purple3' : 'fill-violetd'}
    />
  </TooltipPrimitive.Content>
))
Content.displayName = TooltipPrimitive.Content.displayName
export const Tooltip = {
  Provider: TooltipPrimitive.Provider,
  Root: TooltipPrimitive.Root,
  Trigger: TooltipPrimitive.Trigger,
  Content,
}

export const WithTooltip = (props: {
  children: ReactNode
  disabled?: boolean
  tooltip: ReactNode
  light?: boolean
  sideOffset?: number
  align?: 'start' | 'center' | 'end'
  alignOffset?: number
  open?: boolean
  onOpenChange?: (open: boolean) => void
  side?: 'top' | 'right' | 'bottom' | 'left'
  className?: string
}) => {
  const [open, setOpen] = React.useState(false)

  if (props.disabled) {
    return props.children
  }
  return (
    <Tooltip.Root
      open={props.open ?? open}
      onOpenChange={props.onOpenChange ?? setOpen}
    >
      <Tooltip.Trigger asChild>{props.children}</Tooltip.Trigger>
      <TooltipPrimitive.Portal>
        <Tooltip.Content
          light={props.light}
          sideOffset={props.sideOffset}
          alignOffset={props.alignOffset}
          align={props.align ?? 'center'}
          side={props.side ?? 'top'}
          className={cn('max-w-300')}
        >
          {props.tooltip}
        </Tooltip.Content>
      </TooltipPrimitive.Portal>
    </Tooltip.Root>
  )
}

export function TooltipContent(props: {
  title: React.ReactNode
  text: React.ReactNode
}) {
  return (
    <div className="w-200">
      <div className="-ml-4 flex items-center gap-4">
        <Icon iconName="bulb" size="small" className="mb-8 text-tomato" />
        <div className="text-14 font-extrabold">{props.title}</div>
      </div>
      <div className="text-12">{props.text}</div>
    </div>
  )
}

import { useRouteError } from 'react-router-dom'
import { InformationBlock } from '../shared/ui/informationBlock/informationBlock.tsx'
import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

export function ErrorPage() {
  const error = useRouteError() as Error
  console.error(error)
  useEffect(() => {
    captureException(error, { level: 'fatal' })
  }, [error])

  return (
    <InformationBlock
      imageUrl="/images/status-error.png"
      title={<FormattedMessage id="error" />}
      buttonText={<FormattedMessage id="backHome" />}
      returnUrl="/"
    >
      <span className="p-16">
        <FormattedMessage
          id="errorWithText"
          values={{ errorMessage: error.message }}
        />
      </span>
    </InformationBlock>
  )
}

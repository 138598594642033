import { PickPublic } from '../lib/utils.ts'
import { HttpClient } from './httpClient.ts'
import { Vocabulary } from './vocabApi.ts'

interface VocabularyResponse {
  count: number
  next: string | null
  previous: string | null
  results: Vocabulary[]
}

export interface VocabularyStat {
  new: number
  weak: number
  strong: number
}

export type PracticeApi = PickPublic<AppPracticeApi>
export class AppPracticeApi {
  constructor(private httpClient: HttpClient) {}

  async getPracticePrograms() {
    return await this.httpClient.get(`/api/user_practice/`)
  }

  async getCount() {
    const result = await this.httpClient.get<VocabularyResponse>(
      '/api/vocabulary/',
      {
        limit: 1,
      },
    )
    return result.count
  }
  async getVocabStat() {
    return await this.httpClient.get<VocabularyStat>('/api/vocabulary/stat/')
  }
}

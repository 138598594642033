import { TimerId } from '../../shared/lib/utils.ts'
import { SpeechService } from '../speech/speechService.ts'
import { ErrorNotifier } from '../../shared/api/httpClient.ts'
import { ChatApi } from '../../shared/api/chatApi.ts'
import { ChatStore } from './chatStore.ts'
import { proxy } from 'valtio'
import { HideTooltipManager } from '../../shared/lib/hideTooltipManager.ts'
import { AuthStore } from '../auth/authStore.ts'

interface State {
  intervalId?: TimerId
  recording: boolean
  autoMode: boolean
  recordTimer: number
}

const minRecordTimeMs = 500

export class ChatAudioRecordStore {
  recordTimerId?: TimerId
  recordTimerStartDate = 0

  startTimer() {
    const interval = 100
    this.recordTimerStartDate = Date.now()
    this.state.recordTimer = 0
    this.recordTimerId = setInterval(() => {
      this.state.recordTimer = Date.now() - this.recordTimerStartDate
    }, interval)
  }

  stopTimer() {
    clearInterval(this.recordTimerId)
  }

  state = proxy<State>({
    recording: false,
    intervalId: undefined,
    recordTimer: 0,
    get autoMode() {
      return !!this.intervalId
    },
  })

  constructor(
    private speechService: SpeechService,
    private errorNotifier: ErrorNotifier,
    private chatApi: ChatApi,
    private chatStore: ChatStore,
    private hideTooltipManager: HideTooltipManager,
    private authStore: AuthStore,
  ) {}

  start() {
    this.state.intervalId = setInterval(() => {
      void this.checkSpeechEnd()
    }, 3000)
  }

  reset() {
    this.stopTimer()
  }

  stop() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
      this.state.intervalId = undefined
    }
  }

  async startOrCancelRecording() {
    if (this.state.recording) {
      await this.stopRecording(false)
    } else {
      await this.startRecording()
      this.chatStore.state.shouldViewMicroTooltip &&
        this.chatStore.setShouldViewMicroTooltip(false)
    }
  }

  async startRecording() {
    if (this.chatStore.state.sendButtonDisabled) {
      return
    }
    this.chatStore.stopEdmanAudio()
    this.startTimer()
    this.hideTooltipManager.setTooltipState('holdToRecord', false)
    this.state.recording = true
    try {
      this.chatStore.state.loading = 'record'
      await this.speechService.start(this.state)
    } catch (e) {
      this.state.recording = false
      this.chatStore.state.loading = undefined
      this.errorNotifier.onError(e)
    }
  }

  async stopRecording(send: boolean) {
    if (!this.state.recording) {
      return
    }
    try {
      this.state.recording = false
      this.stopTimer()
      if (send && this.state.recordTimer >= minRecordTimeMs) {
        this.chatStore.state.loading = 'analyze'
        this.authStore.trackEvent('microfon_sent')
        const text = await this.speechService.stopAndAnalyze(
          this.chatStore.state.chatId,
        )
        this.chatStore.state.loading = undefined
        await this.chatStore.send(text)
      } else {
        await this.speechService.stopRecording()
        this.chatStore.state.loading = undefined
      }
      if (this.state.autoMode) {
        await this.startRecording()
      }
    } finally {
      this.chatStore.state.loading = undefined
    }
  }

  async cancelRecord() {
    await this.stopRecording(false)
  }

  async checkSpeechEnd() {
    const data = await this.speechService.requestData()
    if (!data) {
      return
    }
    const result = await this.chatApi.voice_push(
      this.chatStore.state.chatId,
      data,
    )
    if (result.done) {
      await this.stopRecording(true)
    }
  }
}

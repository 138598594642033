import { MetricKeysEnum } from '../lib/utils.ts'
import { AppMessageKeys } from '../translations/messages.ts'

export interface IMetricCard {
  key: AppMessageKeys
  icon: string
  type: 'fire' | 'book' | 'teacher'
  metricKey: MetricKeysEnum
}

export interface IMetric {
  created?: string
  updated?: string
  name: string
  order: number
  value: number
}

export const metricCards: IMetricCard[] = [
  {
    key: 'metrics.daysWithoutBreak',
    metricKey: MetricKeysEnum.DaysSeriesRecord,
    icon: '/images/progress/fire.png',
    type: 'fire',
  },
  {
    key: 'metrics.uniqueWordsAll',
    metricKey: MetricKeysEnum.UniqueWords,
    icon: '/images/progress/blue-book.png',
    type: 'book',
  },
  {
    key: 'metrics.lessonsCompleted',
    metricKey: MetricKeysEnum.LessonsCompleted,
    icon: '/images/progress/man-teacher.png',
    type: 'teacher',
  },
]

export const lessonMetricCards: IMetricCard[] = [
  {
    key: 'metrics.totalWordsChat',
    metricKey: MetricKeysEnum.UniqueWordsChat,
    icon: '/images/progress/blue-book.png',
    type: 'book',
  },
  {
    key: 'metrics.daysWithoutBreak',
    metricKey: MetricKeysEnum.DaysSeries,
    icon: '/images/progress/fire.png',
    type: 'fire',
  },

  {
    key: 'metrics.totalErrorsChat',
    metricKey: MetricKeysEnum.ErrorsChat,
    icon: '/images/progress/man-teacher.png',
    type: 'teacher',
  },
]

import { classed } from '@tw-classed/react'
import { cn } from '../lib/utils.ts'

export const Spinner = classed.div(
  'animate-spin rounded-full border-b-2 border-r-2 border-current',
  {
    variants: {
      size: {
        small: 'size-16',
        medium: 'size-24',
        large: 'size-32',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  },
)

export function DotsAnimation() {
  return (
    <div className="inline-flex h-24 items-center gap-2">
      <span className="size-8 animate-jump rounded-full bg-main"></span>
      <span className="size-8 animate-jump rounded-full bg-main animation-delay-150"></span>
      <span className="size-8 animate-jump rounded-full bg-main animation-delay-300"></span>
    </div>
  )
}

export function ImageLoader(props: { className?: string }) {
  return (
    <div className={cn('inline-flex h-24 items-center gap-8', props.className)}>
      <span className="size-12 animate-scaleop rounded-full bg-main"></span>
      <span className="size-12 animate-scaleop rounded-full bg-alert animation-delay-150"></span>
      <span className="size-12 animate-scaleop rounded-full bg-violet animation-delay-300"></span>
      <span className="size-12 animate-scaleop rounded-full bg-green animation-delay-450"></span>
      <span className="size-12 animate-scaleop rounded-full bg-purple4 animation-delay-600"></span>
    </div>
  )
}

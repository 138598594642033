import { cn } from '../../shared/lib/utils.ts'

const contacts = [
  {
    title: 'Telegram',
    img: '/images/contacts/telegram.svg',
    url: 'https://t.me/edman_ai',
    urlTitle: '@edman_ai',
  },
  {
    title: 'Whatsapp',
    img: '/images/contacts/whatsapp.svg',
    url: 'https://wa.me/message/4DA7WT5FGXIRE1',
    urlTitle: 'Edman Ai',
  },
  {
    title: 'E-mail',
    img: '/images/contacts/email.svg',
    url: 'mailto:support@edman.ai',
    urlTitle: 'support@edman.ai',
  },
]

export function ContactsBlock(props: {
  full?: boolean
  className?: string
  compact?: boolean
}) {
  return (
    <div
      className={cn(
        'flex justify-around',
        props.compact ? 'gap-48' : props.full ? 'gap-60' : 'gap-16',
        props.className,
      )}
    >
      {contacts.map((x, i) => {
        return (
          <a
            rel="noreferrer"
            target="_blank"
            href={x.url}
            className="flex flex-col items-center"
            key={i}
          >
            <img
              alt={x.title}
              className={cn(props.full ? 'h-64' : 'h-32')}
              src={x.img}
            />
            {props.full && (
              <>
                <span className="mt-8 text-18 font-extrabold">{x.title}</span>
                <span className="mt-4 text-12 font-medium">{x.urlTitle}</span>
              </>
            )}
          </a>
        )
      })}
    </div>
  )
}

import { proxy } from 'valtio'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import {
  ChatApi,
  Student,
  Subscription,
  SubscriptionPlan,
} from '../../shared/api/chatApi.ts'
import { AuthStore } from '../auth/authStore.ts'
import { SubscriptionService } from '../subscriptions/subscriptionService.ts'
import {
  OnboardingInfo,
  OnboardingStore,
} from '../onboarding/OnboardingStore.ts'

interface ProfileState {
  student?: Student
  subscription?: Subscription
  showChangeSubscription: boolean
  showCancelSubscription: boolean
  showDeleteAccount: boolean
  showLogout: boolean
  email: string
  name: string
  password: string
  plan: SubscriptionPlan
  editField?: 'name' | 'email' | 'password'
  onboardingKey?: keyof OnboardingInfo
  editValue: string
  version: string
}

export class ProfileStore {
  state: ProfileState

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
    private subscriptionService: SubscriptionService,
    public onboardingStore: OnboardingStore,
  ) {
    this.state = proxy<ProfileState>({
      showChangeSubscription: false,
      showCancelSubscription: false,
      showDeleteAccount: false,
      showLogout: false,
      email: authStore.state.user?.login ?? '',
      name: '',
      password: '',
      editValue: '',
      plan: '1M',
      version: '',
    })
    void this.loadAccount()
    void this.loadSubscription()
  }

  setShowCancelSubscription(value: boolean) {
    this.state.showCancelSubscription = value
  }

  setShowChangeSubscription(value: boolean) {
    this.state.showChangeSubscription = value
  }

  setShowDeleteAccount(value: boolean) {
    this.state.showDeleteAccount = value
  }

  setShowLogout(value: boolean) {
    this.state.showLogout = value
  }

  setSubscriptionPlan(value: SubscriptionPlan) {
    if (!this.state.subscription) {
      return
    }
    this.state.subscription.plan = value
  }

  setPlan(value: SubscriptionPlan) {
    this.state.plan = value
  }

  deleteAccount() {
    void this.chatApi.deleteAccount()
    this.authStore.logoutAndRefresh()
  }

  async unsubscribe() {
    if (!this.state.subscription) {
      return
    }
    await this.chatApi.unsubscribe(this.state.subscription.id)
    await this.loadSubscription()
    this.state.showCancelSubscription = false
    this.authStore.trackOnbMetrika('trial_cancelled')
  }

  async updateSubscriptionn() {
    console.log(this.state.subscription)

    if (!this.state.subscription) {
      return
    }
    await this.chatApi.updateSubscriptions(this.state.subscription)
  }

  async loadSubscription() {
    this.state.subscription =
      await this.subscriptionService.getActiveSubscription()
  }

  async loadAccount() {
    this.state.student = await this.chatApi.getAccount()
    this.state.name = this.state.student.name
    this.onboardingStore.loadFromApiInfo(this.state.student.onboarding_info)
    await this.getVersion()
  }

  openAccountDrawer(field: 'name' | 'email' | 'password') {
    this.state.editField = field
    this.state.editValue = this.state[field]
  }

  openOnboardingDrawer(key: keyof OnboardingInfo) {
    const info = this.state.student?.onboarding_info
    if (!info) {
      return
    }
    this.state.onboardingKey = key
  }

  closeDrawer() {
    this.state.editField = undefined
    this.state.editValue = ''
    this.state.onboardingKey = undefined
    this.state.showChangeSubscription = false
    this.state.showCancelSubscription = false
    this.state.showDeleteAccount = false
    this.state.showLogout = false
  }

  setEditValue(value: string) {
    this.state.editValue = value
  }

  async saveOnboardingChanges() {
    if (!this.state.student) {
      return
    }

    await this.chatApi.updateAccount({
      ...this.state.student,
      onboarding_info: this.onboardingStore.getApiOnboardingInfo(),
    })
    await this.loadAccount()

    this.closeDrawer()
  }

  async saveAccountChanges() {
    if (this.state.editField === 'name') {
      if (!this.state.student) {
        return
      }

      await this.chatApi.updateAccount({
        name: this.state.editValue,
      })
      await this.loadAccount()
    }

    if (this.state.editField === 'password') {
      await this.chatApi.updatePassword({
        password: this.state.editValue,
      })
    }

    if (this.state.editField === 'email') {
      await this.chatApi.updateEmail({
        email: this.state.editValue,
      })
      this.state.email = this.state.editValue
      this.authStore.setLogin(this.state.editValue)
    }

    this.closeDrawer()
  }
  async getVersion() {
    this.state.version = await this.chatApi.version()
  }
}

export const ProfileStoreContext = createStoreContext<ProfileStore>()

export function useProfileStore() {
  return useStoreContext(ProfileStoreContext)
}

import { proxy } from 'valtio'
import { ChatApi } from '../../shared/api/chatApi.ts'
import { AppLocalStorage } from '../../shared/lib/appLocalStorage.ts'
import { getHash } from '../../shared/lib/utils.ts'
import { DataState, isDataLoaded } from '../../shared/lib/hooks.ts'

interface State {
  currentVersion: DataState<string>
  showNotification: boolean
  open: boolean
}

const defaultState: State = {
  currentVersion: 'loading',
  showNotification: false,
  open: false,
}

export class WhatIsNewStore {
  state = proxy<State>(defaultState)

  constructor(
    private chatApi: ChatApi,
    private appStorage: AppLocalStorage,
  ) {}

  async checkForUpdates() {
    this.state.currentVersion = 'loading'
    const version = await this.chatApi.version()
    this.state.currentVersion = version
    const prevVersion = this.appStorage.get('whatIsNewHash')
    this.state.showNotification = prevVersion != getHash(version)
  }

  saveUpdate() {
    if (isDataLoaded(this.state.currentVersion)) {
      this.appStorage.set('whatIsNewHash', getHash(this.state.currentVersion))
      this.state.showNotification = false
    }
  }

  async setOpen(open: boolean) {
    this.state.open = open
    if (open) {
      await this.checkForUpdates()
      this.saveUpdate()
    }
  }
}

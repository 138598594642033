import { useParams } from 'react-router-dom'
import { FullChat } from '../widgets/chat/fullChat.tsx'
import { demoLessonTag } from '../shared/api/chatApi.ts'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type RouteParams = {
  tag: string
}

export function ChatPage() {
  const params = useParams<RouteParams>()
  const tag = params.tag ?? demoLessonTag
  return <FullChat tag={tag} />
}

import { Navigate, useParams } from 'react-router-dom'
import { VocabChat } from '../widgets/chat/vocabChat.tsx'
import { urls } from '../shared/urls.ts'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type RouteParams = {
  tag: string
}

export function VocabPage() {
  const params = useParams<RouteParams>()

  const tag = params.tag ?? null
  if (!tag) {
    return <Navigate to={urls.practice} />
  }
  return <VocabChat tag={tag} />
}

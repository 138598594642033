import { parseISO } from 'date-fns'
import { getValueByKey } from './utils.ts'

export function splitToWordsAndSymbols(input: string): string[] {
  const emojiRegex =
    /([\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}])/u
  const nonWordRegex = /([^a-zA-Zа-яА-Я0-9_\-'])/
  const combinedRegex = new RegExp(
    `${emojiRegex.source}|${nonWordRegex.source}`,
    'u',
  )

  return input.split(combinedRegex).filter(Boolean)
}

export function isWord(word: string): boolean {
  return /^[\w\-]+$/.test(word)
}

export function formatDate(date: string | Date): string {
  const d = typeof date == 'string' ? parseISO(date) : date
  return d.toLocaleDateString()
}

function cyrillicTranslit(input: string): string {
  const russianToEnglish = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'kh',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ъ: '',
    ы: 'y',
    ь: '',
    э: 'e',
    ю: 'yu',
    я: 'ya',
  }
  return input
    .split('')
    .map((char) => {
      const lowerChar = char.toLowerCase()
      const transliterated = getValueByKey(russianToEnglish, lowerChar) ?? char
      const isUpperCase = char === char.toUpperCase()
      return isUpperCase ? transliterated.toUpperCase() : transliterated
    })
    .join('')
}

export function convertToId(string: string): string {
  return cyrillicTranslit(string)
    .replace(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase()
}

export function toUppercase<T extends string>(txt: T): Uppercase<T> {
  return txt.toUpperCase() as never
}

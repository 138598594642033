import React from 'react'
import { Button } from '../button/button.tsx'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { urls } from '../../urls.ts'

interface InformationBlockProps {
  imageUrl: string
  children?: React.ReactNode
  title: React.ReactNode
  buttonText: React.ReactNode
  buttonId?: string
  returnUrl: string
}

export const InformationBlock = (props: InformationBlockProps) => {
  const navigate = useNavigate()
  return (
    <div className="flex w-full flex-1 items-stretch justify-center bg-light p-16 pb-100 sm:items-center">
      <div className="flex w-full max-w-800 flex-col items-center justify-between rounded sm:p-24 ">
        <img src={props.imageUrl} alt="status-img" className="size-[228px]" />
        <div className="mt-32 whitespace-nowrap text-center text-32 font-extrabold">
          {props.title}
        </div>
        <div className="">
          {props.children ?? (
            <div className="my-16 max-w-400 text-center text-24 font-semibold">
              <FormattedMessage id="pleaseTryAgain" />
            </div>
          )}
        </div>
        <Button
          bg="blue-gradient"
          size="large"
          id={props.buttonId}
          rounded="full"
          className="w-full sm:w-800"
          onClick={() => {
            navigate(props.returnUrl)
          }}
        >
          {props.buttonText}
        </Button>

        <Button
          bg="transparent"
          size="large"
          id="contact-button"
          rounded="full"
          className="mt-8 w-full sm:w-800"
          onClick={() => {
            navigate(urls.contacts)
          }}
        >
          <FormattedMessage id="contacts.title" />
        </Button>
      </div>
    </div>
  )
}

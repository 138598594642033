import { AppLocalStorage, HideTooltip } from './appLocalStorage.ts'
import { proxy } from 'valtio'

export type HideTooltipKey = keyof HideTooltip

type State = { [key in HideTooltipKey]?: boolean }
export class HideTooltipManager {
  state: State

  constructor(private storage: AppLocalStorage) {
    this.state = proxy<State>({})
  }

  setTooltipState(key: HideTooltipKey, value: boolean | undefined) {
    if (value !== true || this.get(key) !== true) {
      this.state[key] = value
    }
  }

  set(key: HideTooltipKey, value: boolean) {
    const tooltips = this.storage.get('hideTooltips') ?? {}
    tooltips[key] = value
    this.storage.set('hideTooltips', tooltips)
  }

  setFirstShown(key: HideTooltipKey) {
    if (!this.get(key)) {
      this.set(key, false)
    }
  }

  get(key: HideTooltipKey) {
    const tooltips = this.storage.get('hideTooltips') ?? {}
    return tooltips[key]
  }
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import './sentry.ts'
import './index.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './router.tsx'
import { AppContext, useAppModule } from '../features/appContext.ts'
import { appMainModule } from './appMainModule.ts'
import { IntlProvider } from 'react-intl'
import { useSnapshot } from 'valtio/react'
import { messages } from '../shared/translations/messages.ts'
import { MotionConfig } from 'framer-motion'
import { TooltipProvider } from '@radix-ui/react-tooltip'

const module = appMainModule

function Root() {
  const module = useAppModule()
  const state = useSnapshot(module.appStore.state)
  return (
    <IntlProvider
      locale={navigator.language}
      messages={messages[state.language]}
    >
      <RouterProvider router={router} />
    </IntlProvider>
  )
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <TooltipProvider>
      <MotionConfig
        transition={{
          duration: 0.3,
          type: 'tween',
          ease: 'easeInOut',
        }}
      >
        <AppContext.Provider value={module}>
          <Root />
        </AppContext.Provider>
      </MotionConfig>
    </TooltipProvider>
  </React.StrictMode>
)

import { useAppModule } from '../features/appContext.ts'
import { PracticeView } from '../features/practice/PracticeView.tsx'
import { useLazyRef } from '../shared/lib/hooks.ts'
import { PracticeStoreContext } from '../features/practice/PracticeStore.ts'

export function PracticeProvider({ children }: { children: React.ReactNode }) {
  const appModule = useAppModule()
  const store = useLazyRef(() => appModule.practiceStore()).current

  return (
    <PracticeStoreContext.Provider value={store}>
      {children}
    </PracticeStoreContext.Provider>
  )
}

export function PracticePage() {
  return (
    <PracticeProvider>
      <PracticeView />
    </PracticeProvider>
  )
}

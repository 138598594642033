import { classed } from '@tw-classed/react'
import { Link } from 'react-router-dom'

export const Button = classed.button(
  'flex select-none items-center justify-center outline-none enabled:hover:opacity-90 disabled:border-gray disabled:bg-gray disabled:bg-gray-gradient disabled:text-gray5 disabled:shadow-none',
  {
    variants: {
      size: {
        little: 'h-28 px-12 text-12 font-bold',
        small: 'h-32 px-12',
        medium: 'h-36 px-16 text-14 font-bold',
        large: 'h-44 px-24 text-20 font-bold',
        extralarge: 'h-48 px-36 text-18 font-bold',
        custom: '',
      },
      active: {
        true: 'bg-alert-light',
      },
      bg: {
        gray: 'bg-gray-light shadow-button',
        'gray-dark': 'bg-gray-dark text-white shadow-button',
        main: 'bg-main text-white shadow-button',
        'main-dark': 'bg-main-dark text-white shadow-button',
        'purple-light': 'bg-purple-light shadow-button',
        'green-dark': 'bg-green-dark shadow-button',
        alert: 'bg-alert text-white shadow-button',
        active: 'bg-orange shadow-button',
        violet: 'bg-violet text-white shadow-button',
        blue: 'bg-blue4 text-white shadow-button',
        white: 'border-2 border-violetd bg-white text-violetd shadow-button',
        transparent: 'bg-transparent',
        'blue-gradient': 'bg-blue-gradient text-white shadow-button2',
        'blue-gradient-shadow-inset':
          'bg-blue-gradient text-white shadow-inset-button',
        'gray-gradient': 'bg-gray-gradient text-black',
        'black-gradient': 'bg-black-button text-white shadow-button2',
        'green-gradient': 'bg-green-dark-gradient text-white',
        'red-gradient': 'bg-red-dark-gradient text-white',
        custom: '',
      },
      rounded: {
        full: 'rounded-full',
        default: 'rounded-6',
      },
    },
    defaultVariants: {
      size: 'medium',
      active: false,
      bg: 'main',
      rounded: 'default',
    },
  },
)

export const LinkButton = classed(Link, Button)

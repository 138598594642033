import { proxy } from 'valtio'
import { ErrorNotifier } from '../../shared/api/httpClient.ts'
import { getErrorMessage } from '../../shared/lib/utils.ts'

interface State {
  errors: string[]
}
export class ToastStore implements ErrorNotifier {
  state = proxy<State>({ errors: [] })

  showError(error: string) {
    if (!this.state.errors.includes(error)) {
      this.state.errors.push(error)
    }
  }

  hideError(index: number) {
    this.state.errors.splice(index, 1)
  }

  onError(error: unknown): void {
    this.showError(getErrorMessage(error))
  }
}

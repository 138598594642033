import { FormattedMessage } from 'react-intl'
import { Button } from '../../shared/ui/button/button.tsx'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'

export const SubscribeButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      size="extralarge"
      bg="blue-gradient-shadow-inset"
      rounded="full"
      className="fixed bottom-100 z-50 ml-16 w-full mxl:max-w-[calc(100%-32px)] mxl:text-14 xl:bottom-10 xl:max-w-[920px]"
      onClick={() => {
        navigate(urls.profile('manageSubscription'))
      }}
    >
      <FormattedMessage id="btn.trial" />
    </Button>
  )
}

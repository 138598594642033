import { emptyFn, waitFor } from '../lib/utils.ts'
import * as Sentry from '@sentry/react'

export interface ErrorNotifier {
  onError(error: unknown): void
}

export interface Tokens {
  access: string
  refresh: string
}

const ErrorCodes = {
  user_not_found: 'user_not_found',
}
export class HttpClient {
  private tokens?: Tokens
  onRefreshTokens: (tokens: Tokens | undefined) => void = emptyFn
  constructor(
    private root: string,
    private errorNotifier: ErrorNotifier,
  ) {}

  getFullUrl(url: string) {
    return this.root + url
  }

  updateToken(tokens: Tokens | undefined) {
    this.tokens = tokens
  }

  getHeaders() {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    }
    return headers
  }

  async refreshToken() {
    if (this.tokens) {
      try {
        const refresh = this.tokens.refresh
        this.tokens = undefined
        this.tokens = await this.post<Tokens>('/api/refresh_token/', {
          refresh,
        })

        this.onRefreshTokens(this.tokens)
      } catch {
        this.onRefreshTokens(undefined)
      }
    } else {
      await waitFor(() => !!this.tokens)
    }
  }

  async getResponse(createRequest: () => Promise<Response>) {
    const response = await createRequest()
    if (response.status == 401) {
      await this.refreshToken()
      return await createRequest()
    }
    return response
  }

  async handleResponse<T>(url: string, response: Response) {
    if (!response.headers.get('content-type')?.includes('application/json')) {
      throw new Error('Server error')
    }
    const json = (await response.json()) as T
    if (response.ok) {
      Sentry.addBreadcrumb({
        category: 'fetch',
        message: 'Response ' + url,
        data: json as never,
        level: 'info',
      })
      return json
    }
    if (json && typeof json == 'object') {
      if ('code' in json && json.code == ErrorCodes.user_not_found) {
        this.onRefreshTokens(undefined)
      }
      if ('detail' in json) {
        throw new Error(json.detail as string)
      }
      if ('message' in json) {
        throw new Error(json.message as string)
      }
    }
    if (response.status == 400 && typeof json == 'object') {
      throw new Error(Object.values(json as never).join('\n'))
    }
    throw new Error(`Server error`)
  }

  async post<T>(url: string, body: unknown): Promise<T> {
    return this.request(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.getHeaders(),
    })
  }

  async del<T>(url: string, body: unknown): Promise<T> {
    return this.request(url, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: this.getHeaders(),
    })
  }

  async put<T>(url: string, body: unknown): Promise<T> {
    return this.request(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: this.getHeaders(),
    })
  }

  async patch<T>(url: string, body: unknown): Promise<T> {
    return this.request(url, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: this.getHeaders(),
    })
  }
  async get<T>(
    url: string,
    params?: Record<string, string | number | boolean>,
  ): Promise<T> {
    const queryString = params
      ? '?' + new URLSearchParams(params as Record<string, string>).toString()
      : ''

    return this.request(url + queryString, {
      method: 'GET',
      headers: this.getHeaders(),
    })
  }

  getTokenHeaders() {
    const tokenHeader: Record<string, string> = this.tokens?.access
      ? { Authorization: 'Bearer ' + this.tokens.access }
      : {}
    return tokenHeader
  }

  async request<T>(
    url: string,
    init: RequestInit,
    showError = true,
  ): Promise<T> {
    try {
      const response = await this.getResponse(() => {
        const tokenHeader = this.getTokenHeaders()
        const requestInit: RequestInit = {
          ...init,
          headers: { ...init.headers, ...tokenHeader },
        }
        return fetch(this.root + url, requestInit)
      })
      return await this.handleResponse(url, response)
    } catch (e) {
      if (showError) {
        const message =
          e instanceof Error ? e.message : 'Server is not available'
        this.errorNotifier.onError(message)
      }
      throw e
    }
  }
}

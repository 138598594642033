import { proxy } from 'valtio'
import { WriteTheGapsText } from './writeTheGaps.tsx'
import { shuffleArray } from '../../shared/lib/utils.ts'

interface State {
  writtenWords: (string | undefined)[]
  hints: string[]
  answers: string[]
  statuses: ('correct' | 'incorrect' | undefined)[]
}

export class WriteTheGapsStore {
  state: State

  getResult() {
    return this.state.writtenWords
      .map((word, i) => `${i + 1}. ${word}`)
      .join('\n')
  }
  constructor(private texts: readonly WriteTheGapsText[]) {
    const words = texts.flatMap((text) =>
      text
        .filter(
          (x): x is { word: string; hint: string } => typeof x !== 'string',
        )
        .map((x) => x),
    )
    const answers = words.map((x) => x.word)
    const hints = shuffleArray(words.map((x) => x.hint))
    this.state = proxy({
      writtenWords: [],
      statuses: [],
      answers,
      hints,
      selectedIndex: 0,
    })
  }

  check(index: number) {
    const word = this.state.writtenWords[index]
    if (word) {
      this.state.statuses[index] =
        word === this.state.answers[index] ? 'correct' : 'incorrect'
    } else {
      this.state.statuses[index] = undefined
    }
  }

  writeWord(index: number, word: string) {
    this.state.writtenWords[index] = word
  }
}

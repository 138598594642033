import { AppMessageKeys } from '../translations/messages.ts'

export const ADVANCED_LESSON_TAG = 'Vocab practice ML'

export interface MockVocabLesson {
  img: string
  lessonText: string
  lessonType: AppMessageKeys
  lessonTag: string
}

export const MOCK_VOCAB_LESSONS: MockVocabLesson[] = [
  {
    img: '/images/program/lessons/kimono.png',
    lessonText: 'Match Game',
    lessonType: 'Word-Translation Matching',
    lessonTag: 'Vocab practice MW',
  },
  {
    img: '/images/program/lessons/speaker.png',
    lessonType: 'Word-Pronunciation Matching',
    lessonText: 'Sound Game',
    lessonTag: 'Vocab practice MA',
  },
  {
    img: '/images/program/lessons/basketball.png',
    lessonType: 'Multiple choice',
    lessonText: 'Choice Game',
    lessonTag: 'Vocab practice MC',
  },
  {
    img: '/images/program/lessons/teacher.png',
    lessonType: 'Complex Lesson',
    lessonText: 'Big Word Game',
    lessonTag: 'Vocab practice CL',
  },
]

export const MOCK_ADVANCED_LESSON: MockVocabLesson[] = [
  {
    img: '/images/program/lessons/tennis.png',
    lessonType: 'Pick Your Own Words',
    lessonText: 'Play Your Game',
    lessonTag: ADVANCED_LESSON_TAG,
  },
]

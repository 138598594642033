type Tags = Record<string, unknown>

interface TaggedData<T, K extends keyof T> {
  tag: K
  data: T[K]
}

export const none = Symbol()

export function type<T>(): T {
  return {} as T
}

type MatchFunction<T> = <R>(cases: { [K in keyof T]: (data: T[K]) => R }) => (
  tag: TaggedData<T, keyof T>,
) => R

type MatchFunctionOtherwise<T> = <R>(
  cases: { [K in keyof T]?: (data: T[K]) => R },
  otherwise: () => R,
) => (taggedData: TaggedData<T, keyof T>) => R

type TaggedUnion<T extends Tags> = TaggedData<T, keyof T>
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
type TaggedDataConstructorParams<T> = [typeof none] extends [T] ? void : T
type TaggedDataConstructors<T extends Tags> = {
  [K in keyof T]: (params: TaggedDataConstructorParams<T[K]>) => TaggedUnion<T>
}

export function match<T extends Tags, R>(
  taggedUnion: TaggedUnion<T>,
  cases: { [K in keyof T]: (data: T[K]) => R },
) {
  return cases[taggedUnion.tag](taggedUnion.data)
}

export function is<T extends Tags>(
  taggedUnion: TaggedUnion<T>,
  tags: keyof T | (keyof T)[],
) {
  return Array.isArray(tags)
    ? tags.includes(taggedUnion.tag)
    : taggedUnion.tag === tags
}

export function partialMatch<T extends Tags, R>(
  taggedUnion: TaggedUnion<T>,
  cases: { [K in keyof T]?: (data: T[K]) => R },
  otherwise: () => R,
) {
  const fn = cases[taggedUnion.tag]
  if (fn) {
    return fn(taggedUnion.data)
  } else {
    return otherwise()
  }
}

export function taggedUnion<T extends Tags>(config: {
  [K in keyof T]: T[K]
}): TaggedDataConstructors<T> & {
  match: MatchFunction<T>
  partialMatch: MatchFunctionOtherwise<T>
  type: TaggedUnion<T>
} {
  const constructors = Object.keys(config).reduce<TaggedDataConstructors<T>>(
    (acc, tag: keyof T) => {
      acc[tag] = ((data: never) => {
        return { data, tag }
      }) as never
      return acc
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as never,
  )

  return {
    ...constructors,
    match: (cases) => (taggedUnion) => match(taggedUnion, cases),

    partialMatch: (cases, fn) => (taggedunion) =>
      partialMatch(taggedunion, cases, fn),
    type: {} as never,
  }
}

import React from 'react'

import {
  ProfileButton,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio/react'
import { FormattedMessage } from 'react-intl'

const languages = [
  {
    title: 'English',
    lang: 'en',
  },
  {
    title: 'Русский',
    lang: 'ru',
  },
] as const

const CircleDot = (props: { dot: boolean }) => {
  return (
    <div className="flex size-20 items-center justify-center rounded-full border-2 border-main">
      {props.dot && (
        <div className="size-12 rounded-full bg-main animate-in fade-in"></div>
      )}
    </div>
  )
}

export const ProfileAppLanguage = () => {
  const appStore = useAppModule().appStore
  const state = useSnapshot(appStore.state)
  return (
    <div>
      <ProfileTitle title={<FormattedMessage id="user.appLanguage" />} />
      <div className="flex flex-col gap-16">
        {languages.map((x, i) => {
          return (
            <ProfileMenuBlock key={i}>
              <ProfileButton
                onClick={() => {
                  appStore.setLanguage(x.lang)
                }}
                title={
                  <div className="flex items-center gap-8">
                    <CircleDot dot={x.lang === state.language} />
                    {x.title}
                  </div>
                }
              />
            </ProfileMenuBlock>
          )
        })}
      </div>
    </div>
  )
}

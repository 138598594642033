import { requestData } from '../../../shared/lib/mediaUtils.ts'

export class AppAudioRecorder {
  audioRecorder: MediaRecorder
  audioChunks: Blob[] = []

  constructor(stream: MediaStream) {
    this.audioRecorder = new MediaRecorder(stream)
    this.audioRecorder.addEventListener('dataavailable', (e) => {
      this.audioChunks.push(e.data)
    })
  }
  start() {
    this.audioChunks = []
    try {
      this.audioRecorder.start()
    } catch {
      throw new Error('Необходимо разрешить доступ к микрофону')
    }
  }

  requestData() {
    return requestData(this.audioRecorder)
  }

  togglePause() {
    if (this.audioRecorder.state == 'paused') {
      this.audioRecorder.resume()
    } else {
      this.audioRecorder.pause()
    }
  }
  stopRecord() {
    return new Promise<Blob>((resolve) => {
      const mimeType = this.audioRecorder.mimeType
      this.audioRecorder.addEventListener('stop', () => {
        const audioBlob = new Blob(this.audioChunks, { type: mimeType })

        resolve(audioBlob)
      })
      this.audioRecorder.stop()
    })
  }
}

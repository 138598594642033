import { proxy } from 'valtio'
import { ChatApi } from '../../shared/api/chatApi.ts'

interface RateMessageState {
  rated: boolean
  opened: boolean
  comment: string
  sent: boolean
}

export class RateMessageStore {
  state = proxy<RateMessageState>({
    rated: false,
    opened: false,
    comment: '',
    sent: false,
  })
  constructor(private chatApi: ChatApi) {}
  setOpened(opened: boolean) {
    this.state.opened = opened
  }
  rate(messageId: number, rating: number) {
    this.state.sent = true
    this.setOpened(false)
    void this.chatApi.message_rate(messageId, rating, this.state.comment)
  }
  setComment(comment: string) {
    this.state.comment = comment
  }
}

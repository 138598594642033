import { cn } from '../../lib/utils.ts'

export function TransparentGradient({
  direction = 'top',
}: {
  direction: 'top' | 'bottom'
}) {
  return (
    <div
      className={cn(
        'absolute left-0 h-20 w-full  from-white from-30% to-transparent',
        direction === 'top'
          ? '-top-18 bg-gradient-to-t '
          : '-bottom-18 bg-gradient-to-b',
      )}
    />
  )
}

import { proxy } from 'valtio'
import {
  Chat,
  ChatApi,
  demoLessonTag,
  Lesson,
} from '../../shared/api/chatApi.ts'
import { DataState } from '../../shared/lib/hooks.ts'
import { format } from 'date-fns'
import { AuthStore } from '../auth/authStore.ts'

interface State {
  chats: DataState<Chat[]>
  lessons: DataState<Lesson[]>
  closed: boolean
}

const defaultState: State = {
  chats: 'loading',
  lessons: 'loading',
  closed: true,
}
export class ChatListStore {
  state: State

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
  ) {
    this.state = proxy<State>(defaultState)
  }

  toggleClosed() {
    this.state.closed = !this.state.closed
  }

  async loadChats(): Promise<Chat[]> {
    this.state.chats = 'loading'
    const chats = [...(await this.chatApi.chat_list())].reverse()
    if (chats.length === 0 && this.authStore.state.user) {
      await this.chatApi.chat_create({
        user: this.authStore.state.user.user_id,
        program_tag: demoLessonTag,
        chat_title: format(new Date(), 'dd.MM HH:mm:ss'),
      })
      return this.loadChats()
    }
    this.state.chats = chats
    return chats
  }

  async loadLessons(chatId: number) {
    this.state.lessons = 'loading'
    this.state.lessons = [...(await this.chatApi.lesson_list(chatId))].reverse()
  }
}

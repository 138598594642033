import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

export function getEdmanUserUUID() {
  const cookieKey = 'edman-user-uuid'
  let userUUID = Cookies.get(cookieKey)
  if (!userUUID) {
    userUUID = uuidv4()
    Cookies.set(cookieKey, userUUID, { expires: 365, domain: '.edman.ai' })
  }
  return userUUID
}

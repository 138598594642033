import { ImageHideBeforeLoad } from '../../shared/ui/images/images.tsx'
import { FormattedMessage } from 'react-intl'
import { ContactsBlock } from './contactsBlock.tsx'
import { cn } from '../../shared/lib/utils.ts'

export function ContactsView(props: { compact?: boolean; className?: string }) {
  return (
    <div
      className={cn(
        'flex flex-1 flex-col items-center justify-center px-8',
        props.className,
      )}
    >
      <div
        className={cn(
          'flex',
          props.compact ? 'items-start sm:max-w-600 ' : 'flex-col items-center',
        )}
      >
        <ImageHideBeforeLoad
          width={200}
          height={160}
          className={cn(
            props.compact ? 'mr-24 hidden w-[90px] sm:block' : 'mr-40',
          )}
          src="/images/contacts/contacts.svg"
        />
        <div className={cn(!props.compact && 'text-center')}>
          <div className="text-24 font-bold">
            <FormattedMessage id="contacts.title" />:
          </div>
          <div className="mt-16 max-w-[500px] whitespace-pre-wrap text-14 font-medium">
            <FormattedMessage id="contacts.desc1" />{' '}
            <br className="hidden sm:block" />
            <FormattedMessage id="contacts.desc2" />
          </div>
        </div>
      </div>
      <ContactsBlock
        compact={props.compact}
        full={true}
        className={cn(props.compact ? 'mt-24' : 'mt-44')}
      />
    </div>
  )
}

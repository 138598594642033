export function requestData(
  recorder: MediaRecorder,
): Promise<Blob | undefined> {
  if (recorder.state != 'recording') {
    return Promise.resolve(undefined)
  } else {
    return new Promise<Blob>((resolve) => {
      recorder.requestData()
      recorder.addEventListener(
        'dataavailable',
        (e) => {
          resolve(e.data)
        },
        { once: true },
      )
    })
  }
}

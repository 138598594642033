import { DataState, isDataLoaded } from '../lib/hooks.ts'
import React from 'react'
import { Spinner } from './spinner.tsx'

export function Loadable<T>(props: {
  data: DataState<T>
  children: (data: T) => React.ReactNode
}) {
  return isDataLoaded(props.data) ? (
    props.children(props.data)
  ) : (
    <div className="flex size-full items-center justify-center">
      <Spinner size="large" />
    </div>
  )
}

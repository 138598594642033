import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cn } from '../../lib/utils.ts'
import { Icon } from '../icon/icon.tsx'

const Root = DialogPrimitive.Root

const Trigger = DialogPrimitive.Trigger

const Portal = DialogPrimitive.Portal

const Overlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
))
Overlay.displayName = DialogPrimitive.Overlay.displayName

const Content = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    overlayBlur?: boolean
    closable?: boolean
    light?: boolean
    local?: boolean
    hideOverlay?: boolean
    sizeFull?: boolean
  }
>(
  (
    {
      overlayBlur = false,
      closable = false,
      light = false,
      sizeFull = false,
      local = false,
      hideOverlay = false,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const Container = local ? React.Fragment : Portal
    return (
      <Container>
        {!hideOverlay && (
          <Overlay className={cn(overlayBlur && 'backdrop-blur')} />
        )}
        <DialogPrimitive.Content
          ref={ref}
          onInteractOutside={(e) => {
            if (!closable) {
              e.preventDefault()
            }
          }}
          className={cn(
            light ? 'bg-white' : 'bg-violetd text-white',
            local ? 'absolute' : 'fixed',
            sizeFull
              ? 'inset-0 sm:-inset-8'
              : 'left-half top-half w-max -translate-x-half -translate-y-half data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2  data-[state=open]:slide-in-from-top-[48%]',
            'z-50 rounded-8 p-20 shadow-dialog outline-none duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95  ',
            className,
          )}
          {...props}
        >
          {children}
          {closable && (
            <DialogPrimitive.Close className="absolute -right-4 -top-4 outline-none">
              <Icon
                size="large"
                className={cn(light ? 'text-default' : 'text-purple-light')}
                iconName="close"
              />
            </DialogPrimitive.Close>
          )}
        </DialogPrimitive.Content>
      </Container>
    )
  },
)
Content.displayName = DialogPrimitive.Content.displayName

const Footer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
)
Footer.displayName = 'Footer'

export const Dialog = {
  Root,
  Portal,
  Overlay,
  Trigger,
  Content,
}

import { proxy } from 'valtio'
import { FillWordTextData } from './fillTheGaps.tsx'
import {
  ICurrentAnswer,
  Payload,
  getLast,
  getPayload,
  shuffleArray,
} from '../../../shared/lib/utils.ts'

import { AnswerStatus, AnswerStatusStore } from '../answerStatusDrawer.tsx'

interface State {
  texts: readonly FillWordTextData[]
  wordsForAnswers: readonly string[][]
  words: string[]
  answers: readonly string[]
  userAnswers: ICurrentAnswer[]
  currentAnswer?: ICurrentAnswer
  currentIndex: number
  answerStatus?: AnswerStatus
  correctAnswersCount: number
}

export class FillTheGapsStore {
  state: State

  getResult() {
    return this.state.userAnswers
      .map(
        (userAnswer, i) =>
          `${i + 1}. ${this.state.wordsForAnswers[i][userAnswer.index]}`,
      )
      .join('\n')
  }
  constructor(
    texts: readonly FillWordTextData[],
    private answerStore: AnswerStatusStore,
    private onComplete: (
      result: string,
      correctAnswersCount: number,
      payload: Payload[],
    ) => void,
    private onTaskComplete?: (currentTaskIndex: number) => void,
  ) {
    const answers = texts.map((text) => text.options[0])
    const options = texts.map((text) => text.options)
    this.state = proxy({
      texts,
      showAnswer: false,
      userAnswers: [],
      answers,
      wordsForAnswers: options.map(shuffleArray),
      get words() {
        return this.wordsForAnswers[this.currentIndex]
      },
      currentIndex: 0,
      get currentAnswer() {
        if (this.answerStatus) {
          return getLast(this.userAnswers)
        }
        return undefined
      },
      correctAnswersCount: 0,
    } as State)
  }

  nextQuestion(): void {
    if (this.state.currentIndex < this.state.texts.length - 1) {
      this.state.currentIndex += 1
      this.state.answerStatus = undefined
    } else {
      this.state.answerStatus = AnswerStatus.complete()
      this.onComplete(
        'I have completed the exercise',
        this.state.correctAnswersCount,
        getPayload(this.state.answers, this.state.userAnswers),
      )
    }
    this.onTaskComplete && this.onTaskComplete(this.state.currentIndex)
  }

  select(index: number) {
    if (this.state.answerStatus) {
      return
    }
    const answer = this.state.answers[this.state.currentIndex]
    const userAnswer = this.state.words[index]
    const isCorrect = answer === userAnswer
    this.state.userAnswers.push({ index, isCorrect })
    const answerStatus = isCorrect
      ? AnswerStatus.correct()
      : AnswerStatus.wrong({
          description: 'EmptyId',
          messageValue: this.state.texts[this.state.currentIndex].incorrect,
        })
    isCorrect && this.state.correctAnswersCount++
    this.state.answerStatus = answerStatus
    this.answerStore.setStatus(answerStatus, () => {
      this.nextQuestion()
    })
  }
}

import {
  arrayRotate,
  cn,
  setIntervalWithRAF,
  smoothArray,
  useMount,
} from '../../lib/utils.ts'
import React, { useEffect, useRef, useState } from 'react'
import Rive from '@rive-app/react-canvas'

function drawData(canvas: HTMLCanvasElement, data: number[]) {
  // eslint-disable-next-line sonarjs/no-gratuitous-expressions
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return
  }
  const canvasHeight = canvas.height
  const barWidth = 2 * devicePixelRatio
  const barGap = 6 * devicePixelRatio
  const waveStart = (canvas.width - barGap * data.length) / 2
  for (let i = 0; i < data.length; i++) {
    const barHeight = data[i] * canvasHeight
    ctx.fillRect(
      waveStart + i * barGap,
      (canvasHeight - barHeight) / 2,
      barWidth,
      barHeight,
    )
  }
}

function Wave(props: {
  data: number[]
  size: { width: number; height: number }
  colors: string[]
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const canvasHeight = props.size.height * devicePixelRatio
      const canvasWidth = props.size.width * devicePixelRatio
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        if (props.colors.length) {
          for (let i = 0; i < props.colors.length; i++) {
            const color = props.colors[i]
            const shift = i * (props.data.length / 3)
            ctx.fillStyle = color
            drawData(canvas, arrayRotate(props.data, shift))
          }
        } else {
          const gradient = ctx.createLinearGradient(0, 0, 2, canvas.height)
          gradient.addColorStop(0, '#8B57E8')
          gradient.addColorStop(1, '#61CCFF')
          ctx.fillStyle = gradient
          drawData(canvas, props.data)
        }
      }
    }
  }, [props.data, props.colors, props.size])

  return (
    <div className={cn('absolute inset-0', props.colors.length && 'blur')}>
      <canvas ref={canvasRef} className="size-full" />
    </div>
  )
}

export function Soundwave(props: {
  getData: () => Uint8Array
  className?: string
}) {
  const sizeRef = useRef({ width: 0, height: 0 })
  const rootRef = useRef<HTMLDivElement>(null)

  const [data, setData] = useState<number[]>([])

  useMount(() => {
    if (rootRef.current) {
      sizeRef.current.width = rootRef.current.offsetWidth
      sizeRef.current.height = rootRef.current.offsetHeight
    }
    return setIntervalWithRAF(() => {
      const x = Array.from(props.getData()).map((x) =>
        Math.min(1, (x - 127) / 100),
      )
      setData(smoothArray(x, 2, 0.3))
    }, 40)
  })

  return (
    <div ref={rootRef} className={cn('relative h-60', props.className)}>
      <Rive className="absolute inset-0" src="/animations/soundwave_chat.riv" />
      <Wave
        size={sizeRef.current}
        data={data}
        colors={['#FFDFA3', '#6DB7FC', '#FD6B56']}
      />
      <Wave size={sizeRef.current} data={data} colors={[]} />
    </div>
  )
}

import { AnimatePresence, motion } from 'framer-motion'
import * as Portal from '@radix-ui/react-portal'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio/react'
import { Icon } from '../../shared/ui/icon/icon.tsx'

export function Toasts() {
  const toastStore = useAppModule().toastStore
  const state = useSnapshot(toastStore.state)

  return (
    <Portal.Root asChild>
      <AnimatePresence initial={false}>
        {state.errors.length > 0 && (
          <div className="fixed right-8 top-8 z-[100]">
            {state.errors.map((error, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                className="rounded-8 bg-alert p-20 text-white"
              >
                {error}{' '}
                <Icon
                  onClick={(e) => {
                    e.stopPropagation()
                    toastStore.hideError(i)
                  }}
                  className="absolute right-2 top-2 z-[100] cursor-pointer"
                  iconName="close"
                />
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </Portal.Root>
  )
}

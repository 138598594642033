import { cn, uniqueBy } from '../../shared/lib/utils.ts'
import { preloadVideo } from '../../shared/lib/htmlUtils.ts'

const videos: Record<string, string | undefined> = {
  '/videos/onboarding-0.mp4': 'https://img.edman.ai/videos/onboarding-0.mp4',
  '/videos/onboarding-1.mp4': 'https://img.edman.ai/videos/onboarding-1.mp4',
  '/videos/onboarding-2.mp4': 'https://img.edman.ai/videos/onboarding-2.mp4',
  '/videos/onboarding-3.mp4': 'https://img.edman.ai/videos/onboarding-3.mp4',
  '/videos/onboarding-4.mp4': 'https://img.edman.ai/videos/onboarding-4.mp4',
  '/videos/onboarding-5.mp4':
    'https://img.edman.ai/videos/onboarding-5-nobg.mp4',
  '/videos/tutorial-0.mp4': 'https://img.edman.ai/videos/tutorial-0.mp4',
  '/videos/tutorial-1.mp4': 'https://img.edman.ai/videos/tutorial-1.mp4',
  '/videos/tutorial-2.mp4': 'https://img.edman.ai/videos/tutorial-2.mp4',
  '/videos/tutorial-3.mp4': 'https://img.edman.ai/videos/tutorial-3.mp4',
  '/videos/tutorial-4.mp4': 'https://img.edman.ai/videos/tutorial-4.mp4',
  '/videos/screen-0.mp4': 'https://img.edman.ai/videos/screen-0.mp4',
  '/videos/screen-1.mp4': 'https://img.edman.ai/videos/screen-1.mp4',
  '/videos/screen-2.mp4': 'https://img.edman.ai/videos/screen-2.mp4',
  '/videos/screen-3.mp4': 'https://img.edman.ai/videos/screen-3.mp4',
}

export function getVideoUrl(index: number, tutorial?: 'screen' | 'edman') {
  const url =
    tutorial == 'edman'
      ? `/videos/tutorial-${index}.mp4`
      : tutorial == 'screen'
      ? `/videos/screen-${index}.mp4`
      : `/videos/onboarding-${index}.mp4`

  return videos[url] ?? videos['/videos/onboarding-1.mp4'] ?? ''
}

export async function preloadAllVideos(onlyTutorial = false) {
  try {
    for (const url of uniqueBy(Object.values(videos), (x) => x ?? '').filter(
      (x) => !onlyTutorial || !x?.includes('onboarding'),
    )) {
      await preloadVideo(url ?? '')
    }
  } catch {}
}

export interface ProgressBarProps {
  value: number
  light?: boolean
  slow?: boolean
  wide?: boolean
  className?: string
}

export const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div
      className={cn(
        'w-full max-w-400 rounded-full',
        props.wide ? 'h-[6px]' : 'h-2',
        props.light ? 'bg-purple-light2' : 'bg-violetd-light',
        props.className,
      )}
    >
      <div
        className={cn(
          'h-full rounded-full',
          props.slow ? 'duration-300' : 'duration-100',
          props.value > 0 ? 'transition-width ease-linear' : 'w-0',
          'bg-main',
        )}
        style={{ width: props.value * 100 + '%' }}
      ></div>
    </div>
  )
}

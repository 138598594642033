import { cn } from '../../shared/lib/utils.ts'
import React from 'react'

function generateWaveform(length: number) {
  const waveform = [] as number[]
  const random = 1 + (1 + Math.random() * 2) * Math.random()
  const waveType = Math.floor(Math.random() * 4)
  const amplitude = 0.5 + Math.random() * 0.5

  for (let i = 0; i < length; i++) {
    const x = (Math.PI * i) / length
    let value = 0

    switch (waveType) {
      case 0:
        value = Math.sin(x * random)
        break
      case 1:
        value = Math.sign(Math.sin(x * random))
        break
      case 2:
        value =
          2 *
            ((x * random) / (2 * Math.PI) -
              Math.floor((x * random) / (2 * Math.PI))) -
          1
        break
      case 3:
        value =
          2 *
            Math.abs(
              2 *
                ((x * random) / (2 * Math.PI) -
                  Math.floor((x * random) / (2 * Math.PI) + 0.5)),
            ) -
          1
        break
    }

    value = value * amplitude * Math.sin(x)
    waveform[i] = Math.max(0.1, Math.abs(value))
  }

  if (Math.random() > 0.5) {
    waveform.reverse()
  }

  return waveform
}
export const WaveformProgressBar = (props: {
  progress: number
  className?: string
  small: boolean
}) => {
  const barCount = 24
  const waveform = React.useMemo(() => generateWaveform(barCount), [])

  return (
    <div
      className={cn(
        'flex w-fit items-center',
        props.small ? 'h-20 gap-2' : 'h-40 gap-4',
        props.className,
      )}
    >
      {' '}
      {waveform.map((height, index) => (
        <div
          key={index}
          className={cn(
            'rounded-full transition-colors',
            props.small ? 'w-2' : 'w-4',
            index >= props.progress * barCount ? 'bg-gray2' : 'bg-main-light',
          )}
          style={{
            height: `${height * 100}%`,
          }}
        />
      ))}
    </div>
  )
}
